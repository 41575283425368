import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';

import { userInitial } from '../../actions/';
import Footer from "../../Footer/FooterBottom";
import history from "../../../history";
import DashBoardBg from "../../Admin/Question/View/Background/DashBoard/DashBoardBg";

class DashBoard extends React.Component {

    componentDidMount() {
        window.scrollToPosition("main-dashboard-section");
    }

    renderNotification = count => {
        if (count > 0) {
            if (count > 99) {
                return <span className="badge badge-danger m-1 position-absolute" style={{
                    zIndex: 99
                }}>+99</span>
            }
            else {
                return <span className="badge badge-danger m-1 position-absolute" style={{
                    zIndex: 99
                }}>{count}</span>
            }
        }
        return "";
    }

    goTo = path => {
        history.push(path);
    }

    render() {
        const { t, notifications, user } = this.props;

        let Books = 0;
        let Exams = 0;
        let Files = 0;
        let Lessons = 0;
        let Mission = 0;
        let Quizzes = 0;
        let Games = 0;
        if (notifications && notifications.Notification) {
            Books = notifications.Notification.Books;
            Exams = notifications.Notification.Exams;
            Files = notifications.Notification.Files;
            Lessons = notifications.Notification.Lessons;
            Mission = notifications.Notification.Mission;
            Quizzes = notifications.Notification.Quizzes;
            Games = notifications.Notification.Games;
        }

        return (
            <>
                <Helmet>
                    <title>
                        {t('Pages.UserControl.title')} | {t('alarabeyya')}
                    </title>
                </Helmet>
                <section className="position-relative padding-bottom padding-top oh" id="main-dashboard-section" style={{
                    minHeight: '100vh'
                }}>
                    <DashBoardBg user={user} />

                    <div className="container">
                        <div className="row mx-3">
                            <div className="col-lg-12">

                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="section-header">
                                            <h3 className="title">{t('Pages.UserControl.title')}</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="row align-items-center justify-content-center align-content-center mb-30-none">


                                    <div className="d-flex justify-content-center flex-wrap grid-container">

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/Student/Games`)}>
                                            {this.renderNotification(Games)}
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-gamepad-alt "></i>
                                                <h5 className="title cate mb-2">{t('PageHeader.MyGames')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/Student/Missions`)}>
                                            {this.renderNotification(Mission)}
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-bullseye-arrow "></i>
                                                <h5 className="title cate mb-2">{t('PageHeader.MyMissions')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/Student/Books`)}>
                                            {this.renderNotification(Books)}
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-book "></i>
                                                <h5 className="title cate mb-2">{t('PageHeader.Books')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/Student/Lessons`)}>
                                            {this.renderNotification(Lessons)}
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-layer-group "></i>
                                                <h5 className="title cate mb-2">{t('PageHeader.Lessons')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/Student/Files`)}>
                                            {this.renderNotification(Files)}
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-paperclip "></i>
                                                <h5 className="title cate mb-2">{t('PageHeader.Files')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/Student/Exams`)}>
                                            {this.renderNotification(Exams)}
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-pencil-alt "></i>
                                                <h5 className="title cate mb-2">{t('PageHeader.Exams')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/Student/Quizzes`)}>
                                            {this.renderNotification(Quizzes)}
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-question "></i>
                                                <h5 className="title cate mb-2">{t('PageHeader.Quizzes')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/Student/Competition`)}>
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-crown "></i>
                                                <h5 className="title cate mb-2">{t('Pages.Competition.Stage')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/Profile`)}>
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-user "></i>
                                                <h5 className="title cate mb-2">{t('Pages.Profile.title')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/User/Mark`)}>
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-trophy-alt "></i>
                                                <h5 className="title cate mb-2">{t('Pages.Profile.markList')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/User/Favorite/Lesson`)}>
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-heart "></i>
                                                <h5 className="title cate mb-2">{t('Pages.Profile.favoriteList')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/User/Favorite/Files`)}>
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-heart-circle "></i>
                                                <h5 className="title cate mb-2">{t('Pages.Profile.favoriteFiles')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/User/History/Lessons`)}>
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-history "></i>
                                                <h5 className="title cate mb-2">{t('Pages.Profile.lessonsHistory')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/Profile/Edit`)}>
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-edit "></i>
                                                <h5 className="title cate mb-2">{t('edit')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/Profile/ChangePassword`)}>
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-key "></i>
                                                <h5 className="title cate mb-2">{t('ChangePassword')}</h5>
                                            </div>
                                        </div>

                                        <div className="featured-block mx-1 " data-aos="flip-left" style={{
                                            width: '230px'
                                        }} onClick={() => this.goTo(`/Profile/Picture`)}>
                                            <div className="inner-box inner-box-custom wow fadeInLeft h-100 animated" data-wow-delay="0ms" data-wow-duration="1500ms" style={{
                                                backgroundColor: '#ffffffd9'
                                            }}   >
                                                <i className="h3 display-2 icon-color fal fa-image "></i>
                                                <h5 className="title cate mb-2">{t('ChangePicture')}</h5>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        notifications: state.notifications
    };
};

export default connect(mapStateToProps, { userInitial })(withTranslation('common')(DashBoard));