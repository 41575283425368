import api from "../../../../api/api";

export const fetchStudentRanks = (startDate, endDate, ClassRoomId) => async dispatch => {
    dispatch({ type: 'STUDENT_RANKS_LOADING' });
    const response = await api.post(`/StudentLessonMark/`, {
        startDate,
        endDate,
        ClassRoomId
    });
    dispatch({ type: 'STUDENT_RANKS_INITIAL', studentRanks: response.data.data });
}
