import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import Footer from "../../Footer/FooterBottom";
import SubMenu from "./SubMenu";
import { fetchIndices } from '../../Admin/Indices/actions/';
import Library from "../../Admin/Question/View/Background/Library";

class Books extends React.Component {

    constructor(props) {
        super(props);
        this.props.fetchIndices();
        this.bookList = [];
        this.pathList = [];
    }

    componentDidMount() {

        window.scrollToPosition("main-books-section");
    }

    setBookList = (books, index) => {
        books.nodes.forEach(bookClass => {
            bookClass.nodes.forEach(book => {
                this.bookList.push({
                    ...book,
                    index
                });
            });
        });
    }

    renderBook = () => {
        return this.bookList.sort((a, b) => a.sort > b.sort ? 1 : -1).map((book, index) => {
            return <Link to={`/Tracks/BookLessons/${book.id}`} key={book.id} title={book.label} className=" featured-block col-lg-3 col-md-4 col-sm-6 aos-init aos-animate" data-aos="flip-left">
                <div className="inner-box wow fadeInLeft  animated" data-wow-delay="0ms" data-wow-duration="1500ms">
                    <span className="border-one"></span>
                    <span className="border-two"></span>
                    <div className="book-item" >
                        <div className="bk-img">
                            <div className="bk-wrapper">
                                <div className="bk-book bk-bookdefault">
                                    <div className="bk-front">
                                        <div className="bk-cover">
                                            <LazyLoadImage className="h-100 w-100" src={book.icon ? book.icon : "/assets/images/logo/130p150.png"} />
                                        </div>
                                    </div>
                                    <div className="bk-back"></div>
                                    <div className="bk-left overflow-hidden" >
                                        <h2>{book.label}</h2>
                                        <LazyLoadImage className="float-left w-100 h-100" src={book.icon ? book.icon : "/assets/images/logo/130p150.png"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="feature-content">
                        <h5 className="title">{book.label}</h5>
                    </div>
                </div>
            </Link>
        });
    }

    getNodePath(nodes, indexType) {
        if (nodes) {
            nodes.forEach(node => {
                const isOpen = this.HasNode(node, indexType);
                if (isOpen) {
                    this.getNodePath(node.nodes, indexType);
                    if (indexType === node.indexType) {
                        this.pathList.push(node);
                    }
                }
            });
        }
    }

    HasNode(node, indexType) {
        if (node) {
            return (indexType === node.indexType) || this.hasSelectedNodeInChildren(node.nodes, indexType);
        }
        return false;
    }

    hasSelectedNodeInChildren(nodes, indexType) {
        var result = false;
        nodes.forEach(node => {
            result = result || (indexType === node.indexType) || this.hasSelectedNodeInChildren(node.nodes, indexType);
        });
        return result;
    }

    render() {
        const { t } = this.props;

        this.bookList = [];
        this.pathList = [];
        if (this.props.indices && this.props.indices.nodes) {
            this.getNodePath(this.props.indices.nodes, "Books");
        }
        this.pathList.forEach((books, index) => {
            this.setBookList(books, index)
        });

        return (
            <>
                <Helmet>
                    <title>{t("PageHeader.Books")} | {t('alarabeyya')}</title>

                    <meta property="og:title" content={t("PageHeader.PageHeader") + ' | ' + t('alarabeyya')} />
                    <meta property="twitter:title" content={t("PageHeader.PageHeader") + ' | ' + t('alarabeyya')} />
                    <meta name="description" content={t('PageHeader.Books_desc')} />
                    <meta property="og:description" content={t('PageHeader.Books_desc')} />
                    <meta property="twitter:description" content={t('PageHeader.Books_desc')} />
                </Helmet>

                <section className="position-relative" id="main-books-section" style={{
                    minHeight: '100vh'
                }}>

                    <Library />
                    <div className="container padding-top padding-bottom">
                        <div className="row justify-content-center w-100" data-aos="fade-top" data-aos-easing="linear">
                            <div className="section-header mb-4">
                                <span className="cate custome-title"> {t('PageHeader.StudyTrack')} </span>
                                <div className="display-4 h3 custome-title" ><i className="mx-3 fal fa-book "></i> {t('PageHeader.Books')} {this.props.title ? ` - ${this.props.title}` : ""}</div>
                            </div>
                        </div>
                        <div className="d-none d-lg-block" data-aos="fade-bottom" data-aos-easing="linear">
                            <SubMenu />
                        </div>
                        <div className="row mb-30-none grid">
                            <div className="col-12">
                                <div className="d-flex flex-wrap justify-content-center justify-content-sm-around">
                                    {this.renderBook()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        indices: state.indices
    };
};

export default connect(mapStateToProps, { fetchIndices, fetch })(withTranslation('common')(Books));