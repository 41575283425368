import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast } from 'react-toastify';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';

import Footer from "../../Footer/FooterBottom";
import UserItem from "./UserItem";
import {
    fetchUsers as fetch,
    fetchSchools,
    loadUsers,
    removeUsers as remove,
    undoUsers as undo,
    clearUsers as clear,
    updateUser as update
} from './actions';
import api from '../../../api/api';
import configData from "../../Config/UserType.json";
import configDataCountries from "../../Config/countries.json";
import ClassTypeData from "../../Config/classType.json";
import UserSubMenu from "../../Home/User/UserSubMenu";

class UserList extends React.Component {

    state = {
        search: '',
        UserTypeId: 0,
        ClassTypeId: 0,
        ClassType: [],
        Sex: "",
        SchoolId: "",
        IsActive: null,
        IsLocked: null,
        IsTeacher: null,
        IsDeleted: false,
        ActiveUntil: null,
        CountryCustom: null,
        Email: "",
        Name: "",
        Username: "",
        Phone: "",
        Limit: 50,
        schools: [],
        startActiveDate: "",
        endActiveDate: ""
    };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
        moment.locale('en');
        window.scrollToTop();
        this.props.fetchSchools();
        this.state.ClassType = ClassTypeData.ClassType;
        this.state.UserType = configData.UserType;
        this.state.countries = configDataCountries.countries.sort((a, b) => a.label > b.label ? 1 : -1).map(item => {
            return {
                value: item.value,
                name: item.label,
                label: <div><LazyLoadImage src={process.env.PUBLIC_URL + item.icon} />  {item.label}</div>,
            };
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (props.schools && props.schools.schools) {
            state.schools = props.schools.schools.map(item => {
                return {
                    value: item.Id,
                    name: item.Name,
                    label: <div> {item.Name}</div>,
                };
            });
        }
        return state;
    }

    Sync = () => {
        this.props.loadUsers();
        setTimeout(() => {
            this.props.fetch(
                {
                    UserTypeId: this.state.UserTypeId,
                    SchoolId: this.SchoolId?.select?.select?.getValue()[0]?.value ? this.SchoolId.select.select.getValue()[0].value : 0,
                    Country: this.Country.state.value ? this.Country.state.value.value : "",
                    IsActive: this.state.IsActive,
                    ActiveUntil: this.state.ActiveUntil,
                    CountryCustom: this.state.CountryCustom,
                    IsLocked: this.state.IsLocked,
                    IsTeacher: this.state.IsTeacher,
                    Sex: this.state.Sex,
                    startActiveDate: this.state.startActiveDate,
                    endActiveDate: this.state.endActiveDate,
                    IsDeleted: this.state.IsDeleted,
                    Email: this.state.Email,
                    Username: this.state.Username,
                    ClassTypeId: this.state.ClassTypeId,
                    Phone: this.state.Phone,
                    Name: this.state.Name,
                    Limit: this.state.Limit
                }
            );
        }, 500);
    }

    setEmail = Email => {
        this.setState({
            Email
        });
    }

    setUsername = Username => {
        this.setState({
            Username
        });
    }

    setActiveUntil = ActiveUntil => {
        this.setState({
            ActiveUntil
        });
    }

    setCountry = CountryCustom => {
        this.setState({
            CountryCustom
        });
    }

    setPhone = Phone => {
        this.setState({
            Phone
        });
    }

    setLimit = Limit => {
        this.setState({
            Limit
        });
    }

    setName = Name => {
        this.setState({
            Name
        });
    }

    setUserType = UserTypeId => {
        this.setState({
            UserTypeId
        });
    }

    setSex = Sex => {
        this.setState({
            Sex
        });
    }

    setIsActive = IsActive => {
        this.setState({
            IsActive
        });
    }

    setIsDeleted = IsDeleted => {
        this.setState({
            IsDeleted
        });
    }

    setIsLocked = IsLocked => {
        this.setState({
            IsLocked
        });
    }

    setIsTeacher = IsTeacher => {
        this.setState({
            IsTeacher
        });
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    showDelete = id => {
        if (id) {
            this.props.remove(id);
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDelete(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteUser(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });

        }
    }

    deleteUser = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.delete(`/Users/${id}`).then(response => {

            this.props.clear(id);

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.UsersControl.toast.Delete.Title')}</div> <div className="h6">{t('Pages.UsersControl.toast.Delete.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    undoDelete = id => {
        this.props.undo(id);
    }

    renderUsers() {
        const { t, users } = this.props;
        let isLoaded = false;
        let toRemove = [];

        if (users) {
            toRemove = users.toRemove;
            isLoaded = users.isLoaded;
        }

        if (users && users.users && users.users.length > 0) {
            let filterUsers = users.users.filter(v => v.Name.includes(this.state.search));

            if (isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.UsersControl.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            if (filterUsers.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.UsersControl.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.UsersControl.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            return filterUsers.map(user =>
                (toRemove && toRemove.includes(user.Id)) ? "" :
                    <UserItem
                        key={user.Id}
                        item={user}
                        Sync={this.Sync}
                        updateUser={this.props.update}
                        remove={this.showDelete}
                        schools={this.state.schools}
                    />
            );
        }

        if (users && users.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.UsersControl.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.UsersControl.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    renderUserTypeOption() {
        return this.state.UserType.map(item => {
            return <option key={item.Id} value={item.Id}>{item.Name}</option>
        });
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.includes(input);
        }
        return true;
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY'));
        this.setState({
            startActiveDate: picker.startDate.format('DD/MM/YYYY'),
            endActiveDate: picker.endDate.format('DD/MM/YYYY')
        });
    }

    closeDate = () => {
        this.Date.ref.value = "";
        this.setState({
            startActiveDate: "",
            endActiveDate: ""
        });
    }

    setClassTypeId = ClassTypeId => {
        this.setState({
            ClassTypeId
        });
    }

    renderClassTypeOption() {
        return this.state.ClassType.map(item => {
            return <option key={item.Id} value={item.Id}>{item.Name}</option>
        });
    }

    filterSchool = (options, inputValue) => {
        const candidate = inputValue.toLowerCase();
        if (options.length > 0) {
            return options.filter(({ label }) => label.toLowerCase().includes(candidate));
        }
        return true;
    };

    promiseOptions = (inputValue, callback) => {
        api.post(`/SchoolsLookups/`, {
            Name: inputValue
        }).then(resp => {
            if (resp.data.data?.length > 0) {
                const toSelectOption = ({ Id, Name }) => ({ label: Name, value: Id });
                const asyncOptions = resp.data.data.map(toSelectOption);
                const filtered = this.filterSchool(asyncOptions, inputValue);
                callback(filtered);
            } else {
                callback();
            }
        })
    };


    render() {
        const { t, users, user } = this.props;

        let ranges = {};
        ranges[t("range.Today")] = [moment().toDate(), moment().toDate()];
        ranges[t("range.Tomorrow")] = [moment().subtract(-1, 'days'), moment().subtract(-1, 'days')];
        ranges[t("range.Next7Days")] = [moment().toDate(), moment().subtract(-6, 'days'), moment()];
        ranges[t("range.Next30Days")] = [moment().toDate(), moment().subtract(-29, 'days'), moment()];
        ranges[t("range.ThisMonth")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[t("range.NextMonth")] = [moment().subtract(-1, 'month').startOf('month'), moment().subtract(-1, 'month').endOf('month')];

        let isLoaded = false;
        let length = 0;
        if (users) {
            if (users.users) {
                length = users.users.length;
            }
            isLoaded = users.isLoaded;
        }

        return (
            <>
                <section className="pos-rel padding-bottom padding-top">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <Helmet>
                            <title>
                                {t('Pages.UsersControl.title')} | {t('alarabeyya')}
                            </title>
                        </Helmet>

                        <div className="row ">
                            <div className="col-lg-12">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="section-header">
                                            <h3 className="title">{t('Pages.UsersControl.title')}</h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="widget widget-category" >

                                    <div className="widget-header">
                                        <h5 className="title">
                                            <i className="fal fa-search "></i> {t('Pages.UsersControl.advancedSearch.title')}
                                        </h5>
                                    </div>
                                    <div className="widget-body">
                                        <div className="contact-form">
                                            <div className="row">

                                                <div className="form-group col-md-3">
                                                    <select
                                                        defaultValue={this.state.UserTypeId}
                                                        onChange={e => this.setUserType(e.target.value)}
                                                        className="form-control"
                                                        name="UserTypeId"
                                                        title={t("Pages.UsersControl.UserType.title")}
                                                        placeholder={t("Pages.UsersControl.UserType.Placeholder")}    >
                                                        <option value="0">{t("Pages.UsersControl.advancedSearch.UserType")}  </option>
                                                        {this.renderUserTypeOption()}
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <select
                                                        defaultValue={this.state.Sex}
                                                        onChange={e => this.setSex(e.target.value)}
                                                        className="form-control"
                                                        name="UserTypeId"
                                                        title={t("Pages.UsersControl.advancedSearch.Sex")}  >
                                                        <option value="">{t("Pages.UsersControl.advancedSearch.Sex")}  </option>
                                                        <option value="male">{t("Pages.Register.Sex.male")}  </option>
                                                        <option value="female">{t("Pages.Register.Sex.female")}  </option>
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <div>
                                                        <Select
                                                            defaultValue={this.state.CountryIndex >= 0 ? {
                                                                label: this.state.countries[this.state.CountryIndex].label,
                                                                value: this.state.countries[this.state.CountryIndex].value
                                                            } : ""}
                                                            name="Country"
                                                            placeholder={t('Pages.Register.Country.Placeholder')}
                                                            isClearable={true}
                                                            isRtl={true}
                                                            isSearchable={true}
                                                            ref={Country => this.Country = Country}
                                                            options={this.state.countries}
                                                            filterOption={this.filterOptions}
                                                            theme={theme => ({
                                                                ...theme,
                                                                borderRadius: 0,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#92278f',
                                                                    primary: '#92278f',
                                                                },
                                                            })}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <div>
                                                        <AsyncSelect
                                                            cacheOptions
                                                            isClearable={true}
                                                            name="SchoolId"
                                                            placeholder={t('Pages.UsersControl.advancedSearch.School')}
                                                            isRtl={true}
                                                            isSearchable={true}
                                                            loadOptions={this.promiseOptions}
                                                            filterOption={this.filterSchool}
                                                            ref={SchoolId => this.SchoolId = SchoolId}
                                                            defaultOptions
                                                            theme={theme => ({
                                                                ...theme,
                                                                borderRadius: 0,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#92278f',
                                                                    primary: '#92278f',
                                                                },
                                                            })}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <select
                                                        defaultValue={this.state.IsActive}
                                                        onChange={e => this.setIsActive(e.target.value)}
                                                        className="form-control"
                                                        name="IsActive"
                                                        title={t("Pages.UsersControl.advancedSearch.IsActive")}  >
                                                        <option value="">{t("Pages.UsersControl.advancedSearch.IsActive")}  </option>
                                                        <option value={true}>{t("Pages.UsersControl.Active")}  </option>
                                                        <option value={false}>{t("Pages.UsersControl.NotActive")}  </option>
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <select
                                                        defaultValue={this.state.IsLocked}
                                                        onChange={e => this.setIsLocked(e.target.value)}
                                                        className="form-control"
                                                        name="IsLocked"
                                                        title={t("Pages.UsersControl.advancedSearch.IsLocked")}  >
                                                        <option value="">{t("Pages.UsersControl.advancedSearch.IsLocked")}  </option>
                                                        <option value={false}>{t("Pages.UsersControl.NotLocked")}  </option>
                                                        <option value={true}>{t("Pages.UsersControl.Locked")}  </option>
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <select
                                                        defaultValue={this.state.IsTeacher}
                                                        onChange={e => this.setIsTeacher(e.target.value)}
                                                        className="form-control"
                                                        name="IsTeacher"
                                                        title={t("Pages.UsersControl.advancedSearch.IsTeacher")}  >
                                                        <option value="">{t("Pages.UsersControl.advancedSearch.IsTeacher")}  </option>
                                                        <option value={false}>{t("Pages.UsersControl.NotTeacher")}  </option>
                                                        <option value={true}>{t("Pages.UsersControl.Teacher")}  </option>
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <select
                                                        defaultValue={this.state.Country}
                                                        onChange={e => this.setCountry(e.target.value)}
                                                        className="form-control"
                                                        name="CountryCustom"
                                                        title={t("Pages.UsersControl.advancedSearch.Country.title")}  >
                                                        <option value="">{t("Pages.UsersControl.advancedSearch.Country.title")}  </option>
                                                        <option value={false}>{t("Pages.UsersControl.advancedSearch.Country.OutSide")}  </option>
                                                        <option value={true}>{t("Pages.UsersControl.advancedSearch.Country.InSide")}  </option>
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <select
                                                        defaultValue={this.state.ActiveUntil}
                                                        onChange={e => this.setActiveUntil(e.target.value)}
                                                        className="form-control"
                                                        name="ActiveUntil"
                                                        title={t("Pages.UsersControl.advancedSearch.ActiveUntil.title")}  >
                                                        <option value="">{t("Pages.UsersControl.advancedSearch.ActiveUntil.title")}  </option>
                                                        <option value={true}>{t("Pages.UsersControl.advancedSearch.ActiveUntil.Active")}  </option>
                                                        <option value={false}>{t("Pages.UsersControl.advancedSearch.ActiveUntil.NotActive")}  </option>
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-3 pos-rel d-flex align-items-center">
                                                    <div className=" flex-grow-1">
                                                        <DateRangePicker
                                                            ref={Date => this.Date = Date}
                                                            initialSettings={{
                                                                opens: 'center',
                                                                autoUpdateInput: false,
                                                                buttonClasses: 'btn',
                                                                cancelClass: "btn-danger",
                                                                applyButtonClasses: "btn-primary",
                                                                showDropdowns: true,
                                                                ranges: ranges,
                                                                minYear: 2020,
                                                                locale: {
                                                                    format: 'DD/MM/YYYY',
                                                                    cancelLabel: t('Close'),
                                                                    firstDay: 6,
                                                                    applyLabel: t('Apply'),
                                                                    customRangeLabel: t('range.customRange'),
                                                                    monthNames: [
                                                                        `${t("months.January")}`,
                                                                        `${t("months.February")}`,
                                                                        `${t("months.March")}`,
                                                                        `${t("months.April")}`,
                                                                        `${t("months.May")}`,
                                                                        `${t("months.June")}`,
                                                                        `${t("months.July")}`,
                                                                        `${t("months.August")}`,
                                                                        `${t("months.September")}`,
                                                                        `${t("months.October")}`,
                                                                        `${t("months.November")}`,
                                                                        `${t("months.December")}`
                                                                    ],
                                                                    daysOfWeek: [
                                                                        t("daysOfWeek.Sunday"),
                                                                        t("daysOfWeek.Monday"),
                                                                        t("daysOfWeek.Tuesday"),
                                                                        t("daysOfWeek.Wednesday"),
                                                                        t("daysOfWeek.Thursday"),
                                                                        t("daysOfWeek.Friday"),
                                                                        t("daysOfWeek.Saturday")
                                                                    ],
                                                                },
                                                            }}
                                                            onApply={this.handleApplyDate} >
                                                            <input
                                                                defaultValue={this.state.activeDate}
                                                                name="Date"
                                                                autoComplete="off"
                                                                required
                                                                type="text"
                                                                placeholder={t('Pages.UsersControl.advancedSearch.activeDate')}
                                                                title={t('Pages.UsersControl.advancedSearch.activeDate')}
                                                            />
                                                        </DateRangePicker>
                                                    </div>
                                                    <div className="btn position-absolute  d-flex align-items-center" onClick={() => this.closeDate()} style={{
                                                        left: 0
                                                    }}>
                                                        <i className="fas fa-times"></i>
                                                    </div>
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <input
                                                        defaultValue={this.state.Name}
                                                        onChange={e => this.setName(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={t("Pages.UsersControl.advancedSearch.name")}
                                                        title={t("Pages.UsersControl.advancedSearch.name")} />
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <input
                                                        defaultValue={this.state.Email}
                                                        onChange={e => this.setEmail(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={t("Pages.UsersControl.advancedSearch.email")}
                                                        title={t("Pages.UsersControl.advancedSearch.email")} />
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <input
                                                        defaultValue={this.state.Username}
                                                        onChange={e => this.setUsername(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={t("Pages.UsersControl.advancedSearch.username")}
                                                        title={t("Pages.UsersControl.advancedSearch.username")} />
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <input
                                                        defaultValue={this.state.Phone}
                                                        onChange={e => this.setPhone(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={t("Pages.UsersControl.advancedSearch.phone")}
                                                        title={t("Pages.UsersControl.advancedSearch.phone")} />
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <select
                                                        defaultValue={this.state.ClassTypeId}
                                                        onChange={e => this.setClassTypeId(e.target.value)}
                                                        className="form-control"
                                                        name="ClassTypeId"
                                                        placeholder={t("Pages.UsersControl.advancedSearch.ClassType.Placeholder")}
                                                        title={t("Pages.UsersControl.advancedSearch.ClassType.title")}  >
                                                        <option value={0}>{t("Pages.UsersControl.advancedSearch.ClassType.Placeholder")}</option>
                                                        {this.renderClassTypeOption()}
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <select
                                                        defaultValue={this.state.IsDeleted}
                                                        onChange={e => this.setIsDeleted(e.target.value)}
                                                        className="form-control"
                                                        name="IsDeleted"
                                                        title={t("Pages.UsersControl.advancedSearch.IsDeleted")}  >
                                                        <option value={false}>{t("Pages.UsersControl.IsNotDeleted")}  </option>
                                                        <option value={true}>{t("Pages.UsersControl.IsDeleted")}  </option>
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <input
                                                        defaultValue={this.state.Limit}
                                                        onChange={e => this.setLimit(e.target.value)}
                                                        type="number"
                                                        step={1}
                                                        className="form-control"
                                                        placeholder={t("Pages.UsersControl.advancedSearch.limit")}
                                                        title={t("Pages.UsersControl.advancedSearch.limit")} />
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <button className="custom-button" onClick={() => this.Sync()} title={t("Pages.UsersControl.Sync")} >
                                                        <span>
                                                            <i className="fas icon fa-sync-alt"></i>
                                                            {t('Pages.UsersControl.advancedSearch.send')}
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <div className="row ">
                                    <div className="col-lg-8">
                                        <div className="widget widget-search" >
                                            <div className="widget-body">
                                                <div className="d-flex">
                                                    <div className="p-2 flex-grow-1 ">
                                                        <form className="search-form">
                                                            <input type="text" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.UsersControl.search")} />
                                                            <button type="button"><i className="fas fa-search"></i></button>
                                                        </form>
                                                    </div>
                                                    {
                                                        isLoaded ?
                                                            <div className="p-3">
                                                                <span className="btn btn-info">
                                                                    <span className="ml-1">
                                                                        {t('Pages.UsersControl.advancedSearch.total')}
                                                                    </span>
                                                                    :
                                                                    {length}
                                                                </span>
                                                            </div>
                                                            : ""
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        {this.renderUsers()}
                                    </div>

                                    <div className="col-lg-4 mb-50">
                                        <aside>
                                            <UserSubMenu user={user} />
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        users: state.users
    };
};


export default connect(mapStateToProps, { fetch, loadUsers, remove, clear, undo, update, fetchSchools })(withTranslation('common')(UserList));
