import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';

import configData from "../../Config/countries.json";
import EditProfileForm from '../../Home/Forms/EditProfileForm';
import api, { setToken } from '../../../api/api';
import ChangePasswordForm from '../../Home/Forms/ChangePasswordForm';
import history from "../../../history";

import { userLogin } from '../../actions/';
import UserBill from "./UserBill";
import UserNote from "./UserNote";
import ChangeLoginInfoForm from "../../Home/Forms/ChangeLoginInfoForm";

class UserItem extends React.Component {

    state = {
        moreInfo: false,
        showEditProfile: false,
        showChangePassword: false,
        showBills: false,
        showNotes: false,
        showChangeSchool: false,
        showLoginInfo: false,
        showMove: false
    };

    constructor(props) {
        super(props);
        this.state.countries = configData.countries.sort((a, b) => a.label > b.label ? 1 : -1).map(item => {
            return {
                value: item.value,
                name: item.label,
                label: <span><LazyLoadImage src={process.env.PUBLIC_URL + item.icon} />  {item.label}</span>,
            };
        });
        this._toastId = new React.createRef();
    }

    changePassword = () => {
        this.setState({
            showChangePassword: true
        });
    }

    move = () => {
        this.setState({
            showMove: true
        });
    }

    changeLoginInfo = () => {
        this.setState({
            showLoginInfo: true
        });
    }

    moreInfo = () => {
        this.setState({
            moreInfo: !this.state.moreInfo
        });
    }

    edit = () => {
        this.setState({
            showEditProfile: true
        });
    }

    bills = () => {
        this.setState({
            showBills: true
        });
    }

    notes = () => {
        this.setState({
            showNotes: true
        });
    }

    handleCloseChangeSchool = () => {
        this.setState({
            showChangeSchool: false
        });
    }

    handleClose = () => {
        this.setState({
            showEditProfile: false,
            showBills: false,
            showChangePassword: false,
            showMove: false,
            showNotes: false,
            showLoginInfo: false
        });
    }

    onSubmit = (postData, _toastId) => {
        const { t } = this.props;

        api.put(`/Users/${this.props.item.Id}`,
            postData
        ).then(response => {
            if (response.data.code === "success") {

                this.props.updateUser(this.props.item.Id, postData);

                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.ProfileEdit.success_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.success_toast.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.handleClose();

            } else if (response.data.code === "exists_email") {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.Email.error_toast.Title')}</div> <div className="h6">{t('Pages.Register.Email.error_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    onSubmitChangePassword = (postData, _toastId) => {
        const { t } = this.props;

        api.put(`/Users/${this.props.item.Id}?ChangePassword=true`,
            postData
        ).then(response => {
            if (response.data.code === "success") {

                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.ProfileEdit.success_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.success_toast.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.handleClose();

            } else {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    onSubmitLoginInfo = (postData) => {
        const { t, item } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.post(`/ChangeUserLoginInfo/${item.Id}`,
            postData
        ).then(response => {
            if (response.data.code === "success") {

                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.ProfileEdit.success_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.success_toast.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.props.updateUser(item.Id, {
                    ...item,
                    Email: postData.Email,
                    Username: postData.Username
                });

                this.handleClose();

            } else if (response.data.code === "exists_username") {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.Username.error_toast.Title')}</div> <div className="h6">{t('Pages.Register.Username.error_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if (response.data.code === "exists_email") {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.Email.error_toast.Title')}</div> <div className="h6">{t('Pages.Register.Email.error_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    onSubmitChangeSchool = () => {
        const { t, item } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        if (this.SchoolsId?.select?.select?.getValue() && this.SchoolsId?.select?.select?.getValue()[0] && this.SchoolsId?.select?.select?.getValue()[0]?.value) {
            let postData = {
                SchoolId: this.SchoolsId.select.select.getValue()[0]?.value
            };

            api.post(`/ChangeUserSchool/${item.Id}`,
                postData
            ).then(response => {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-school"></i> {t('Pages.UsersControl.toast.ChangeSchool.Title')}</div> <div className="h6">{t('Pages.UsersControl.toast.ChangeSchool.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.props.updateUser(item.Id, {
                    ...item,
                    School: {
                        Id: this.SchoolsId.select.select.getValue()[0]?.value,
                        Name: this.SchoolsId.select.select.getValue()[0]?.label
                    }
                });

                this.handleCloseChangeSchool();
            }).catch(error => {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        } else {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.UsersControl.toast.ChangeSchool.error')}</div> <div className="h6"></div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    restore = id => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.post(`/Users/${id}`).then(response => {
            if (response.data.code === "success") {

                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5">
                        <i className="icon fal fa-trash-undo-alt"></i>
                        {t('Pages.ProfileEdit.restore_toast.Title')}
                    </div>
                        <div className="h6">
                            {t('Pages.ProfileEdit.restore_toast.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                this.props.Sync();

            }
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    fire = id => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.delete(`/Users/${id}?HardDelete=${true}`).then(response => {

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5">
                    <i className="icon fal fa-trash-undo-alt"></i>
                    {t('Pages.ProfileEdit.delete_toast.Title')}
                </div>
                    <div className="h6">
                        {t('Pages.ProfileEdit.delete_toast.Body')}
                    </div>
                </div>,
                type: toast.TYPE.SUCCESS,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            this.props.Sync();
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    useAs = id => {
        if (id) {
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('UserAsProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.userAsUser(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    userAsUser = id => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.post(`/UserAsUser/${id}`).then(response => {
            if (response.data.code === "success") {
                let prevToken = localStorage.getItem('token');
                localStorage.setItem('prevToken', prevToken);

                localStorage.setItem('token', response.data.token);
                this.props.userLogin(response.data);
                setToken(response.data.token);

                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.Login.success_toast.Title')}</div> <div className="h6">{t('Pages.Login.success_toast.Body')}</div></div>,
                    type: toast.TYPE.INFO,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });


                history.push("/Profile");
            }
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    showChangeSchool = () => {
        this.setState({
            showChangeSchool: true
        });
    }

    changeUserType = id => {
        if (id) {
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('ChangePermissionUserProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.checkUserTypeBeforCahngePermission(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    checkUserTypeBeforCahngePermission = id => {
        if (this.props.item.UserType.Id === 4) {
            this.changeUserTypeConfirm(id);
        } else {
            this.setState({
                showChangeSchool: true
            });
        }
    }

    changeUserTypeConfirm = id => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        if (this.props.item.UserType.Id === 2 && (!this.SchoolsId?.select?.select?.getValue() || !this.SchoolsId?.select?.select?.getValue()[0] || !this.SchoolsId?.select?.select?.getValue()[0]?.value)) {

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.UsersControl.toast.ChangeSchool.error')}</div> <div className="h6"></div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            return;
        }

        const postData = {
            SchoolId: this.SchoolsId.select.select.getValue()[0]?.value,
            UserTypeId: this.props.item.UserType.Id === 2 ? 4 : 2
        };

        api.put(`/ChangeUserType/${id}`, postData).then(response => {

            this.props.Sync();

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.UsersControl.toast.ChangePermission.Title')}</div> <div className="h6">{t('Pages.UsersControl.toast.ChangePermission.Body')}</div></div>,
                type: toast.TYPE.INFO,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    changePermission = id => {
        if (id) {
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('ChangePermissionUserProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.changePermissionConfirm(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    changePermissionConfirm = id => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        const postData = {
            UserTypeId: this.props.item.UserType.Id === 3 ? 4 : 3
        };

        api.post(`/ChangeUserType/${id}`, postData).then(response => {

            this.props.Sync();

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.UsersControl.toast.ChangePermission.Title')}</div> <div className="h6">{t('Pages.UsersControl.toast.ChangePermission.Body')}</div></div>,
                type: toast.TYPE.INFO,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    changeAccessToAllClass = id => {
        if (id) {
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('ChangeAccessToClassProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.changeAccessToAllClassConfirm(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    changeAccessToAllClassConfirm = id => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.post(`/ChangeAccessToAllClass/${id}`).then(response => {

            this.props.Sync();

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.UsersControl.toast.ChangePermission.Title')}</div> <div className="h6">{t('Pages.UsersControl.toast.ChangePermission.Body')}</div></div>,
                type: toast.TYPE.INFO,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    testUser = id => {
        if (id) {
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('TestUserProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.testUserConfirm(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    testUserConfirm = id => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.put(`/UserAsUser/${id}`).then(response => {

            this.props.updateUser(id, {
                ...this.props.item,
                IsForTest: true
            });

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.UsersControl.toast.test_user.Title')}</div> <div className="h6">{t('Pages.UsersControl.toast.test_user.Body')}</div></div>,
                type: toast.TYPE.INFO,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    filter = (options, inputValue) => {
        const candidate = inputValue.toLowerCase();
        if (options.length > 0) {
            return options.filter(({ label }) => label.toLowerCase().includes(candidate));
        }
        return true;
    };

    promiseSchoolOptions = (inputValue, callback) => {
        api.post(`/SchoolsLookups/`, {
            Name: inputValue
        }).then(resp => {
            if (resp.data.data?.length > 0) {
                const toSelectOption = ({ Id, Name }) => ({ label: Name, value: Id });
                const asyncOptions = resp.data.data.map(toSelectOption);
                const filtered = this.filter(asyncOptions, inputValue);
                callback(filtered);
            } else {
                callback();
            }
        })
    }

    promiseClassRoomOptions = (inputValue, callback) => {
        api.post(`/ClassRoomsLookups/`, {
            Name: inputValue,
            SchoolId: this.props.item.School.Id
        }).then(resp => {
            if (resp.data.data?.length > 0) {
                const toSelectOption = p => ({ label: p.ClassType.Name + " " + p.Division.Name, value: p.Id });
                const asyncOptions = resp.data.data.map(toSelectOption);
                const filtered = this.filter(asyncOptions, inputValue);
                callback(filtered);
            } else {
                callback();
            }
        })
    }

    onSubmitChangeClassRoom = () => {
        const { t, item } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        if (this.ClassRoomId && this.ClassRoomId.select?.select?.getValue()[0]?.value) {
            let postData = {
                ClassRoomId: this.ClassRoomId.select.select.getValue()[0]?.value,
            };

            api.post(`/ChangeStudentClass/${item.Id}`,
                postData
            ).then(response => {

                toast.update(this._toastId.current, {
                    render: () => <div>
                        <div className="h5">
                            <i className="icon fal fa-exchange"></i>
                            {t('Pages.UsersControl.toast.ChangeClassRoom.Title')}
                        </div>
                        <div className="h6">
                            {t('Pages.UsersControl.toast.ChangeClassRoom.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.handleClose();


            }).catch(error => {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

        } else {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.UsersControl.toast.ChangeClassRoom.error')}</div> <div className="h6"></div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    render() {
        const { t, item } = this.props;
        if (item) {

            let Country = "";
            if (item.Country) {
                Country = this.state.countries.filter(p => p.value === item.Country)[0];
            }

            return (
                <>
                    {
                        this.state.showBills ? <UserBill handleClose={this.handleClose} user={item} /> : ""
                    }
                    {
                        this.state.showNotes ? <UserNote handleClose={this.handleClose} user={item} /> : ""
                    }
                    {
                        this.state.showLoginInfo ?
                            <Modal
                                size="md"
                                centered
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false}
                                show={this.state.showLoginInfo}
                                onHide={this.handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="h5 mb-0">
                                            <i className="fad fa-fingerprint text-success icon"></i> {t("Pages.UsersControl.action.changeLoginInfo")}
                                            :
                                            <span className="text-primary mx-1">
                                                {item.Name}
                                            </span>
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="widget widget-category">
                                        <div className="widget-body">
                                            <ChangeLoginInfoForm onSubmit={this.onSubmitLoginInfo} user={item} />
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            : ""
                    }
                    {
                        this.state.showChangePassword ?
                            <Modal
                                size="md"
                                centered
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false}
                                show={this.state.showChangePassword}
                                onHide={this.handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="h5 mb-0">
                                            <i className="fad fa-key text-primary icon"></i> {t("Pages.UsersControl.action.change_password")}
                                            :
                                            <span className="text-primary mx-1">
                                                {item.Name}
                                            </span>
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="widget widget-category">
                                        <div className="widget-body">
                                            <ChangePasswordForm onSubmit={this.onSubmitChangePassword} />
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            : ""
                    }
                    {
                        this.state.showEditProfile ?
                            <Modal
                                size="lg"
                                centered
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false}
                                show={this.state.showEditProfile}
                                onHide={this.handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="h5 mb-0">
                                            <i className="fad fa-edit text-primary icon"></i> {t("Pages.UsersControl.action.edit")}
                                            :
                                            <span className="text-primary mx-1">
                                                {item.Name}
                                            </span>
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="widget widget-category">
                                        <div className="widget-body">
                                            <EditProfileForm isAdmin={true} user={item} onSubmit={this.onSubmit}></EditProfileForm>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            : ""
                    }
                    {
                        this.state.showChangeSchool ?
                            <Modal
                                size="md"
                                centered
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false}
                                show={this.state.showChangeSchool}
                                onHide={this.handleCloseChangeSchool}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="h5 mb-0">
                                            <i className="fad fa-school text-primary icon"></i>
                                            {
                                                this.props.item.UserType.Id === 2 ?
                                                    t("Pages.UsersControl.action.changeTypeToSchoolTeacher")
                                                    :
                                                    <>
                                                        :
                                                        {t("Pages.UsersControl.action.changeSchool")}
                                                        <span className="text-primary mx-1">
                                                            {item.Name}
                                                        </span>
                                                    </>
                                            }
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="widget widget-category mb-2">
                                        <div className="widget-body">
                                            <div className="form-group w-100">
                                                <div>
                                                    <AsyncSelect
                                                        cacheOptions
                                                        isClearable={true}
                                                        name="SchoolsId"
                                                        placeholder={t('Pages.UsersControl.advancedSearch.School')}
                                                        isRtl={true}
                                                        isSearchable={true}
                                                        defaultOptions
                                                        loadOptions={this.promiseSchoolOptions}
                                                        filterOption={this.filter}
                                                        ref={SchoolsId => this.SchoolsId = SchoolsId}
                                                        theme={theme => ({
                                                            ...theme,
                                                            borderRadius: 0,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#92278f',
                                                                primary: '#92278f',
                                                            },
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group w-100 text-center">
                                                <button className="custom-button" onClick={
                                                    () =>
                                                        item.UserType.Id === 2 ?
                                                            this.changeUserTypeConfirm(item.Id)
                                                            :
                                                            this.onSubmitChangeSchool()
                                                }>
                                                    <span>
                                                        <i className="icon fal fa-save"></i> {t('Pages.UsersControl.notes.Send')}
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            : ""
                    }
                    {
                        this.state.showMove ?
                            <Modal
                                size="md"
                                centered
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false}
                                show={this.state.showMove}
                                onHide={this.handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="h5 mb-0">
                                            <i className="fad fa-exchange text-primary icon"></i>
                                            {t("Pages.StudentInClassRoomsControl.action.move")}
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="widget widget-category mb-2">
                                        <div className="widget-body">
                                            <div className="form-group w-100">
                                                <div>
                                                    <AsyncSelect
                                                        cacheOptions
                                                        isClearable={true}
                                                        name="ClassRoomId"
                                                        placeholder={t('Pages.ClassRoomsControl.title')}
                                                        isRtl={true}
                                                        isSearchable={true}
                                                        defaultOptions
                                                        loadOptions={this.promiseClassRoomOptions}
                                                        filterOption={this.filter}
                                                        ref={ClassRoomId => this.ClassRoomId = ClassRoomId}
                                                        theme={theme => ({
                                                            ...theme,
                                                            borderRadius: 0,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#92278f',
                                                                primary: '#92278f',
                                                            },
                                                        })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group w-100 text-center">
                                                <button className="custom-button" onClick={() => this.onSubmitChangeClassRoom()}>
                                                    <span>
                                                        <i className="icon fal fa-exchange"></i> {t('Pages.StudentInClassRoomsControl.action.move_action')}
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            : ""
                    }
                    <div className="col px-0 mx-0"  >
                        <div className="product-list-item" >
                            {
                                item.IsActive ? "" :
                                    <div className="ribbon-corner ribbon-fold" data-position="left top" title={t('Pages.UsersControl.NotActive')}>
                                        <i className="fas fa-lock"></i>
                                    </div>
                            }
                            {
                                item.IsLocked ?
                                    <div className="ribbon-corner ribbon-fold" data-position="right top" title={t("Pages.UsersControl.IsLocked")}>
                                        <i className="fas fa-user-lock"></i>
                                    </div>
                                    :
                                    item.IsForTest ?
                                        <div className="ribbon-corner ribbon-fold" data-position="right top" title={t('Pages.UsersControl.IsForTest')}>
                                            <i className="fas fa-alarm-exclamation"></i>
                                        </div> : ""

                            }
                            <div className="product-thumb">
                                {
                                    item.Photo ?
                                        <LazyLoadImage src={item.Photo} alt={`${item.Name}`} />
                                        :
                                        <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.Name}`} />
                                }
                                <div className="product-action-link">
                                    {
                                        item.IsDeleted ?
                                            <>
                                                <div
                                                    className="my-1"
                                                    onClick={() => this.restore(item.Id)}
                                                    title={t("Pages.UsersControl.action.undo")}>
                                                    <i className="far fa-trash-undo-alt"></i>
                                                </div>
                                                <div
                                                    className="my-1"
                                                    onClick={() => this.fire(item.Id)}
                                                    title={t("Pages.UsersControl.action.fire")}>
                                                    <i className="far fa-fire-alt"></i>
                                                </div>

                                            </>
                                            :
                                            <>
                                                {
                                                    item.UserType.Id !== 7 ?
                                                        <div className="my-1" onClick={() => this.edit()} title={t("Pages.UsersControl.action.edit")}><i className="far fa-edit"></i></div>
                                                        : ""
                                                }
                                                {
                                                    item.UserType.Id === 2 || item.UserType.Id === 7 ?
                                                        <>
                                                            <div className="my-1" onClick={() => this.bills()} title={t("Pages.UsersControl.action.bills")}><i className="far fa-file-invoice-dollar"></i></div>
                                                        </>
                                                        : ""
                                                }
                                                <div className="my-1" onClick={() => this.notes()} title={t("Pages.UsersControl.action.notes")}>
                                                    <i className={`far ${item.Notes > 0 ? "text-danger fa-notes " : "fa-sticky-note "} `}></i>
                                                </div>
                                                {
                                                    item.UserType.Id !== 7 ?
                                                        <>
                                                            <div className="my-1" onClick={() => this.changePassword()} title={t("Pages.UsersControl.action.change_password")}><i className="far fa-key"></i></div>
                                                            <div className="my-1" onClick={() => this.useAs(item.Id)} title={t("Pages.UsersControl.action.useAs") + item.Name}><i className="text-info fad fa-alien"></i></div>
                                                        </>
                                                        : ""
                                                }
                                                {
                                                    item.UserType.Id === 2 ?
                                                        <>
                                                            <div className="my-1" onClick={() => this.testUser(item.Id)} title={t("Pages.UsersControl.action.testUser")}><i className="text-danger fad fa-alarm-exclamation"></i></div>
                                                        </> : ""
                                                }
                                                {
                                                    item.UserType.Id === 3 ?
                                                        <div className="my-1" onClick={() => this.changePermission(item.Id)} title={t("Pages.UsersControl.action.makeSchoolTeacher")}><i className="text-danger fad fa-water-lower"></i></div>
                                                        : ""
                                                }
                                                {
                                                    item.UserType.Id === 3 || item.UserType.Id === 4 ?
                                                        <div className="my-1" onClick={() => this.changeAccessToAllClass(item.Id)} title={t("Pages.UsersControl.action.accessToAllClass")}><i className="text-primary fa-solid fa-users-between-lines"></i></div>
                                                        : ""
                                                }
                                                {
                                                    item.UserType.Id === 4 ?
                                                        <>
                                                            <div className="my-1" onClick={() => this.changePermission(item.Id)} title={t("Pages.UsersControl.action.makeSchoolManager")}><i className="text-danger fad fa-water-rise"></i></div>
                                                            <div className="my-1" onClick={() => this.showChangeSchool()} title={t("Pages.UsersControl.action.changeSchool")}><i className="text-primary far fa-school"></i></div>
                                                            <div className="my-1" onClick={() => this.changeUserType(item.Id)} title={t("Pages.UsersControl.action.changeTypeToUser")}><i className="text-warning far fa-user-tag"></i></div>
                                                        </>
                                                        : ""
                                                }
                                                {
                                                    item.UserType.Id === 2 ?
                                                        <>
                                                            <div className="my-1" onClick={() => this.changeUserType(item.Id)} title={t("Pages.UsersControl.action.changeTypeToSchoolTeacher")}><i className="text-warning far fa-chalkboard-teacher "></i></div>
                                                        </> : ""
                                                }
                                                {
                                                    item.UserType.Id !== 1 && item.UserType.Id !== 7 ?
                                                        <>
                                                            <div className="my-1" onClick={() => this.changeLoginInfo(item.Id)} title={t("Pages.UsersControl.action.changeLoginInfo")}><i className="text-success far fa-fingerprint "></i></div>
                                                        </> : ""
                                                }
                                                {
                                                    item.UserType.Id === 5 ?
                                                        <>
                                                            <div className="my-1" onClick={() => this.move(item)} title={t("Pages.StudentInClassRoomsControl.action.move")}><i className="text-warning far fa-exchange "></i></div>
                                                        </> : ""
                                                }
                                                {
                                                    item.UserType.Id !== 7 ?
                                                        <div className="my-1" onClick={() => this.props.remove(item.Id)} title={t("Pages.UsersControl.action.remove")}><i className="far fa-trash"></i></div>
                                                        : ""
                                                }
                                                <div className="my-1" onClick={() => this.moreInfo()} title={t("Pages.UsersControl.action.info")}><i className="far fa-info"></i></div>
                                            </>
                                    }
                                </div>
                            </div>
                            <div className="product-content py-3">
                                <h5 className="title"><Link to="#" onClick={() => this.edit(item)}> {item.Name} </Link></h5>
                                <p>
                                    {
                                        item.Birthday ?
                                            <span title={t('Pages.Register.Birthday.title')}>
                                                <i className="icon fal fa-calendar-alt"></i>
                                                {item.Birthday}
                                            </span>
                                            : ""
                                    }
                                    {
                                        item.Sex ?
                                            <span className="mx-2" title={t('Pages.Register.Sex.title')} >
                                                <i className={`icon text-info fad fa-${item.Sex}`}></i>
                                                {t('Pages.Register.Sex.' + item.Sex)}
                                            </span>
                                            : ""
                                    }
                                    {
                                        item.UserType &&
                                            (item.UserType.Id === 2 || item.UserType.Id === 7)
                                            && item.ActivateUntilDate && item.ActivateUntilDate !== "0" ?
                                            <span className="text-success mx-1" title={t('Pages.Profile.ActivateUntilDate.title')} >
                                                <i className="icon fas fa-calendar text-success"></i>
                                                {item.ActivateUntilDate}
                                            </span>
                                            : ""
                                    }
                                </p>
                                <p>
                                    {
                                        item.Username ?
                                            <span title={t('Pages.Register.Username.title')} >
                                                <i className="icon fal fa-hashtag"></i>
                                                {item.Username}
                                            </span>
                                            : ""
                                    }
                                    {
                                        item.Email ?
                                            <>
                                                {
                                                    item.Username ? " - " : ""
                                                }
                                                <a href={`mailto:${item.Email}`} title={t('Pages.Register.Email.title')}>
                                                    <i className="icon fal fa-envelope-open-text"></i>
                                                    {item.Email}
                                                </a>
                                            </>
                                            : ""
                                    }
                                    {
                                        item.Phone ?
                                            <>
                                                {
                                                    item.Email ? " - " : ""
                                                }
                                                <a href={`Tel:${item.Phone}`} title={t('Pages.Register.Phone.title')}>
                                                    <i className="icon fal fa-phone-alt"></i>
                                                    <span dir="ltr">
                                                        {item.Phone}
                                                    </span>
                                                </a>
                                            </> : ""
                                    }
                                </p>

                                <div className="d-flex justify-content-between ">
                                    <p>
                                        {
                                            Country ?
                                                <span title={t('Pages.Register.Country.title')} >
                                                    {Country.label}
                                                </span>
                                                : ""
                                        }
                                        {
                                            item.Address ?
                                                <span title={t('Pages.Register.Address.title')} >
                                                    {
                                                        Country ? " - " : ""
                                                    }
                                                    {item.Address}
                                                </span>
                                                : ""
                                        }
                                        <br />
                                        <span className="mt-2">
                                            {
                                                item.School ?
                                                    <span title={t('Pages.Register.SchoolName.title')}>
                                                        <i className="icon fal fa-school"></i>
                                                        {item.School.Name + " - "}
                                                        <span className="text-danger" >
                                                            {
                                                                item.UserType ?
                                                                    <>
                                                                        {item.UserType.Name}
                                                                    </>
                                                                    : ""
                                                            }
                                                        </span>
                                                    </span>
                                                    : ""
                                            }
                                        </span>
                                    </p>

                                    <div className="d-flex align-items-center justify-content-center flex-wrap">
                                        {
                                            item.ActivateCode ?
                                                <div className="d-flex align-items-center mr-1" >
                                                    <span className="cate btn btn-dark " title={t("Pages.ActivePage.input.title")}>
                                                        <i className="icon fal fa-code"></i>
                                                        {item.ActivateCode}
                                                    </span>
                                                </div> : ""
                                        }

                                        {
                                            item.IsSuperTeacher ?
                                                <div className="d-flex align-items-center mr-1" >
                                                    <span className="cate btn btn-primary " title={t("Pages.Register.IsSuperTeacher")}>
                                                        <i className="fas fa-users-between-lines"></i>
                                                    </span>
                                                </div> : ""
                                        }
                                    </div>
                                </div>

                            </div>
                            {
                                this.state.moreInfo ?
                                    <div className=" w-100 pt-2 mt-1 mb-2 px-3 d-flex flex-wrap justify-content-around" style={{
                                        borderTop: '1px dashed #92278f'
                                    }}>

                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Profile.LastLogin.title")}  :
                                                <div className="text-secondary">
                                                    {item.LastLogin}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Profile.ActivateUntilDate.title")}  :
                                                <div className="text-secondary">
                                                    {
                                                        item.ActivateUntilDate !== "0" ?
                                                            <>
                                                                <i className="text-success icon fad fa-circle"></i> {item.ActivateUntilDate}
                                                            </> :
                                                            <>
                                                                <i className="text-danger icon fad fa-circle"></i> {t('Pages.UsersControl.NotActive')}
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.CreationDate")}  :
                                                <div className="text-secondary">
                                                    {item.CreationDate}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.UpdateDate")}  :
                                                <div className="text-secondary">
                                                    {item.UpdateDate}
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ""
                            }
                        </div>
                    </div>


                </>
            );
        }
        return "";
    }
}

const mapStateToProps = state => {
    return {
    };
};

export default connect(mapStateToProps, { userLogin })(withTranslation('common')(UserItem));