import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';

import TreeView from "../Indices/TreeView";
import { fetchIndices, updateIndicesRemoveCount } from '../Indices/actions';
import { getIndeices, setIndeices, setGames, getGames, removeGame, removeIndeicesCount, setGameSort } from "../../../context/sessionStorage";
import FooterBottom from "../../Footer/FooterBottom";
import LessonItem from "../../Home/Tracks/LessonItem";
import DragDropModal from '../Lessons/Modal/DragDropModal';
import {
    fetchLessons as fetch,
    removeLessons as remove,
    undoLessons as undo,
    clearLessons as clear,
    updateLessonSort as sort
} from '../Lessons/actions';
import api from '../../../api/api';
import history from '../../../history';
import GameObjects from "../Question/View/GameObjects";

class Fun extends React.Component {

    state = { currentItem: '', showDragDropModal: false, indices: [], search: '', startFrom: 0 };

    componentDidMount() {
        let indices = getIndeices();
        if (indices === null || indices.length === 0) {
            this.props.fetchIndices();
        }

        this.setState({
            indices
        });

        const { id } = this.props.match.params;
        if (id && id !== '0') {
            this.setState({
                currentItem: {
                    id: parseInt(id)
                }
            });
            this.props.fetch(id);
        }
        window.scrollToPosition("main-game-section");
    }

    static getDerivedStateFromProps(props, state) {
        state.startFrom = props.startFrom;
        return state;
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    Sync = () => {
        if (this.state.currentItem) {
            this.props.fetch(this.state.currentItem.id);
        }
    }

    SortGame = () => {
        if (this.state.currentItem) {
            this.setState({
                showDragDropModal: true,
            });
        }
    }

    handleCloseDragDropModal = () => {
        this.setState({
            showDragDropModal: false
        });
    }

    setSort = items => {

        items.forEach(item => {
            this.props.sort(item.id, item.sort);
            setGameSort(item.id, item.sort);
        });

        this.setState({
            showDragDropModal: false
        });
    }

    setCurrentItem = item => {
        this.setState({ currentItem: item });
        let checkFromServer = false;
        let lessons = getGames();
        if (lessons === null || lessons.length === 0)
            checkFromServer = true;
        else {
            let filterGame = lessons.lessons.filter(v => v.Index.Id === item.id);
            if (filterGame === null || filterGame.length === 0)
                checkFromServer = true;
        }
        if (checkFromServer) {
            this.props.fetch(item.id);
        }

        history.push(`/ControlPanel/Games/${item.id}`);
    }

    showDelete = id => {
        if (id) {
            this.props.remove(id);
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDelete(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteGame(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });

        }
    }

    deleteGame = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        let Index = '';
        var Games = getGames();
        if (Games) {
            Index = Games.lessons.filter(v => v.Id === id)[0].Index;
        }

        api.delete(`/Lessons/${id}`).then(response => {

            removeGame(id);
            this.props.clear(id);
            this.props.updateIndicesRemoveCount(Index.Id);
            removeIndeicesCount(Index.Id);

            this.setState({
                indices: this.state.indices
            });

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Games.toast.Delete.Title')}</div> <div className="h6">{t('Pages.Games.toast.Delete.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    undoDelete = id => {
        this.props.undo(id);
    }

    GetNode(nodes, Id) {
        var result = false;
        if (nodes) {
            nodes.forEach(node => {
                if (node.id === Id && node.Level >= 2) {
                    result = node.nodes;
                }
            });
            if (result) {
                return result;
            }
            nodes.forEach(node => {
                var subResult = this.GetNode(node.nodes, Id);
                if (subResult) {
                    result = subResult;
                    return false;
                }
            });
        }
        return result;
    }

    renderGames() {
        const { t, user } = this.props;
        let lessons = this.props.lessons;
        let isLoaded = false;

        if (lessons) {
            isLoaded = lessons.isLoaded;
        }

        let toRemove = [];
        if (lessons) {
            toRemove = lessons.toRemove;
        }

        let indices = this.props.indices;
        if (indices) {
            setIndeices(indices);
        } else {
            indices = this.state.indices;
        }

        if (lessons && lessons.lessons && lessons.lessons.length > 0) {
            setGames(lessons);
        } else {
            lessons = getGames();
        }

        if (lessons && lessons.lessons && lessons.lessons.length > 0 && this.state.currentItem) {
            let filterGame = lessons.lessons.filter(v => v.Name.includes(this.state.search) && v.Index.Id === this.state.currentItem.id);

            if (isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.Games.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            if (filterGame.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.Games.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.Games.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            if (filterGame.length === 0) {
                if (indices && indices.nodes && this.state.currentItem) {
                    var nodes = this.GetNode(indices.nodes, this.state.currentItem.id);
                    if (nodes && nodes.length > 0) {
                        return <div className="d-flex flex-wrap justify-content-center justify-content-sm-around">
                            {
                                nodes.map(item => {
                                    return <div className="pr-1 bookWrap mb-50 " key={item.id}>
                                        <Link to="#" className='w-100' onClick={() => this.setCurrentItem(item)}>
                                            <div className="shop-item">
                                                <div className="product-thumb">
                                                    {
                                                        item.icon ?
                                                            <LazyLoadImage src={item.icon} alt={`${item.label}`} />
                                                            :
                                                            <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.label}`} />
                                                    }
                                                </div>
                                                <div className="product-content">
                                                    <h5 className="title">{item.label}</h5>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                })
                            }
                        </div>
                    }
                }
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.Games.NotFound")}
                            </h4>
                        </div>
                    </div>
                );
            }

            return filterGame.sort((a, b) => a.Sort > b.Sort ? 1 : -1).map(lesson =>
                (toRemove && toRemove.includes(lesson.Id)) ? "" :
                    <LessonItem
                        type={"Games"}
                        key={lesson.Id}
                        user={user}
                        IsAdmin={true}
                        setIndex={this.setCurrentItem}
                        lesson={lesson}
                        indices={indices.nodes}
                        remove={this.showDelete}
                    />
            );
        }
        if (!this.state.currentItem) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-glasses "></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.Games.SelectIndex")}
                        </h4>
                    </div>
                </div>
            );
        }
        if (lessons && lessons.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.Games.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }

        if (indices && indices.nodes && this.state.currentItem) {
            var indexNodes = this.GetNode(indices.nodes, this.state.currentItem.id);
            if (indexNodes && indexNodes.length > 0) {
                return <div className="d-flex flex-wrap justify-content-center justify-content-sm-around">
                    {
                        indexNodes.map(item => {
                            return <div className="pr-1 bookWrap mb-50 " key={item.id}>
                                <Link to="#" className='w-100' onClick={() => this.setCurrentItem(item)}>
                                    <div className="shop-item">
                                        <div className="product-thumb">
                                            {
                                                item.icon ?
                                                    <LazyLoadImage src={item.icon} alt={`${item.label}`} />
                                                    :
                                                    <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.label}`} />
                                            }
                                        </div>
                                        <div className="product-content">
                                            <h5 className="title">{item.label}</h5>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        })
                    }
                </div>
            }
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.Games.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    renderWave = () => {

        const { innerWidth: width, innerHeight: height } = window;

        return <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px"
            width={`${height}px`}
            height={`${width}px`}
            viewBox={`0 0 500 1`}
            xmlSpace="preserve">
            <style dangerouslySetInnerHTML={{
                __html:
                    `
                        @keyframes lightwaves {
                            0%,100% { transform: translate(0,0); }
                            25%     { transform: translate(5px,5px); transform: scale(1.05); }
                            50%     { transform: translate(25px, 5px); }
                            75%     { transform: translate(12px,10px); transform: scale(1.05); }
                        }
                        @keyframes darkwaves {
                            0%,100% { transform: translate(0,0); }
                            25%     { transform: translate(25px, 5px); transform: scale(1.05); }
                            50%     { transform: translate(12px,10px); }
                            75%     { transform: translate(5px,5px); }
                        }
                    `
            }}></style>

            <g id="DarkWaves" className="DarkWaves" style={{
                animation: 'darkwaves 2.8s infinite'
            }}>
                <g>
                    <path style={{ fill: '#6cecff' }} d="M761.8,202.4c-24.7,0-36.7,7.2-49.5,14.7c-13.2,7.8-26.8,15.9-53.8,15.9c-27,0-40.6-8.1-53.8-15.9
               c-12.8-7.6-24.9-14.7-49.6-14.7c-24.7,0-36.8,7.2-49.6,14.7c-13.2,7.8-26.8,15.9-53.8,15.9c-27,0-40.7-8.1-53.8-15.9
               c-12.8-7.6-24.9-14.7-49.6-14.7c-24.7,0-36.8,7.2-49.6,14.7c-13.2,7.8-26.8,15.9-53.8,15.9c-27,0-40.7-8.1-53.8-15.9
               c-12.8-7.6-24.9-14.7-49.6-14.7c-24.7,0-36.8,7.2-49.6,14.7C78.7,224.9,65,232.9,38,232.9c-27,0-40.4-8.1-53.6-15.9
               c-12.1-7.1-24.3-13.9-45.4-14.6V602h922V232.9c-22.6-0.8-37.2-8.4-49.7-15.8C798.5,209.5,786.5,202.4,761.8,202.4z"/>
                </g>
            </g>
            <g id="LightWaves" className="LightWaves position-relative" style={{
                animation: 'lightwaves 2s infinite'
            }}>
                <g>
                    <path style={{ fill: '#24bef4' }} d="M750.9,229.8c-14.8-7.9-28.7-15.4-57.2-15.4c-28.5,0-42.4,7.5-57.2,15.4c-15.2,8.2-30.9,16.6-62.1,16.6
               s-46.9-8.4-62.1-16.6c-14.8-7.9-28.7-15.4-57.2-15.4c-28.5,0-42.4,7.5-57.2,15.4c-15.2,8.2-30.9,16.6-62.1,16.6
               c-31.2,0-46.9-8.4-62.1-16.6c-14.8-7.9-28.7-15.4-57.2-15.4c-28.5,0-42.4,7.5-57.2,15.4c-15.2,8.2-30.9,16.6-62.1,16.6
               c-31.2,0-46.9-8.4-62.1-16.6c-14.8-7.9-28.9-15.4-57.3-15.4c-16.9,0-28.8,2.6-38.8,6.4V600h922V237c-12,5.3-26,9.4-47.8,9.4
               C782.1,246.4,766.1,237.9,750.9,229.8z"/>
                </g>
            </g>
        </svg >
    }

    render() {
        const { t } = this.props;

        let indices = this.props.indices;
        if (indices) {
            setIndeices(indices);
        } else {
            indices = this.state.indices;
        }

        let isLoaded = false;
        let lessons = this.props.lessons;
        if (lessons) {
            isLoaded = lessons.isLoaded;
        }

        let IndexId = (this.state.currentItem) ? parseInt(this.state.currentItem.id) : 0;

        let nodes = this.props.lessons;
        if (lessons && lessons.lessons && lessons.lessons.length > 0) {
            nodes = this.props.lessons.lessons.sort((a, b) => a.Sort > b.Sort ? 1 : -1);
        } else {
            nodes = getGames()
            if (nodes) {
                nodes = nodes.lessons.sort((a, b) => a.Sort > b.Sort ? 1 : -1);
            }
        }

        const { innerWidth: width } = window;

        return (
            <>
                <Helmet>
                    <title>{t("PageHeader.FunPath")} | {t('alarabeyya')}</title>
                </Helmet>
                <DragDropModal
                    hideButton={true}
                    show={this.state.showDragDropModal}
                    IndexId={IndexId}
                    nodes={nodes}
                    API={`/Lessons/`}
                    setSort={this.setSort}
                    handleClose={this.handleCloseDragDropModal}></DragDropModal>

                <section className="position-relative  oh" id="main-game-section" style={{
                    backgroundColor: '#f6d997',
                    minHeight: '100vh'
                }}>

                    <div className="position-fixed w-100 h-100" style={{
                        backgroundColor: '#f6d997',
                        height: '100vh',
                        top: '0'
                    }}>
                        <div className=" position-relative" style={{
                            backgroundColor: '#f6d997',
                            height: '100vh'
                        }}>

                            <div className="position-absolute fun-bg-1 d-flex flex-column oh">
                                <div className="w-100 h-100 position-relative"></div>
                                <div className="w-100 h-100 position-relative"></div>
                            </div>
                            <div className="position-absolute fun-bg-2"></div>

                            <div className="position-absolute d-md-none d-md-block" style={{
                                top: `0%`,
                                right: `0%`,
                                transform: 'rotate(-90deg)',
                                transformOrigin: 'right bottom',
                                marginTop: `-${width}px`
                            }}>
                                {
                                    this.renderWave()
                                }
                            </div>
                            <div className="position-absolute" style={{
                                bottom: `10%`,
                                left: `5%`
                            }}>
                                <GameObjects objectCss="BoysPlayWithSand" />
                            </div>

                            <div className="position-absolute CrabMove objectMovment" style={{
                                top: `5%`,
                                right: `25%`
                            }}>
                                <GameObjects objectCss="Crab" scale={0.6} />
                            </div>

                            <div className="position-absolute d-md-none d-md-block" style={{
                                bottom: '20%',
                                right: `10%`
                            }}>
                                <div className="position-relative d-flex justify-content-center ">
                                    <div className="position-absolute">
                                        <GameObjects objectCss="WaterCircles" />
                                    </div>
                                    <div className="position-absolute" style={{
                                        top: '-80px',
                                        marginLeft: '-20px'
                                    }}>
                                        <GameObjects objectCss="DiveFish" scale={0.6} transformOriginY={"bottom"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container padding-top">


                        <div className="row justify-content-center w-100" data-aos="fade-top" data-aos-easing="linear">
                            <div className="section-header">
                                <div className="mb-4 title h3 font-weight-bold">{t('ControlPanel.title')}</div>
                                <h3 className="title  font-weight-bold" style={{
                                    color: '#74b21f'
                                }}>{t('PageHeader.FunPath')} </h3>
                            </div>
                        </div>

                        <div className="row mb-30-none">
                            <div className="col-lg-4 mb-50">
                                {indices ?
                                    <TreeView
                                        IsAdmin={true}
                                        title={this.props.title}
                                        parentId={this.props.parentId}
                                        currentId={IndexId}
                                        data={indices.nodes}
                                        type="Fun"
                                        startFrom={this.state.startFrom}
                                        toRemove={indices.toRemove}
                                        setCurrentItem={this.setCurrentItem} />
                                    : ""
                                }
                            </div>
                            <div className="col-lg-8 mb-50">
                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex">
                                            <div className="p-2 flex-grow-1 ">
                                                <form className="search-form">
                                                    <input type="text" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.Games.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                            <div className="pt-3">
                                                <Link to={`/ControlPanel/Games/Edit/0`} title={t("Pages.Games.Add")} className="btn btn-primary ">
                                                    <i className="fas fa-plus"></i>
                                                </Link>
                                                <div onClick={() => this.SortGame()} title={t("Pages.Games.Sort")} className="btn btn-dark mx-1">
                                                    <i className="fas fa-sort-numeric-up"></i>
                                                </div>
                                                <div onClick={() => this.Sync()} title={t("Pages.Games.Sync")} className="btn btn-danger">
                                                    {
                                                        isLoaded ?
                                                            <i className="fas fa-sync-alt"></i>
                                                            :
                                                            <i className="fas fa-sync-alt fa-spin"></i>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.renderGames()}
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-sticky">
                    <FooterBottom />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        indices: state.indices,
        lessons: state.lessons
    };
};

export default connect(mapStateToProps, { fetchIndices, updateIndicesRemoveCount, fetch, remove, undo, clear, sort })(withTranslation('common')(Fun));