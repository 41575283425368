import React from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import ReactPixel from 'react-facebook-pixel';
import { LazyLoadImage } from "react-lazy-load-image-component";

import Footer from "../../../Footer/FooterBottom";
import UserForm from "./Forms/Step1";
import Backgrounds from "../../../Admin/Question/View/Background";
import api, { setToken } from "../../../../api/api";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { userLogin } from "../../../actions";

class SingleRegister extends React.Component {

    constructor(props) {
        super(props);
        ReactPixel.init('863207705778506');
        ReactPixel.track('PageView', "Register Free Page");
    }

    componentDidMount() {
        window.scrollToPosition("single-register-section");
    }

    saveLoginInfo = data => {

        const { t } = this.props;
        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        ReactPixel.track('GoToPurchase', data?.Email);

        api.post(`/AbroadRegister/`, data
        ).then(response => {
            if (response.data.code === "exists_email") {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Register.Email.error_toast.Title')}</div> <div className="h6">{t('Pages.Register.Email.error_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            } else if (response.data.code === "success") {

                const token = response.data.data.token;
                localStorage.setItem('token', token);

                setToken(token);

                const browserToken = response.data.data.browserToken;
                localStorage.setItem('browserToken', browserToken);

                this.props.userLogin(response.data.data);

                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.Register.success_toast.Title')}</div> <div className="h6">{t('Pages.Register.success_toast.Body')}</div></div>,
                    type: toast.TYPE.INFO,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.props.goToActivePage();
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    render() {
        const { t, location } = this.props;
        return (
            <>
                <Helmet>
                    <title>
                        {t('Pages.Register.Single.title')} | {t('alarabeyya')}
                    </title>
                </Helmet>
                <style dangerouslySetInnerHTML={{ __html: `html{width: calc(100% );overflow-x: unset;}header{ display:none; }body{overflow-x: hidden;}section{padding-top:5px !important;}.footer-bottom{display:none;}` }}></style>
                <section className="pos-rel call-in-action call-overlay oh" id="single-register-section" style={{
                    backgroundColor: '#75cdff',
                    borderBottom: '1px solid #0e2c53'
                }}>
                    <div className="position-relative padding-bottom padding-top" >
                        <Backgrounds CustomBackground="Registration" />
                        <div className="container h-100" >
                            <div className="widget widget-category col-xl-12 mx-auto">
                                <div className="widget-header p-3 d-flex justify-content-center align-items-center">
                                    <LazyLoadImage src="/assets/images/logo/logo-1.png" alt={`${t('alarabeyya')}`} />
                                </div>
                                <div className="widget-body">
                                    <div className="mb-3 px-2"></div>
                                    <UserForm
                                        onSubmit={this.saveLoginInfo}
                                        country_code={location.location?.country_code}
                                        Address={location.location?.Address} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        location: state.location
    };
};

export default connect(mapStateToProps, { userLogin })(withTranslation('common')(SingleRegister));