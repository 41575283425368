import React from 'react'
import { Field, reduxForm } from 'redux-form';
import Select from 'react-select';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import configData from "../../Config/countries.json";

class EditSchoolForm extends React.Component {

    state = {
        initialize: false,
        IsLocked: false,
        IsForTest: false,
        IsTeacher: false,
        touchedActivateUntilDate: false,
        errorActivateUntilDate: '',
        errorList: [],
        countries: [],
        CountryIndex: -1
    }

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
        this.state.countries = configData.countries.sort((a, b) => a.label > b.label ? 1 : -1).map(item => {
            return {
                value: item.value,
                name: item.label,
                label: <div><LazyLoadImage src={process.env.PUBLIC_URL + item.icon} />  {item.label}</div>,
            };
        });
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.initialize && props.school && Object.keys(props.school).length !== 0) {

            var index = state.countries.findIndex(p => p.value === props.school.Country);

            setTimeout(() => {
                props.initialize({
                    SchoolName: props.school.Name,
                    SchoolAddress: props.school.Address,
                    Code: props.school.Code,
                    SchoolCode: props.school.PosCode,
                    SchoolFax: props.school.Fax,
                    SchoolPhone: props.school.Phone,
                    SchoolEmail: props.school.Email,
                    Country: props.school.Country,
                    MaxTeacherCount: props.school.MaxTeacherCount,
                    MaxStudentCount: props.school.MaxStudentCount
                });
            }, 500);

            state.IsLocked = props.school.IsLocked;
            state.IsForTest = props.school.IsForTest;

            state.CountryIndex = index;
            state.initialize = true;
        }
        return state;
    }

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY'));
        this.setState({
            touchedActivateUntilDate: false,
            errorActivateUntilDate: ''
        });
    }
    onBlurActivateUntilDate = e => {
        const value = e.target.value;
        this.validateActivateUntilDate(value);
    }
    validateActivateUntilDate = value => {
        const { t } = this.props;
        if (!value) {
            this.setState({
                touchedActivateUntilDate: true,
                errorActivateUntilDate: t('Pages.UsersControl.bills.ActivateUntilDate.error')
            });
        } else if (!/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i.test(value)) {
            this.setState({
                touchedActivateUntilDate: true,
                errorActivateUntilDate: t('Pages.UsersControl.bills.ActivateUntilDate.error_notmatch')
            });
        }
    }

    setIsForTest = IsForTest => {
        this.setState({
            IsForTest
        });
    }
    setIsLocked = IsLocked => {
        this.setState({
            IsLocked
        });
    }
    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.includes(input);
        }
        return true;
    }
    validate = () => {
        const errors = {};

        if (!this.Country.state.value || !this.Country.state.value.value) {
            errors.Country = 'Pages.Register.Country.error';
        }

        if (!this.SchoolName.ref.current.getValue()) {
            errors.SchoolName = 'Pages.Register.SchoolName.error';
        }

        if (!this.SchoolAddress.ref.current.getValue()) {
            errors.SchoolAddress = 'Pages.Register.SchoolAddress.error';
        }

        if (!this.SchoolCode.ref.current.getValue()) {
            errors.SchoolCode = 'Pages.Register.SchoolCode.error';
        }

        if (!this.SchoolFax.ref.current.getValue()) {
            errors.SchoolFax = 'Pages.Register.SchoolFax.error';
        }

        if (!this.SchoolPhone.ref.current.getValue()) {
            errors.SchoolPhone = 'Pages.Register.SchoolPhone.error';
        }

        if (!this.Code.ref.current.getValue()) {
            errors.Code = 'Pages.Register.Code.error';
        }

        if (!this.SchoolEmail.ref.current.getValue()) {
            errors.SchoolEmail = 'Pages.Register.SchoolEmail.error';
        } else if (!/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(this.SchoolEmail.ref.current.getValue())) {
            errors.SchoolEmail = 'Pages.Register.SchoolEmail.error_notmatch';
        }

        if (this.props.isAdmin) {
            if (!this.MaxTeacherCount.ref.current.getValue()) {
                errors.MaxTeacherCount = 'Pages.Register.MaxTeacherCount.error';
            }
            if (!this.MaxStudentCount.ref.current.getValue()) {
                errors.MaxStudentCount = 'Pages.Register.MaxStudentCount.error';
            }
        }

        return errors;
    }
    onSubmit = e => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        var errors = this.validate();
        if (errors && errors.length !== 0 && Object.keys(errors).length !== 0) {

            let errorList = [];
            for (const [key, value] of Object.entries(errors)) {
                errorList.push(<li key={`error-${key}`}>{t(value)}.</li>);
            }
            this.setState({ errorList });

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-thumbs-up"></i> {t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            let postData = {
                SchoolName: this.SchoolName.ref.current.getValue(),
                SchoolAddress: this.SchoolAddress.ref.current.getValue(),
                Code: this.Code.ref.current.getValue(),
                IsForTest: this.state.IsForTest,
                IsLocked: this.state.IsLocked,
                Country: this.Country.state.value.value,
                SchoolCode: this.SchoolCode.ref.current.getValue(),
                SchoolFax: this.SchoolFax.ref.current.getValue(),
                SchoolEmail: this.SchoolEmail.ref.current.getValue(),
                SchoolPhone: this.SchoolPhone.ref.current.getValue(),
                MaxTeacherCount: this.MaxTeacherCount.ref.current.getValue(),
                MaxStudentCount: this.MaxStudentCount.ref.current.getValue(),
                ActivateUntilDate: this.ActivateUntilDate && this.ActivateUntilDate.ref ? this.ActivateUntilDate.ref.value : ""
            };
            this.setState({ errorList: [] });

            this.props.onSubmit(postData, this._toastId.current);

        }
        e.preventDefault();
    }

    render() {
        const { submitting, t, isAdmin, isEdit } = this.props;

        return (
            <>
                <form method="POST" className="contact-form" onSubmit={this.onSubmit} autoComplete="new-password">

                    <div className={`alert alert-danger mb-5 p-5 w-100 ${this.state.errorList.length === 0 ? 'd-none' : ''} `} role="alert">
                        <h4 className="alert-heading">{t('ErrorsList')}</h4>
                        <div className="border-bottom border-white opacity-20"></div>
                        <div className="alert-text">
                            <ul className="list-unstyled mt-4">
                                <li>
                                    <ul>
                                        {this.state.errorList}
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className='text-dark font-size-h5'>{t('Pages.Register.SchoolName.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <Field
                                ref={SchoolName => this.SchoolName = SchoolName}
                                name="SchoolName"
                                component={this.renderField}
                                type="text"
                                required={true}
                                placeholder={t('Pages.Register.SchoolName.Placeholder')}
                                title={t('Pages.Register.SchoolName.title')}
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label className='text-dark font-size-h5'>{t('Pages.Register.SchoolEmail.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <Field
                                ref={SchoolEmail => this.SchoolEmail = SchoolEmail}
                                name="SchoolEmail"
                                component={this.renderField}
                                type="email"
                                required={true}
                                placeholder={t('Pages.Register.SchoolEmail.Placeholder')}
                                title={t('Pages.Register.SchoolEmail.title')}
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label className='text-dark font-size-h5'>{t('Pages.Register.Country.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <Select
                                defaultValue={this.state.CountryIndex >= 0 ? {
                                    label: this.state.countries[this.state.CountryIndex].label,
                                    value: this.state.countries[this.state.CountryIndex].value
                                } : ""}
                                name="Country"
                                placeholder={t('Pages.Register.Country.Placeholder')}
                                isRtl={true}
                                isSearchable={true}
                                ref={Country => this.Country = Country}
                                options={this.state.countries}
                                filterOption={this.filterOptions}
                                theme={theme => ({
                                    ...theme,
                                    borderRadius: 0,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#92278f',
                                        primary: '#92278f',
                                    },
                                })}
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label className='text-dark font-size-h5'>{t('Pages.Register.SchoolAddress.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <Field
                                ref={SchoolAddress => this.SchoolAddress = SchoolAddress}
                                name="SchoolAddress"
                                component={this.renderField}
                                type="text"
                                required={true}
                                placeholder={t('Pages.Register.SchoolAddress.Placeholder')}
                                title={t('Pages.Register.SchoolAddress.title')}
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label className='text-dark font-size-h5'>{t('Pages.Register.SchoolPhone.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <Field
                                ref={SchoolPhone => this.SchoolPhone = SchoolPhone}
                                name="SchoolPhone"
                                component={this.renderField}
                                type="text"
                                required={true}
                                placeholder={t('Pages.Register.SchoolPhone.Placeholder')}
                                title={t('Pages.Register.SchoolPhone.title')}
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label className='text-dark font-size-h5'>{t('Pages.Register.SchoolFax.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <Field
                                ref={SchoolFax => this.SchoolFax = SchoolFax}
                                name="SchoolFax"
                                component={this.renderField}
                                type="text"
                                required={true}
                                placeholder={t('Pages.Register.SchoolFax.Placeholder')}
                                title={t('Pages.Register.SchoolFax.title')}
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label className='text-dark font-size-h5'>{t('Pages.Register.SchoolCode.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <Field
                                ref={SchoolCode => this.SchoolCode = SchoolCode}
                                name="SchoolCode"
                                component={this.renderField}
                                type="text"
                                required={true}
                                placeholder={t('Pages.Register.SchoolCode.Placeholder')}
                                title={t('Pages.Register.SchoolCode.title')}
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label className='text-dark font-size-h5'>{t('Pages.Register.Code.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                        <div>
                            <Field
                                ref={Code => this.Code = Code}
                                name="Code"
                                component={this.renderField}
                                type="text"
                                required={true}
                                placeholder={t('Pages.Register.Code.Placeholder')}
                                title={t('Pages.Register.Code.title')}
                                className="form-control"
                            />
                        </div>
                    </div>

                    {
                        isAdmin ?
                            <>
                                <div className="bg-white w-100 p-1 mb-2"></div>
                                <div className="form-group">
                                    <label className='text-dark font-size-h5'>{t('Pages.Register.MaxTeacherCount.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                    <div>
                                        <Field
                                            ref={MaxTeacherCount => this.MaxTeacherCount = MaxTeacherCount}
                                            name="MaxTeacherCount"
                                            component={this.renderField}
                                            type="number"
                                            min="1"
                                            step="1"
                                            required={true}
                                            placeholder={t('Pages.Register.MaxTeacherCount.Placeholder')}
                                            title={t('Pages.Register.MaxTeacherCount.title')}
                                            className="form-control"
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label className='text-dark font-size-h5'>{t('Pages.Register.MaxStudentCount.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                    <div>
                                        <Field
                                            ref={MaxStudentCount => this.MaxStudentCount = MaxStudentCount}
                                            name="MaxStudentCount"
                                            component={this.renderField}
                                            type="number"
                                            min="1"
                                            step="1"
                                            required={true}
                                            placeholder={t('Pages.Register.MaxStudentCount.Placeholder')}
                                            title={t('Pages.Register.MaxStudentCount.title')}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="text-dark font-size-h5" htmlFor="IsLocked">
                                        <i className="icon fas fa-user-lock"></i> {t(`Pages.UsersControl.IsLocked`)}
                                    </label>
                                    <div className="custom-control custom-checkbox mr-sm-4 py-2 checkbox-dark">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            onChange={e => this.setIsLocked(e.target.checked)}
                                            ref={IsLocked => this.IsLocked = IsLocked}
                                            defaultChecked={this.state.IsLocked}
                                            name="IsLocked"
                                            id="IsLocked" />
                                        <label className="custom-control-label" htmlFor="IsLocked" >
                                            {t(`Pages.UsersControl.IsLocked`)}
                                        </label>
                                    </div>
                                </div>
                                {
                                    isEdit ?
                                        <>
                                            <div className="bg-white w-100 p-1 mb-2"></div>
                                            <div className="form-group">
                                                <label className="text-dark font-size-h5" htmlFor="IsForTest">
                                                    <i className="icon fas fa-alarm-exclamation"></i> {t(`Pages.SchoolsControl.IsForTest`)}
                                                </label>
                                                <div className="custom-control custom-checkbox mr-sm-4 py-2 checkbox-danger">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        onChange={e => this.setIsForTest(e.target.checked)}
                                                        ref={IsForTest => this.IsForTest = IsForTest}
                                                        defaultChecked={this.state.IsForTest}
                                                        name="IsForTest"
                                                        id="IsForTest" />
                                                    <label className="custom-control-label" htmlFor="IsForTest" >
                                                        {t(`Pages.SchoolsControl.IsForTest`)}
                                                    </label>
                                                </div>
                                            </div>
                                            {
                                                this.state.IsForTest ?
                                                    <>
                                                        <div className="form-group  col-md">
                                                            <label className='text-dark font-size-h5'>{t('Pages.UsersControl.bills.ActivateUntilDate.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                                                            <div>
                                                                <DateRangePicker
                                                                    ref={ActivateUntilDate => this.ActivateUntilDate = ActivateUntilDate}
                                                                    initialSettings={{
                                                                        drops: 'up',
                                                                        opens: 'center',
                                                                        autoUpdateInput: false,
                                                                        cancelClass: "btn btn-sm btn-danger",
                                                                        singleDatePicker: true,
                                                                        showDropdowns: true,
                                                                        minYear: 2021,
                                                                        defaultValue: this.state.ActivateUntilDate,
                                                                        locale: {
                                                                            format: 'DD/MM/YYYY',
                                                                            cancelLabel: t('Close'),
                                                                            firstDay: 6,
                                                                            applyLabel: t('Apply'),
                                                                            monthNames: [
                                                                                `${t("months.January")}`,
                                                                                `${t("months.February")}`,
                                                                                `${t("months.March")}`,
                                                                                `${t("months.April")}`,
                                                                                `${t("months.May")}`,
                                                                                `${t("months.June")}`,
                                                                                `${t("months.July")}`,
                                                                                `${t("months.August")}`,
                                                                                `${t("months.September")}`,
                                                                                `${t("months.October")}`,
                                                                                `${t("months.November")}`,
                                                                                `${t("months.December")}`
                                                                            ],
                                                                            daysOfWeek: [
                                                                                t("daysOfWeek.Sunday"),
                                                                                t("daysOfWeek.Monday"),
                                                                                t("daysOfWeek.Tuesday"),
                                                                                t("daysOfWeek.Wednesday"),
                                                                                t("daysOfWeek.Thursday"),
                                                                                t("daysOfWeek.Friday"),
                                                                                t("daysOfWeek.Saturday")
                                                                            ],
                                                                        },
                                                                    }}
                                                                    onApply={this.handleApplyDate} >
                                                                    <input
                                                                        defaultValue={this.state.ActivateUntilDate}
                                                                        name="ActivateUntilDate"
                                                                        autoComplete="off"
                                                                        required
                                                                        type="text"
                                                                        onBlur={e => this.onBlurActivateUntilDate(e)}
                                                                        className={`form-control ${(this.state.touchedActivateUntilDate && this.state.errorActivateUntilDate) ? 'is-invalid' : ''}`}
                                                                        placeholder={t('Pages.UsersControl.bills.ActivateUntilDate.Placeholder')}
                                                                        title={t('Pages.UsersControl.bills.ActivateUntilDate.title')}
                                                                    />
                                                                </DateRangePicker>
                                                                {this.state.touchedActivateUntilDate && this.state.errorActivateUntilDate && <div className="invalid-feedback">{this.state.errorActivateUntilDate}</div>}
                                                            </div>
                                                        </div>
                                                    </>
                                                    : ""
                                            }
                                        </> : ""
                                }
                            </> : ""
                    }

                    <div className="form-group w-100 text-center">
                        <button className="custom-button" disabled={submitting} >
                            <span>
                                <i className="icon fal fa-save"></i> {t('Pages.ProfileEdit.Send')}
                            </span>
                        </button>
                    </div>

                </form>
            </>
        );
    }
}

const validate = values => {
    const errors = {};

    if (!values.MaxTeacherCount) {
        errors.MaxTeacherCount = 'Pages.Register.MaxTeacherCount.error';
    }
    if (!values.MaxStudentCount) {
        errors.MaxStudentCount = 'Pages.Register.MaxStudentCount.error';
    }

    if (!values.SchoolName) {
        errors.SchoolName = 'Pages.Register.SchoolName.error';
    }

    if (!values.Code) {
        errors.Code = 'Pages.Register.Code.error';
    }

    if (!values.SchoolAddress) {
        errors.SchoolAddress = 'Pages.Register.SchoolAddress.error';
    }

    if (!values.SchoolCode) {
        errors.SchoolCode = 'Pages.Register.SchoolCode.error';
    }

    if (!values.SchoolFax) {
        errors.SchoolFax = 'Pages.Register.SchoolFax.error';
    }

    if (!values.SchoolPhone) {
        errors.SchoolPhone = 'Pages.Register.SchoolPhone.error';
    }

    if (!values.Country) {
        errors.Country = 'Pages.Register.Country.error';
    }

    if (!values.SchoolEmail) {
        errors.SchoolEmail = 'Pages.Register.SchoolEmail.error';
    } else if (!/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.SchoolEmail)) {
        errors.SchoolEmail = 'Pages.Register.SchoolEmail.error_notmatch';
    }

    return errors;
}

export default withTranslation('common')(reduxForm({
    form: 'EditSchoolForm',
    validate
})(EditSchoolForm))