import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { LazyLoadImage } from "react-lazy-load-image-component";
import Modal from 'react-bootstrap/Modal';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment";

import api from "../../../api/api";
import FooterBottom from "../../Footer/FooterBottom";
import classType from "../../Config/classType.json";

class SchoolCompetition extends React.Component {

    state = {
        data: [],
        ClassType: [],
        Division: [],
        index: 0,
        show: false,
        showSetting: false,
        date: moment().add(-30, 'd').format('DD/MM/YYYY') + " - " + moment().format('DD/MM/YYYY'),
        startDate: moment().add(-30, 'd').format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY')
    };

    constructor(props) {
        super(props);
        this.state.ClassType = classType.ClassType.sort((a, b) => a.Id > b.Id ? 1 : -1);
        this.state.Division = classType.Division.sort((a, b) => a.Id > b.Id ? 1 : -1);
    }

    componentDidMount() {
        window.scrollToPosition("main-game-stage-section");
        this.getResult(0);
    }

    showSetting = () => {
        this.setState({
            showSetting: !this.state.showSetting
        });
    }

    showResult = index => {
        window.scrollToPosition("main-game-stage-section");
        this.setState({
            data: [],
            show: false,
            index
        });
        this.getResult(index);
    }

    onSubmitSearch = () => {
        this.getResult(this.state.index);
        this.showSetting();
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY'));
        this.setState({
            startDate: picker.startDate.format('DD/MM/YYYY'),
            endDate: picker.endDate.format('DD/MM/YYYY'),
            date: picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY')
        });
    }

    getResult = index => {
        setTimeout(() => {
            api.post(`/StudentLessonMark/`, {
                ClassTypeId: index,
                startDate: this.state.startDate,
                endDate: this.state.endDate
            }).then(response => {
                this.setState({
                    data: response.data.data,
                    show: true
                });
            });
        }, 500);
    }

    renderWinner = (index, item) => {
        if (item) {
            const { t } = this.props;
            const Name = item.User.FirstName + " " + item.User.SecondName + " " + item.User.LastName;

            let ClassType = "";
            if (item.User.ClassRoom.ClassTypeId) {
                ClassType = this.state.ClassType.filter(p => p.Id === item.User.ClassRoom.ClassTypeId)[0];
            }

            let Division = "";
            if (item.User.ClassRoom.DivisionId) {
                Division = this.state.Division.filter(p => p.Id === item.User.ClassRoom.DivisionId)[0];
            }

            return <div title={t('Pages.Competition.Winner.' + index) + " " + Name} className="teacher-single-item mb-0" >
                <div className=" d-flex flex-wrap flex-column align-content-center align-items-center mb-0 " style={{
                    marginTop: '2rem'
                }}>

                    <div className="position-relative d-block" style={{ zIndex: 8 }}>
                        <div className="position-absolute w-100" style={{ marginTop: index === 1 ? "-55px" : '-36px' }} >
                            <div className=" d-flex justify-content-center " >
                                <div className={`display-${index === 1 ? "3" : "4"} pb-4`}>
                                    <i className={`fa-solid fa-crown competition-color  text-warning`} style={{
                                        WebkitTextStrokeColor: '#0e2c53',
                                        WebkitTextStrokeWidth: '2px'
                                    }}></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="teacher-single-thumb d-flex align-items-center justify-content-center m-0" style={{
                        borderColor: '#0e2c53',
                        width: '200px',
                        borderWidth: '5px',
                        backgroundColor: '#f7f9ee'
                    }}>
                        <LazyLoadImage src={`${item.User && item.User.Photo ? item.User.Photo : "/assets/images/logo/sub.png"}`} alt={Name} />
                    </div>
                    <div className="w-auto mt--40" style={{ zIndex: 8 }}>
                        <div className="text-center mx-3">
                            <div className={`p-3 h4 mb-0 rounded-custom `} style={{
                                backgroundColor: '#0e2c53',
                            }}>
                                <div className="text-center">
                                    <div className={`h5 text-truncate mb-0 pb-1 text-white`} title={Name}>
                                        {Name}
                                    </div>
                                </div>
                            </div>
                            {
                                ClassType && Division ?
                                    <span className="d-flex justify-content-center mb-0" style={{ marginTop: '-15px', height: '35px' }}>
                                        <div className="py-2 px-2 rounded-custom h6 mb-0 text-truncate text-white" title={item.ClassRoom} style={{ background: '#1b3f7b' }}>
                                            {ClassType.Name + " - " + Division.Name}
                                        </div>
                                    </span>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        }
        return "";
    }

    renderTableRows = () => {
        return this.state.data.slice(3, 10).map((item, index) => {
            const Name = item.User.FirstName + " " + item.User.SecondName + " " + item.User.LastName;

            let ClassType = "";
            if (item.User.ClassRoom.ClassTypeId) {
                ClassType = this.state.ClassType.filter(p => p.Id === item.User.ClassRoom.ClassTypeId)[0];
            }

            let Division = "";
            if (item.User.ClassRoom.DivisionId) {
                Division = this.state.Division.filter(p => p.Id === item.User.ClassRoom.DivisionId)[0];
            }

            return <tr className="mb-2" key={index}>
                <td className="mb-2 text-center h2 rounded-circle px-2 bg-white mx-auto font-weight-bold" style={{
                    color: '#0e2c53',
                    width: '50px',
                    height: '50px',
                    lineHeight: '50px'
                }}>
                    {index + 4}
                </td>
                <td className="mb-2 text-center p-2 rounded text-white mx-1 font-weight-bold" style={{
                    minHeight: '50px',
                    background: '#0e2c53'
                }}>
                    {Name}

                </td>

                <td className="mb-2 text-center p-2 rounded bg-white font-weight-bold" style={{
                    minHeight: '50px',
                    color: '#0e2c53'
                }}>
                    {ClassType.Name + " - " + Division.Name}
                </td>
            </tr>
        });
    }

    render() {
        const { t } = this.props;

        let ranges = {};
        ranges[t("range.Today")] = [moment().toDate(), moment().toDate()];
        ranges[t("range.Yesterday")] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        ranges[t("range.ThisMonth")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[t("range.LastMonth")] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
        ranges[t("range.ThisYear")] = [moment().startOf('year'), moment().endOf('year')];

        if (moment().startOf('year').month("September") < moment()) {
            ranges[t("range.FirstSemester")] = [moment().startOf('year').month("September"), moment().endOf('year')];
            ranges[t("range.ThisStudyYear")] = [moment().startOf('year').month("September").startOf('month'), moment().toDate()];
        } else {
            ranges[t("range.FirstSemester")] = [moment().subtract(1, 'year').month("September"), moment().subtract(1, 'year').endOf('year')];
            if (moment().startOf('year') <= moment()) {
                ranges[t("range.SecondSemester")] = [moment().startOf('year'), moment().startOf('year').month("April").day(10)];
            }
            if (moment().startOf('year').month("April").day(15) <= moment()) {
                ranges[t("range.ThirdSemester")] = [moment().startOf('year').month("April").day(15), moment().startOf('year').month("June").endOf('month')];
            }
            ranges[t("range.ThisStudyYear")] = [moment().subtract(1, 'year').month("September").startOf('month'), moment().toDate()];
        }

        return (
            <>
                {
                    this.state.showSetting ?
                        <Modal
                            size="md"
                            centered
                            backdrop="static"
                            keyboard={false}
                            enforceFocus={false}
                            show={this.state.showSetting}
                            onHide={this.showSetting}>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <div className="h5 mb-0">
                                        <i className="fad fa-gears text-primary icon"></i>
                                        {t("Pages.Competition.setting")}
                                    </div>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="widget widget-category mb-2">
                                    <div className="widget-body">
                                        <div className="contact-form">

                                            <div className="form-group w-100">
                                                <div>
                                                    <DateRangePicker
                                                        ref={Date => this.Date = Date}
                                                        initialSettings={{
                                                            opens: 'center',
                                                            autoUpdateInput: false,
                                                            buttonClasses: 'btn',
                                                            cancelClass: "btn-danger",
                                                            applyButtonClasses: "btn-primary",
                                                            showDropdowns: true,
                                                            maxDate: `${moment().format('DD/MM/YYYY')}`,
                                                            ranges: ranges,
                                                            minYear: 2020,
                                                            locale: {
                                                                format: 'DD/MM/YYYY',
                                                                cancelLabel: t('Close'),
                                                                firstDay: 6,
                                                                applyLabel: t('Apply'),
                                                                customRangeLabel: t('range.customRange'),
                                                                monthNames: [
                                                                    `${t("months.January")}`,
                                                                    `${t("months.February")}`,
                                                                    `${t("months.March")}`,
                                                                    `${t("months.April")}`,
                                                                    `${t("months.May")}`,
                                                                    `${t("months.June")}`,
                                                                    `${t("months.July")}`,
                                                                    `${t("months.August")}`,
                                                                    `${t("months.September")}`,
                                                                    `${t("months.October")}`,
                                                                    `${t("months.November")}`,
                                                                    `${t("months.December")}`
                                                                ],
                                                                daysOfWeek: [
                                                                    t("daysOfWeek.Sunday"),
                                                                    t("daysOfWeek.Monday"),
                                                                    t("daysOfWeek.Tuesday"),
                                                                    t("daysOfWeek.Wednesday"),
                                                                    t("daysOfWeek.Thursday"),
                                                                    t("daysOfWeek.Friday"),
                                                                    t("daysOfWeek.Saturday")
                                                                ],
                                                            },
                                                        }}
                                                        onApply={this.handleApplyDate} >
                                                        <input
                                                            defaultValue={this.state.date}
                                                            name="Date"
                                                            autoComplete="off"
                                                            required
                                                            type="text"
                                                            placeholder={t('SearchDate.Placeholder')}
                                                            title={t('SearchDate.title')}
                                                        />
                                                    </DateRangePicker>
                                                </div>
                                            </div>
                                            <div className="form-group w-100 text-center">
                                                <button className="custom-button" onClick={() => this.onSubmitSearch()}>
                                                    <span>
                                                        <i className="icon fal fa-search"></i> {t('Pages.Missions.searchButton')}
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                        : ""
                }
                <Helmet>
                    <title>
                        {t('Pages.Competition.Stage')} | {t('alarabeyya')}
                    </title>
                    <link rel="stylesheet" type="text/css" href={process.env.PUBLIC_URL + '/assets/css/game.css?v=' + process.env.REACT_APP_VERSION} />
                </Helmet>
                <section className="position-relative game-font oh" id="main-game-stage-section" style={{
                    minHeight: '100vh',
                    backgroundColor: '#985b46'
                }}>
                    <div className="position-relative oh" style={{
                        minHeight: '100vh',
                        backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/stage/bg.png"})`,
                        backgroundPositionX: 'center',
                        backgroundPositionY: 'bottom',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        top: '0'
                    }}>



                        <div className="w-100 position-absolute-md" style={{
                            zIndex: 9
                        }}>

                            <div className="w-100 position-relative" style={{
                                zIndex: 9
                            }}>
                                <div style={{
                                    background: 'linear-gradient(180deg, rgba(25,86,176,1) 0%, rgba(41,123,255,1) 20%)'
                                }}>
                                    <div className="d-flex w-100 py-1 justify-content-evenly align-items-center flex-wrap flex-md-nowrap">
                                        <div className="d-flex w-100 py-1 justify-content-evenly align-items-center order-2 order-sm-1 ">
                                            <div title={t('Pages.Competition.ClassRoom.1')} className="display-4 position-relative d-flex align-items-end justify-content-center cursor-custom-pointer" >
                                                <div className="text-center font-weight-bold w-100 position-absolute h3 user-select-none" onClick={() => this.showResult(1)} style={{
                                                    color: "#0e2c53",
                                                    zIndex: 999
                                                }}>
                                                    1
                                                </div>
                                                <i className={`fa-solid fa-star ${this.state.index === 1 ? "home-competition-color" : "competition-color"}  text-warning`}></i>
                                            </div>
                                            <div title={t('Pages.Competition.ClassRoom.2')} className="display-4 position-relative d-flex align-items-end justify-content-center cursor-custom-pointer" >
                                                <div className="text-center font-weight-bold w-100 position-absolute h3 user-select-none" onClick={() => this.showResult(2)} style={{
                                                    color: "#0e2c53",
                                                    zIndex: 999
                                                }}>
                                                    2
                                                </div>
                                                <i className={`fa-solid fa-star ${this.state.index === 2 ? "home-competition-color" : "competition-color"}  text-warning`}></i>
                                            </div>
                                            <div title={t('Pages.Competition.ClassRoom.3')} className="display-4 position-relative d-flex align-items-end justify-content-center cursor-custom-pointer" >
                                                <div className="text-center font-weight-bold w-100 position-absolute h3 user-select-none" onClick={() => this.showResult(3)} style={{
                                                    color: "#0e2c53",
                                                    zIndex: 999
                                                }}>
                                                    3
                                                </div>
                                                <i className={`fa-solid fa-star ${this.state.index === 3 ? "home-competition-color" : "competition-color"}  text-warning`}></i>
                                            </div>

                                            <div title={t('Pages.Competition.ClassRoom.4')} className="display-4 position-relative d-flex align-items-end justify-content-center cursor-custom-pointer" >
                                                <div className="text-center font-weight-bold w-100 position-absolute h3 user-select-none" onClick={() => this.showResult(4)} style={{
                                                    color: "#0e2c53",
                                                    zIndex: 999
                                                }}>
                                                    4
                                                </div>
                                                <i className={`fa-solid fa-star ${this.state.index === 4 ? "home-competition-color" : "competition-color"}  text-warning`}></i>
                                            </div>
                                            <div title={t('Pages.Competition.ClassRoom.5')} className="display-4 position-relative d-flex align-items-end justify-content-center cursor-custom-pointer" >
                                                <div className="text-center font-weight-bold w-100 position-absolute h3 user-select-none" onClick={() => this.showResult(5)} style={{
                                                    color: "#0e2c53",
                                                    zIndex: 999
                                                }}>
                                                    5
                                                </div>
                                                <i className={`fa-solid fa-star ${this.state.index === 5 ? "home-competition-color" : "competition-color"}  text-warning`}></i>
                                            </div>
                                        </div>
                                        <div className="display-3 position-relative order-1 order-sm-2 cursor-custom-pointer d-flex align-items-center flex-column" onClick={() => this.showResult(0)} >
                                            <i className={`fa-solid fa-crown ${this.state.index === 0 ? "home-competition-color" : "competition-color"}  text-warning`}></i>
                                            <div className="h4 mb-0 text-center text-white" style={{
                                                marginTop: '-5px'
                                            }}>
                                                {t('Pages.Competition.School')}
                                            </div>
                                        </div>
                                        <div className="d-flex w-100 py-1 justify-content-evenly align-items-center order-3 ">

                                            <div title={t('Pages.Competition.ClassRoom.6')} className="display-4 position-relative d-flex align-items-end justify-content-center cursor-custom-pointer" >
                                                <div className="text-center font-weight-bold w-100 position-absolute h3 user-select-none" onClick={() => this.showResult(6)} style={{
                                                    color: "#0e2c53",
                                                    zIndex: 999
                                                }}>
                                                    6
                                                </div>
                                                <i className={`fa-solid fa-star ${this.state.index === 6 ? "home-competition-color" : "competition-color"}  text-warning`}></i>
                                            </div>

                                            <div title={t('Pages.Competition.ClassRoom.7')} className="display-4 position-relative d-flex align-items-end justify-content-center cursor-custom-pointer" >
                                                <div className="text-center font-weight-bold w-100 position-absolute h3 user-select-none" onClick={() => this.showResult(7)} style={{
                                                    color: "#0e2c53",
                                                    zIndex: 999
                                                }}>
                                                    7
                                                </div>
                                                <i className={`fa-solid fa-star ${this.state.index === 7 ? "home-competition-color" : "competition-color"}  text-warning`}></i>
                                            </div>
                                            <div title={t('Pages.Competition.ClassRoom.8')} className="display-4 position-relative d-flex align-items-end justify-content-center cursor-custom-pointer" >
                                                <div className="text-center font-weight-bold w-100 position-absolute h3 user-select-none" onClick={() => this.showResult(8)} style={{
                                                    color: "#0e2c53",
                                                    zIndex: 999
                                                }}>
                                                    8
                                                </div>
                                                <i className={`fa-solid fa-star ${this.state.index === 8 ? "home-competition-color" : "competition-color"}  text-warning`}></i>
                                            </div>
                                            <div title={t('Pages.Competition.ClassRoom.9')} className="display-4 position-relative d-flex align-items-end justify-content-center cursor-custom-pointer" >
                                                <div className="text-center font-weight-bold w-100 position-absolute h3 user-select-none" onClick={() => this.showResult(9)} style={{
                                                    color: "#0e2c53",
                                                    zIndex: 999
                                                }}>
                                                    9
                                                </div>
                                                <i className={`fa-solid fa-star ${this.state.index === 9 ? "home-competition-color" : "competition-color"}  text-warning`}></i>
                                            </div>

                                            <div title={t('Pages.Competition.setting')} onClick={() => this.showSetting()} className="display-4 position-relative d-flex align-items-end justify-content-center cursor-custom-pointer" >
                                                <i className={`fa-solid fa-gears text-warning competition-color`}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-100 d-none d-md-block" style={{
                                    height: '100px',
                                    backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/stage/1.png"})`,
                                    backgroundSize: 'contain',
                                    backgroundPositionX: 'center',
                                    backgroundPositionY: 'bottom',
                                    backgroundRepeat: 'repeat'
                                }}></div>
                            </div>
                            <div className="w-100 position-absolute" style={{
                                zIndex: 1,
                                top: 0
                            }}>
                                <div className={`curtain__panel ${this.state.show ? "curtain__panel--left" : ""}`}></div>
                                <div className={`curtain__panel curtain__panel__left ${this.state.show ? "curtain__panel--right" : ""}`}></div>
                                <div className="h-100 position-absolute d-none d-lg-block" style={{
                                    backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/stage/right.png"})`,
                                    backgroundSize: 'cover',
                                    backgroundPositionX: 'left',
                                    backgroundPositionY: 'top',
                                    backgroundRepeat: 'no-repeat',
                                    width: '300px',
                                    right: 0,
                                    top: 0,
                                    zIndex: 2
                                }}></div>
                                <div className="h-100 position-absolute d-none d-lg-block" style={{
                                    backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/stage/left.png"})`,
                                    backgroundSize: 'cover',
                                    backgroundPositionX: 'right',
                                    backgroundPositionY: 'top',
                                    backgroundRepeat: 'no-repeat',
                                    width: '300px',
                                    left: 0,
                                    top: 0,
                                    zIndex: 2
                                }}></div>
                            </div>

                            {
                                this.state.data?.length > 0 && this.state.show ? "" :
                                    <div className="w-100 position-absolute d-flex justify-content-center align-items-center" style={{
                                        height: '50vh'
                                    }}>
                                        <div className="product-content rounded mt-5 mb-3 w-50 text-center bg-white p-5 border border-warning">
                                            <div className="display-1 text-warning mb-2">
                                                <i className="fad fa-crown"></i>
                                            </div>
                                            <h3 className="subtitle mb-3">
                                                {t("NoInfo")}
                                            </h3>
                                            <h4 className="subtitle">
                                                {this.state.date}
                                            </h4>
                                        </div>
                                    </div>
                            }


                        </div>

                        {
                            this.state.data?.length > 0 ?
                                <div className="container d-flex justify-content-end flex-column align-items-center h-100 position-relative" style={{
                                    minHeight: '100vh'
                                }} >

                                    <div className="d-flex align-items-stretch align-content-center flex-wrap flex-column mb-5 w-100">
                                        <div className="h1 title text-center display-4 mt-3" style={{
                                            color: '#0e2c53'
                                        }}>
                                            {t('Pages.Competition.Stage')}
                                        </div>
                                        <div className="h2 title text-center " style={{
                                            color: '#0e2c53'
                                        }}>
                                            {this.state.index === 0 ? t('Pages.Competition.School') : t('Pages.Competition.ClassRoom.' + this.state.index)}

                                        </div>
                                        <div className="h3 title text-center " style={{
                                            color: '#0e2c53'
                                        }}>
                                            {this.state.date}
                                        </div>
                                        <div className="d-flex w-100 justify-content-evenly align-items-center flex-wrap flex-md-nowrap">
                                            <div className="w-100 d-flex justify-content-center justify-content-md-end order-sm-1 order-3">
                                                {this.renderWinner(3, this.state.data[2])}
                                            </div>
                                            <div className="w-75 mb-md-3 order-sm-2 order-1 mx-2">
                                                {this.renderWinner(1, this.state.data[0])}
                                            </div>
                                            <div className="w-100 d-flex justify-content-center justify-content-md-start order-sm-3 order-2">
                                                {this.renderWinner(2, this.state.data[1])}
                                            </div>
                                        </div>
                                        <div className="d-none d-md-block" style={{
                                            height: '200px',
                                            backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/stage/footer.png"})`,
                                            backgroundPositionX: 'center',
                                            backgroundPositionY: 'bottom',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'contain'
                                        }}></div>
                                    </div>
                                </div>
                                : ""
                        }


                    </div>
                    {
                        this.state.show && this.state.data && this.state.data.length > 3 ?
                            <div className="container mb-3 ">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8 col-md-12">
                                        <table className="w-100" style={{
                                            borderSpacing: '0.5rem',
                                            borderCollapse: "separate"
                                        }}>
                                            <tbody>
                                                {this.renderTableRows()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> : ""
                    }
                </section>
                <div className="position-sticky">
                    <FooterBottom />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps, {})(withTranslation('common')(SchoolCompetition));
