import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';

import configData from "../../Config/countries.json";
import EditProfileForm from '../../Home/Forms/EditProfileForm';
import ChangePasswordForm from '../../Home/Forms/ChangePasswordForm';
import api from '../../../api/api';

class TeacherItem extends React.Component {

    state = {
        moreInfo: false,
        showEditProfile: false,
        currentUser: null,
        showChangePassword: false
    };

    constructor(props) {
        super(props);
        this.state.countries = configData.countries.sort((a, b) => a.label > b.label ? 1 : -1).map(item => {
            return {
                value: item.value,
                name: item.label,
                label: <span><LazyLoadImage src={process.env.PUBLIC_URL + item.icon} />  {item.label}</span>,
            };
        });
    }

    changePassword = user => {
        this.setState({
            showChangePassword: true,
            currentUser: user
        });
    }

    moreInfo = () => {
        this.setState({
            moreInfo: !this.state.moreInfo
        });
    }

    edit = user => {
        this.setState({
            showEditProfile: true,
            currentUser: user
        });
    }


    showAddNewBill = () => {
        this.setState({
            showAddNewBill: true
        });
    }

    handleCloseEditProfile = () => {
        this.setState({
            showEditProfile: false,
            showBills: false,
            showChangePassword: false,
            currentUser: null
        });
    }


    onSubmit = (postData, _toastId) => {
        const { t } = this.props;

        api.put(`/Teachers/${this.state.currentUser.Id}`,
            postData
        ).then(response => {
            if (response.data.code === "success") {

                this.props.updateTeacher(this.state.currentUser.Id, postData);

                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.ProfileEdit.success_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.success_toast.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.handleCloseEditProfile();

            } else {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    onSubmitChangePassword = (postData, _toastId) => {
        const { t } = this.props;

        api.put(`/Teachers/${this.state.currentUser.Id}?ChangePassword=true`,
            postData
        ).then(response => {
            if (response.data.code === "success") {

                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.ProfileEdit.success_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.success_toast.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                this.handleCloseEditProfile();

            } else {
                toast.update(_toastId, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Title')}</div> <div className="h6">{t('Pages.ProfileEdit.error_AllInformationIsRequired_toast.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    render() {
        const { t, item } = this.props;
        if (item) {

            let Country = "";
            if (item.Country) {
                Country = this.state.countries.filter(p => p.value === item.Country)[0];
            }

            return (
                <>
                    {
                        this.state.showChangePassword ?
                            <Modal
                                size="md"
                                centered
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false}
                                show={this.state.showChangePassword}
                                onHide={this.handleCloseEditProfile}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="h5 mb-0">
                                            <i className="fad fa-key text-primary icon"></i> {t("Pages.TeachersControl.action.change_password")}
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="widget widget-category">
                                        <div className="widget-body">
                                            <ChangePasswordForm onSubmit={this.onSubmitChangePassword}></ChangePasswordForm>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            : ""
                    }
                    {
                        this.state.showEditProfile ?
                            <Modal
                                size="lg"
                                centered
                                backdrop="static"
                                keyboard={false}
                                enforceFocus={false}
                                show={this.state.showEditProfile}
                                onHide={this.handleCloseEditProfile}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="h5 mb-0">
                                            <i className="fad fa-edit text-primary icon"></i> {t("Pages.TeachersControl.action.edit")}
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="widget widget-category">
                                        <div className="widget-body">
                                            <EditProfileForm isTeacher={true} isAdmin={false} user={item} onSubmit={this.onSubmit}></EditProfileForm>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            : ""
                    }
                    <div className="col px-0 mx-0"  >
                        <div className="product-list-item" >
                            {
                                item.IsActive ? "" :
                                    <div className="ribbon-corner ribbon-fold" data-position="left top" title={t('Pages.TeachersControl.NotActive')}>
                                        <i className="fas fa-lock"></i>
                                    </div>
                            }
                            {
                                item.IsLocked ?
                                    <div className="ribbon-corner ribbon-fold" data-position="right top" title={t("Pages.TeachersControl.IsLocked")}>
                                        <i className="fas fa-user-lock"></i>
                                    </div> : ""

                            }
                            <div className="product-thumb">
                                {
                                    item.Photo ?
                                        <LazyLoadImage src={item.Photo} alt={`${item.Name}`} />
                                        :
                                        <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.Name}`} />
                                }
                                <div className="product-action-link">
                                    <div className="my-1" onClick={() => this.edit(item)} title={t("Pages.TeachersControl.action.edit")}><i className="far fa-edit"></i></div>
                                    <div className="my-1" onClick={() => this.changePassword(item)} title={t("Pages.TeachersControl.action.change_password")}><i className="far fa-key"></i></div>
                                    <div className="my-1" onClick={() => this.moreInfo()} title={t("Pages.TeachersControl.action.info")}><i className="far fa-info"></i></div>
                                    <div className="my-1" onClick={() => this.props.remove(item.Id)} title={t("Pages.TeachersControl.action.remove")}><i className="far fa-trash"></i></div>
                                </div>
                            </div>
                            <div className="product-content py-3">
                                <h5 className="title"><Link to="#" onClick={() => this.edit(item)}> {item.Name} </Link></h5>
                                <p>
                                    {
                                        item.Birthday ?
                                            <span title={t('Pages.Register.Birthday.title')}>
                                                <i className="icon fal fa-calendar-alt"></i>
                                                {item.Birthday}
                                            </span>
                                            : ""
                                    }
                                    {
                                        item.Sex ?
                                            <span className="mx-2" title={t('Pages.Register.Sex.title')} >
                                                <i className={`icon text-info fad fa-${item.Sex}`}></i>
                                                {t('Pages.Register.Sex.' + item.Sex)}
                                            </span>
                                            : ""
                                    }
                                </p>
                                <p>
                                    {
                                        item.Username ?
                                            <span title={t('Pages.Register.Username.title')} >
                                                <i className="icon fal fa-hashtag"></i>
                                                {item.Username}
                                            </span>
                                            : ""
                                    }
                                    {
                                        item.Email ?
                                            <>
                                                {
                                                    item.Username ? " - " : ""
                                                }
                                                <a href={`mailto:${item.Email}`} title={t('Pages.Register.Email.title')}>
                                                    <i className="icon fal fa-envelope-open-text"></i>
                                                    {item.Email}
                                                </a>
                                            </>
                                            : ""
                                    }
                                    {
                                        item.Phone ?
                                            <>
                                                {
                                                    item.Email ? " - " : ""
                                                }
                                                <a href={`Tel:${item.Phone}`} title={t('Pages.Register.Phone.title')}>
                                                    <i className="icon fal fa-phone-alt"></i>
                                                    <span dir="ltr">
                                                        {item.Phone}
                                                    </span>
                                                </a>
                                            </> : ""
                                    }
                                </p>

                                <div className="d-flex justify-content-between ">
                                    <p>
                                        {
                                            Country && Country.value !== 'il' ?
                                                <span title={t('Pages.Register.Country.title')} >
                                                    {Country.label}
                                                </span>
                                                : ""
                                        }
                                        {
                                            item.Address ?
                                                <span title={t('Pages.Register.Address.title')} >
                                                    {
                                                        Country && Country.value !== 'il' ? " - " : ""
                                                    }
                                                    {item.Address}
                                                </span>
                                                : ""
                                        }
                                        <br />
                                        <span className="mt-2">
                                            {
                                                item.School ?
                                                    <span title={t('Pages.Register.SchoolName.title')}>
                                                        <i className="icon fal fa-school"></i>
                                                        {item.School.Name + " - "}
                                                        <span className="text-danger" >
                                                            {
                                                                item.UserType ?
                                                                    <>
                                                                        {item.UserType.Name}
                                                                    </>
                                                                    : ""
                                                            }
                                                        </span>
                                                    </span>
                                                    : ""
                                            }
                                        </span>

                                    </p>
                                    {
                                        item.IsTeacher ?
                                            <div className="d-flex align-items-center mr-1" >
                                                <span className="cate btn btn-light " title={t("Pages.TeachersControl.IsTeacher")}>
                                                    <i className="fad fa-chalkboard-teacher text-primary"></i>
                                                </span>
                                            </div> : ""
                                    }
                                </div>

                            </div>
                            {
                                this.state.moreInfo ?
                                    <div className=" w-100 pt-2 mt-1 mb-2 px-3 d-flex flex-wrap justify-content-around" style={{
                                        borderTop: '1px dashed #92278f'
                                    }}>

                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Profile.LastLogin.title")}  :
                                                <div className="text-secondary">
                                                    {item.LastLogin}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Profile.ActivateUntilDate.title")}  :
                                                <div className="text-secondary">
                                                    {
                                                        item.ActivateUntilDate !== "0" ?
                                                            <>
                                                                <i className="text-success icon fad fa-circle"></i> {item.ActivateUntilDate}
                                                            </> :
                                                            <>
                                                                <i className="text-danger icon fad fa-circle"></i> {t('Pages.TeachersControl.NotActive')}
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.CreationDate")}  :
                                                <div className="text-secondary">
                                                    {item.CreationDate}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.UpdateDate")}  :
                                                <div className="text-secondary">
                                                    {item.UpdateDate}
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ""
                            }
                        </div>
                    </div>
                </>
            );
        }
        return "";
    }
}


export default withTranslation('common')(TeacherItem);