import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import Select from 'react-select';

import UserSubMenu from '../../Home/User/UserSubMenu';
import Footer from "../../Footer/FooterBottom";
import { fetchStudentRanks as fetch } from './actions/';
import classType from "../../Config/classType.json";
import { fetchClassRooms } from '../ClassRooms/actions';

class StudentRanksList extends React.Component {

    state = {
        ClassRoomId: [],
        ClassType: [],
        Division: [],
        date: moment().add(-15, 'd').format('DD/MM/YYYY') + " - " + moment().format('DD/MM/YYYY'),
        startDate: moment().add(-15, 'd').format('DD/MM/YYYY'),
        endDate: moment().format('DD/MM/YYYY'),
    };

    constructor(props) {
        super(props);
        this.state.ClassType = classType.ClassType.sort((a, b) => a.Id > b.Id ? 1 : -1);
        this.state.Division = classType.Division.sort((a, b) => a.Id > b.Id ? 1 : -1);
        this.props.fetchClassRooms(true);
    }

    componentDidMount() {
        this.Sync();
        window.scrollToTop();
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY'));
        this.setState({
            startDate: picker.startDate.format('DD/MM/YYYY'),
            endDate: picker.endDate.format('DD/MM/YYYY'),
            date: picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY')
        });
    }

    renderStudents() {
        const { t, studentRanks } = this.props;

        if (studentRanks &&
            studentRanks.studentRanks &&
            studentRanks.studentRanks.length > 0) {

            const ListOfColor = ["warning", "primary ", "orange"];
            const ListOfBackgroundColor = ["#fffee8", "#eaf2ff", "#fff5fd"];

            return studentRanks.studentRanks.map((item, index) => {

                let ClassType = "";
                if (item.User.ClassRoom.ClassTypeId) {
                    ClassType = this.state.ClassType.filter(p => p.Id === item.User.ClassRoom.ClassTypeId)[0];
                }

                let Division = "";
                if (item.User.ClassRoom.DivisionId) {
                    Division = this.state.Division.filter(p => p.Id === item.User.ClassRoom.DivisionId)[0];
                }

                return <div className="col px-0 mx-0 w-100" key={`StudentsHavePoints-${item.User.Id}`}>
                    <div className="product-list-item" style={{
                        minHeight: '160px',
                        backgroundColor: index < 3 ? ListOfBackgroundColor[index] : ""
                    }}>
                        <div className="product-thumb" style={{
                            backgroundColor: ListOfBackgroundColor[index]
                        }}>
                            <LazyLoadImage src={item.User.Photo} alt={item.User.FirstName} />
                        </div>
                        <div className="product-content py-1 ">
                            <div className="d-flex justify-content-between px-2">
                                <div className="d-flex flex-column justify-content-center">
                                    <h5 className="title ">
                                        {item.User.FirstName ? item.User.FirstName + " " : ""}
                                        {item.User.SecondName ? item.User.SecondName + " " : ""}
                                        {item.User.LastName ? item.User.LastName + " " : ""}
                                    </h5>
                                    <p className="my-0">
                                        {
                                            item.User.Birthday ?
                                                <span title={t('Pages.Register.Birthday.title')}>
                                                    <i className="icon fal fa-calendar-alt"></i>
                                                    {item.User.Birthday.substring(0, 10)}
                                                </span>
                                                : ""
                                        }
                                        {
                                            item.User.Sex ?
                                                <span className="mx-2" title={t('Pages.Register.Sex.title')} >
                                                    <i className={`icon text-info fad fa-${item.User.Sex}`}></i>
                                                    {t('Pages.Register.Sex.' + item.User.Sex)}
                                                </span>
                                                : ""
                                        }
                                    </p>
                                    <p className="my-0">
                                        {
                                            item.User.Username ?
                                                <span title={t('Pages.Register.Username.title')} >
                                                    <i className="icon fal fa-hashtag"></i>
                                                    {item.User.Username}
                                                </span>
                                                : ""
                                        }
                                        {
                                            item.User.Phone ?
                                                <>
                                                    -
                                                    <a href={`Tel:${item.User.Phone}`} title={t('Pages.Register.Phone.title')}>
                                                        <i className="icon fal fa-phone-alt"></i>
                                                        <span dir="ltr">
                                                            {item.User.Phone}
                                                        </span>
                                                    </a>
                                                </> : ""
                                        }
                                        {
                                            ClassType && Division ?
                                                <>
                                                    {
                                                        item.User.Phone ? " - " : ""
                                                    }
                                                    <span className="mx-2 text-info" title={t('Pages.StudentInClassRoomsControl.title')}>
                                                        <i className="icon fal fa-users-class"></i>
                                                        {ClassType.Name + " - " + Division.Name}
                                                    </span>
                                                </> : ""
                                        }
                                    </p>
                                    <p className="my-0">
                                        <span className={`h4 text-${index < 3 ? ListOfColor[index] : ""}`} title={t('Pages.Home.Point')}>
                                            <i className={`icon h5 fad fa-circle text-${index < 3 ? ListOfColor[index] : ""}`}></i>
                                            {Math.round(item.Point * 100)}
                                        </span>
                                    </p>
                                </div>
                                {
                                    index <= 5 ?
                                        <div className="d-flex align-items-center mr-1 flex-column justify-content-center" >
                                            <LazyLoadImage src={`/assets/images/archive/sa${4 + index}.png`} alt={t("Pages.Home.Position")} title={`${t("Pages.Home.Point")} : ${Math.round(item.Point * 100)} `} />
                                        </div>
                                        :
                                        <div className="d-flex text-dark h2 align-items-center mr-1 justify-content-center" >
                                            <i className="icon text-danger fad fa-hashtag"></i> {index + 1}
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

            });

        }

        if (studentRanks && studentRanks.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.SchoolsControl.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.SchoolsControl.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    Sync = () => {
        const ClassRoomId = this.state.ClassRoomId.map(c => c.value);
        this.props.fetch(this.state.startDate, this.state.endDate, ClassRoomId);
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.label.includes(input);
        }
        return true;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.classRooms && props.classRooms.classRooms) {
            state.classRooms = props.classRooms.classRooms.map(classRoom => {
                return {
                    value: classRoom.Id,
                    label: classRoom.Name
                };
            });
        }
        return state;
    }

    setSelectedOption = ClassRoom => {
        if (ClassRoom) {
            this.setState({
                ClassRoomId: ClassRoom
            });
        } else {
            this.setState({
                ClassRoomId: []
            });
        }
    }

    render() {
        const { t, user, studentRanks } = this.props;

        let isLoaded = false;
        if (studentRanks) {
            isLoaded = studentRanks.isLoaded;
        }


        let ranges = {};
        ranges[t("range.Today")] = [moment().toDate(), moment().toDate()];
        ranges[t("range.Yesterday")] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        ranges[t("range.ThisMonth")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[t("range.LastMonth")] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
        ranges[t("range.ThisYear")] = [moment().startOf('year'), moment().endOf('year')];

        if (moment().startOf('year').month("September") < moment()) {
            ranges[t("range.FirstSemester")] = [moment().startOf('year').month("September"), moment().endOf('year')];
            ranges[t("range.ThisStudyYear")] = [moment().startOf('year').month("September").startOf('month'), moment().toDate()];
        } else {
            ranges[t("range.FirstSemester")] = [moment().subtract(1, 'year').month("September"), moment().subtract(1, 'year').endOf('year')];
            if (moment().startOf('year') <= moment()) {
                ranges[t("range.SecondSemester")] = [moment().startOf('year'), moment().startOf('year').month("April").day(10)];
            }
            if (moment().startOf('year').month("April").day(15) <= moment()) {
                ranges[t("range.ThirdSemester")] = [moment().startOf('year').month("April").day(15), moment().startOf('year').month("June").endOf('month')];
            }
            ranges[t("range.ThisStudyYear")] = [moment().subtract(1, 'year').month("September").startOf('month'), moment().toDate()];
        }

        return (
            <>
                <section className="pos-rel padding-bottom padding-top oh">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <Helmet>
                            <title>
                                {t('Pages.UserControl.StudentRank')}| {t('alarabeyya')}
                            </title>
                        </Helmet>

                        <div className="row ">
                            <div className="col-lg-8 mb-50">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="section-header">
                                            <h3 className="title"> {t('Pages.UserControl.StudentRank')} </h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="search-form contact-form align-items-center">

                                            <div className="form-group" style={{
                                                zIndex: 9999999
                                            }}>
                                                <Select
                                                    name="ClassRoomId"
                                                    title={t('Pages.Missions.ClassRoom.title')}
                                                    placeholder={t('Pages.Missions.ClassRoom.placeholder')}
                                                    onChange={e => this.setSelectedOption(e)}
                                                    isRtl={true}
                                                    isMulti={true}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    ref={ClassRoomId => this.ClassRoomId = ClassRoomId}
                                                    options={this.state.classRooms}
                                                    filterOption={this.filterOptions}
                                                    theme={theme => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#92278f',
                                                            primary: '#92278f',
                                                        },
                                                    })}
                                                />
                                            </div>
                                            <div className="form-group " style={{
                                                zIndex: 9999999
                                            }}>
                                                <DateRangePicker
                                                    ref={Date => this.Date = Date}
                                                    initialSettings={{
                                                        opens: 'center',
                                                        autoUpdateInput: false,
                                                        buttonClasses: 'btn',
                                                        cancelClass: "btn-danger",
                                                        applyButtonClasses: "btn-primary",
                                                        showDropdowns: true,
                                                        maxDate: `${moment().format('DD/MM/YYYY')}`,
                                                        ranges: ranges,
                                                        minYear: 2020,
                                                        locale: {
                                                            format: 'DD/MM/YYYY',
                                                            cancelLabel: t('Close'),
                                                            firstDay: 6,
                                                            applyLabel: t('Apply'),
                                                            customRangeLabel: t('range.customRange'),
                                                            monthNames: [
                                                                `${t("months.January")}`,
                                                                `${t("months.February")}`,
                                                                `${t("months.March")}`,
                                                                `${t("months.April")}`,
                                                                `${t("months.May")}`,
                                                                `${t("months.June")}`,
                                                                `${t("months.July")}`,
                                                                `${t("months.August")}`,
                                                                `${t("months.September")}`,
                                                                `${t("months.October")}`,
                                                                `${t("months.November")}`,
                                                                `${t("months.December")}`
                                                            ],
                                                            daysOfWeek: [
                                                                t("daysOfWeek.Sunday"),
                                                                t("daysOfWeek.Monday"),
                                                                t("daysOfWeek.Tuesday"),
                                                                t("daysOfWeek.Wednesday"),
                                                                t("daysOfWeek.Thursday"),
                                                                t("daysOfWeek.Friday"),
                                                                t("daysOfWeek.Saturday")
                                                            ],
                                                        },
                                                    }}
                                                    onApply={this.handleApplyDate} >
                                                    <input
                                                        defaultValue={this.state.date}
                                                        name="Date"
                                                        autoComplete="off"
                                                        required
                                                        type="text"
                                                        placeholder={t('SearchDate.Placeholder')}
                                                        title={t('SearchDate.title')}
                                                    />
                                                </DateRangePicker>
                                                <style dangerouslySetInnerHTML={{
                                                    __html: ".ranges ul {width:auto !important;}"
                                                }}></style>
                                            </div>

                                        </div>

                                        <div className="d-flex justify-content-center mt-3">
                                            <div className="custom-button primary-button" onClick={() => this.Sync()} title={t("Pages.SchoolsControl.Sync")} >
                                                <span>
                                                    {
                                                        isLoaded ?
                                                            <i className="icon fal fa-search"></i>
                                                            :
                                                            <i className="icon fas fa-sync-alt fa-spin"></i>
                                                    }
                                                    {t('Pages.Missions.searchButton')}
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                {this.renderStudents()}
                            </div>
                            <div className="col-lg-4 mb-50">
                                <aside>
                                    <UserSubMenu user={user}></UserSubMenu>
                                </aside>
                            </div>
                        </div>

                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        classRooms: state.classRooms,
        studentRanks: state.studentRanks,
        user: state.user
    };
};

export default connect(mapStateToProps, { fetchClassRooms, fetch })(withTranslation('common')(StudentRanksList));