import React from "react";
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast } from 'react-toastify';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Footer from "../../../Footer/FooterBottom";
import api from '../../../../api/api';
import configData from "../../../Config/UserType.json";
import ClassTypeData from "../../../Config/classType.json";
import { fetchSchools } from '../../Users/actions';
import { getIndeices } from "../../../../context/sessionStorage";
import { fetchIndices } from '../../../Admin/Indices/actions/';
import { fetchClassRooms } from '../../../School/ClassRooms/actions';
import {
    fetchStudentInMultiClassRooms,
} from '../../../School/StudentInClassRooms/actions/';
import {
    fetchTeachers,
} from '../../../School/Teachers/actions/';

class LessonsStatistics extends React.Component {

    state = {
        date: `${moment().format('DD/MM/YYYY')} - ${moment().format('DD/MM/YYYY')}`,
        startDate: `${moment().format('DD/MM/YYYY')}`,
        endDate: `${moment().format('DD/MM/YYYY')}`,
        schools: [],
        ClassType: [],
        indices: [],
        data: [],
        UserTypeId: 0,
        Type: "Lessons",
        ClassTypeId: "",
        IsPublic: "",
        isFirst: true,
        ClassRoomId: [],
        StudentId: [],
    };

    constructor(props) {
        super(props);
        this.state.ClassType = ClassTypeData.ClassType;
        this.state.UserType = configData.UserType;
        if (this.props.user.user && this.props.user.user.UserType.Id === 1) {
            this.props.fetchSchools();
        } else {
            this.props.fetchClassRooms(true);
            this.props.fetchTeachers();
        }
    }

    componentDidMount() {
        let indices = getIndeices();
        if (indices === null || indices.length === 0) {
            this.props.fetchIndices();
        }
        this.setState({
            indices: indices
        });
    }

    setSelectedOption = ClassRoom => {
        if (ClassRoom) {
            this.setState({
                ClassRoomId: ClassRoom
            });
        } else {
            this.setState({
                ClassRoomId: []
            });
        }
        let ClassRoomId = [];
        if (ClassRoom) {
            ClassRoomId = ClassRoom.map(c => c.value);
        }
        this.props.fetchStudentInMultiClassRooms(ClassRoomId);
    }

    setSelectedOptionStudent = Student => {
        if (Student) {
            this.setState({
                StudentId: Student
            });
        } else {
            this.setState({
                StudentId: []
            });
        }
    }

    static getDerivedStateFromProps(props, state) {

        if (props.schools && props.schools.schools) {
            state.schools = props.schools.schools.map(item => {
                return {
                    value: item.Id,
                    name: item.Name,
                    label: <div> {item.Name}</div>,
                };
            });
        }

        if (props.classRooms && props.classRooms.classRooms) {
            state.classRooms = props.classRooms.classRooms.map(classRoom => {
                return {
                    value: classRoom.Id,
                    label: classRoom.Name
                };
            });
        }

        if (props.teachers && props.teachers.teachers) {
            state.teachers = props.teachers.teachers.map(item => {
                return {
                    value: item.Id,
                    name: item.Name,
                    label: <div> {item.Name}</div>,
                };
            });
        }

        if (props.studentInClassRooms && props.studentInClassRooms.studentInClassRooms) {
            state.students = props.studentInClassRooms.studentInClassRooms.map(item => {
                return {
                    value: item.Id,
                    name: item.Name,
                    label: <div> {item.Name}</div>,
                };
            });
        }
        return state;
    }

    renderUserTypeOption() {
        return this.state.UserType.map(item => {
            return <option key={item.Id} value={item.Id}>{item.Name}</option>
        });
    }

    setUserType = UserTypeId => {
        this.setState({
            UserTypeId,
            data: []
        });
    }

    setType = Type => {
        this.setState({
            Type,
            data: []
        });
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.includes(input);
        }
        return true;
    }

    handleApplyDate = (event, picker) => {
        picker.element.val(picker.startDate.format('DD/MM/YYYY') + " - " + picker.endDate.format('DD/MM/YYYY'));
        this.setState({
            startDate: picker.startDate.format('DD/MM/YYYY'),
            endDate: picker.endDate.format('DD/MM/YYYY'),
            data: []
        });
    }

    submit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });
        const SchoolId = this.SchoolId && this.SchoolId.state && this.SchoolId.state.value ? this.SchoolId.state.value.value : 0;

        let TeacherId = [];
        if (this.TeacherId && this.TeacherId.state && this.TeacherId.state.value) {
            TeacherId = this.TeacherId.state.value.map(c => c.value);
        }
        let StudentId = [];
        if (this.StudentId && this.StudentId.state && this.StudentId.state.value) {
            StudentId = this.StudentId.state.value.map(c => c.value);
        }
        const ClassRoomId = this.state.ClassRoomId.map(c => c.value);

        const postData = {
            SchoolId,
            ClassTypeId: this.state.ClassTypeId,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            UserTypeId: this.state.UserTypeId,
            Type: this.state.Type,
            IsPublic: this.state.IsPublic,
            ClassRoomId,
            TeacherId,
            StudentId
        };

        let url = `/LessonView/`;
        if (this.state.Type === "Files") {
            url = `/EducationalFileView/`;
        }

        api.post(url, postData).then(response => {
            this.setState({ data: response.data.data });
            toast.dismiss(_toastId);
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    openViewWindow = Id => {
        if (this.state.Type === "Exams" || this.state.Type === "Quizzes") {
            window.openExamView(Id);
        } else if (this.state.Type === "Games" || this.state.Type === "Fun") {
            window.openGameView(Id);
        } else if (this.state.Type === "SelfDevelopment") {
            window.openSelfDevelopmentView(Id);
        } else {
            window.openLessonView(Id);
        }
    }

    openFilePDFView = Id => {
        window.openFilePDFViewWindow(Id);
    }

    renderPath(currentId) {
        this.pathList = [];
        const nodes = this.state.indices.nodes ? this.state.indices.nodes : this.state.indices;
        if (nodes) {
            let result = null;
            let i = 0;
            for (i = 0; result === null && i < nodes.length; i++) {
                result = this.searchTree(nodes[i], currentId);
            };
            if (result) {
                return {
                    ...result,
                    index: i
                };
            }
        }
        return "";
    }

    searchTree(node, currentId) {
        if (node.id === currentId) {
            return node;
        } else if (node.nodes) {
            var result = null;
            for (let i = 0; result === null && i < node.nodes.length; i++) {
                result = this.searchTree(node.nodes[i], currentId);
            }
            return result;
        }
        return null;
    }

    renderCols = () => {
        if (this.state.data) {
            const { t } = this.props;
            return this.state.data.map(item => {
                let path = this.renderPath(item.Info.IndexId);
                return <tr key={item.Info.Id}>
                    <td>
                        <div className="d-flex justify-content-between w-100">
                            {item.Info.Name}
                            {
                                item.Info.IsPublic ?
                                    <span className="m-1 h6 rounded-pill p-1 bg-default text-white blink" style={{
                                        fontSize: '12px'
                                    }}>{t("Pages.Search.IsPublic.True")}</span>
                                    :
                                    <></>
                            }
                        </div>
                    </td>
                    <td>{path && path.label ? path.label : ""}</td>
                    <td>{item.Total}</td>
                    <td>
                        <div className="d-flex justify-content-center w-100">
                            {
                                this.state.Type === "Files" ?
                                    <>
                                        <div className="btn btn-primary btn-sm mx-1" title={t('Pages.Search.Table.View')} onClick={() => this.openFilePDFView(item.Info.Id)}>
                                            <i className="fa-light fa-eye"></i>
                                        </div>
                                        <Link to={`/Files/${path ? path.index : 1}/${item.Info.IndexId}`} target="_blank" className="btn btn-dark btn-sm mx-1" title={t('Pages.Search.Table.View')} >
                                            <i className="fa-light fa-arrow-up-right-from-square"></i>
                                        </Link>
                                    </>
                                    :
                                    <div className="btn btn-primary btn-sm mx-1" title={t('Pages.Search.Table.View')} onClick={() => this.openViewWindow(item.Info.Id)}>
                                        <i className="fa-light fa-eye"></i>
                                    </div>
                            }
                        </div>
                    </td>
                </tr>
            });
        }
        return "";
    }

    renderClassTypeOption() {
        return this.state.ClassType.map(item => {
            return <option key={item.Id} value={item.Id}>{item.Name}</option>
        });
    }

    setSchoolId = () => {
        this.setState({
            data: []
        });
    }

    setClassTypeId = ClassTypeId => {
        this.setState({
            ClassTypeId,
            data: []
        });
    }

    setIsPublic = IsPublic => {
        this.setState({
            IsPublic,
            data: []
        });
    }

    checkStudent = () => {
        let ClassRoomId = [];
        if (this.ClassRoomId && this.ClassRoomId.state && this.ClassRoomId.state.value) {
            ClassRoomId = this.ClassRoomId.state.value.map(c => c.value);
        }
        if (ClassRoomId.length === 0) {
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bell-exclamation"></i> {t('Pages.Statistics.no_classroom.Title')}</div> <div className="h6">{t('Pages.Statistics.no_classroom.Body')}</div></div>,
                type: toast.TYPE.INFO,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    render() {
        const { t, schools, user, studentInClassRooms, teachers } = this.props;

        let ranges = {};
        ranges[t("range.Today")] = [moment().toDate(), moment().toDate()];
        ranges[t("range.Yesterday")] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
        ranges[t("range.Last7Days")] = [moment().subtract(6, 'days'), moment(), moment().toDate()];
        ranges[t("range.Last30Days")] = [moment().subtract(30, 'days'), moment(), moment().toDate()];
        ranges[t("range.ThisMonth")] = [moment().startOf('month'), moment().endOf('month')];
        ranges[t("range.LastMonth")] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
        ranges[t("range.ThisYear")] = [moment().startOf('year'), moment().endOf('year')];

        if (moment().startOf('year').month("September") < moment()) {
            ranges[t("range.FirstSemester")] = [moment().startOf('year').month("September"), moment().endOf('year')];
            ranges[t("range.ThisStudyYear")] = [moment().startOf('year').month("September").startOf('month'), moment().toDate()];
        } else {
            ranges[t("range.FirstSemester")] = [moment().subtract(1, 'year').month("September"), moment().subtract(1, 'year').endOf('year')];

            if (moment().startOf('year') <= moment()) {
                ranges[t("range.SecondSemester")] = [moment().startOf('year'), moment().startOf('year').month("April").day(10)];
            }
            if (moment().startOf('year').month("April").day(15) <= moment()) {
                ranges[t("range.ThirdSemester")] = [moment().startOf('year').month("April").day(15), moment().startOf('year').month("June").endOf('month')];
            }
            ranges[t("range.ThisStudyYear")] = [moment().subtract(1, 'year').month("September").startOf('month'), moment().toDate()];
        }

        let isLoadedStudentInClassRooms = false;
        if (studentInClassRooms) {
            isLoadedStudentInClassRooms = studentInClassRooms.isLoaded;
        }
        let isLoadedTeacher = false;
        if (teachers) {
            isLoadedTeacher = teachers.isLoaded;
        }
        let isLoadedSchool = false;
        if (schools) {
            isLoadedSchool = schools.isLoaded;
        }

        return (
            <>
                <Helmet>
                    <title>
                        {t('Pages.UserControl.LessonsStatistics')} | {t('alarabeyya')}
                    </title>
                </Helmet>
                <section className="pos-rel padding-bottom padding-top oh">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <div className="row ">
                            <div className="col-lg-4 mb-50">
                                <div className="widget widget-category">
                                    <div className="widget-header d-flex justify-content-between">
                                        <h5 className="title align-self-center">
                                            {t("Pages.Search.title")}
                                        </h5>
                                    </div>
                                    <div className="widget-body">
                                        <div className="contact-form">

                                            <div className="form-group w-100">
                                                <label className='text-dark font-size-h5'><i className="icon fas fa-keyboard"></i>{t('Pages.Search.Type.title')}</label>
                                                <select
                                                    defaultValue={this.state.Type}
                                                    onChange={e => this.setType(e.target.value)}
                                                    className="form-control"
                                                    name="Type"
                                                    title={t("Pages.Search.Type.title")}
                                                    placeholder={t("Pages.Search.Type.Placeholder")}    >
                                                    <option value="Lessons">{t("PageHeader.Lessons")}</option>
                                                    <option value="Files">{t("PageHeader.Files")}</option>
                                                    <option value="Exams">{t("PageHeader.Exams")}</option>
                                                    <option value="Books">{t("PageHeader.Books")}</option>
                                                    <option value="Quizzes">{t("PageHeader.Quizzes")}</option>
                                                    <option value="Fun">{t("PageHeader.Fun")}</option>
                                                </select>
                                            </div>

                                            <div className={`form-group w-100`}>
                                                <label className='text-dark font-size-h5'><i className="icon fas fa-calendar-week"></i>{t('Pages.Search.Date.title')}</label>
                                                <DateRangePicker
                                                    ref={Date => this.Date = Date}
                                                    initialSettings={{
                                                        opens: 'center',
                                                        ranges: ranges,
                                                        autoUpdateInput: false,
                                                        buttonClasses: 'btn',
                                                        cancelClass: "btn-danger",
                                                        applyButtonClasses: "btn-primary",
                                                        showDropdowns: true,
                                                        timePicker: false,
                                                        format: 'DD/MM/YYYY',
                                                        maxDate: `${moment().format('DD/MM/YYYY')}`,
                                                        minYear: 2023,
                                                        locale: {
                                                            format: 'DD/MM/YYYY',
                                                            cancelLabel: t('Close'),
                                                            firstDay: 6,
                                                            applyLabel: t('Apply'),
                                                            customRangeLabel: t('range.customRange'),
                                                            monthNames: [
                                                                `${t("months.January")}`,
                                                                `${t("months.February")}`,
                                                                `${t("months.March")}`,
                                                                `${t("months.April")}`,
                                                                `${t("months.May")}`,
                                                                `${t("months.June")}`,
                                                                `${t("months.July")}`,
                                                                `${t("months.August")}`,
                                                                `${t("months.September")}`,
                                                                `${t("months.October")}`,
                                                                `${t("months.November")}`,
                                                                `${t("months.December")}`
                                                            ],
                                                            daysOfWeek: [
                                                                t("daysOfWeek.Sunday"),
                                                                t("daysOfWeek.Monday"),
                                                                t("daysOfWeek.Tuesday"),
                                                                t("daysOfWeek.Wednesday"),
                                                                t("daysOfWeek.Thursday"),
                                                                t("daysOfWeek.Friday"),
                                                                t("daysOfWeek.Saturday")
                                                            ],
                                                        },
                                                    }}
                                                    onApply={this.handleApplyDate} >
                                                    <input
                                                        defaultValue={this.state.date}
                                                        name="Date"
                                                        autoComplete="off"
                                                        required
                                                        type="text"
                                                        placeholder={t('Pages.Search.Date.Placeholder')}
                                                        title={t('Pages.Search.Date.title')}
                                                    />
                                                </DateRangePicker>
                                            </div>

                                            {
                                                user.user && user.user.UserType.Id === 1 ?
                                                    <>

                                                        <div className="form-group w-100">
                                                            <label className='text-dark font-size-h5'><i className="icon fas fa-globe"></i>{t('Pages.Search.IsPublic.title')}</label>

                                                            <select
                                                                defaultValue={this.state.IsPublic}
                                                                onChange={e => this.setIsPublic(e.target.value)}
                                                                className="form-control"
                                                                name="IsPublic"
                                                                title={t("Pages.Search.IsPublic.title")}  >
                                                                <option value="">{t("Pages.Search.IsPublic.Placeholder")}  </option>
                                                                <option value={true}>{t("Pages.Search.IsPublic.True")}  </option>
                                                                <option value={false}>{t("Pages.Search.IsPublic.False")}  </option>
                                                            </select>
                                                        </div>

                                                        <div className="form-group w-100">
                                                            <label className='text-dark font-size-h5'><i className="icon fas fa-users"></i>{t('Pages.UsersControl.UserType.title')}</label>
                                                            <select
                                                                defaultValue={this.state.UserTypeId}
                                                                onChange={e => this.setUserType(e.target.value)}
                                                                className="form-control"
                                                                name="UserTypeId"
                                                                title={t("Pages.UsersControl.UserType.title")}
                                                                placeholder={t("Pages.UsersControl.UserType.Placeholder")}    >
                                                                <option value="0">{t("Pages.UsersControl.advancedSearch.UserType")}  </option>
                                                                {this.renderUserTypeOption()}
                                                            </select>
                                                        </div>

                                                        <div className="form-group w-100">
                                                            <label className='text-dark font-size-h5'><i className="icon fas fa-school"></i>{t('Pages.UsersControl.advancedSearch.School')}</label>
                                                            <div>
                                                                <Select
                                                                    isClearable={true}
                                                                    name="SchoolId"
                                                                    placeholder={t('Pages.UsersControl.advancedSearch.School')}
                                                                    isRtl={true}
                                                                    isLoading={!isLoadedSchool}
                                                                    isSearchable={true}
                                                                    ref={SchoolId => this.SchoolId = SchoolId}
                                                                    options={this.state.schools}
                                                                    filterOption={this.filterOptions}
                                                                    onChange={this.setSchoolId}
                                                                    theme={theme => ({
                                                                        ...theme,
                                                                        borderRadius: 0,
                                                                        colors: {
                                                                            ...theme.colors,
                                                                            primary25: '#92278f',
                                                                            primary: '#92278f',
                                                                        },
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group w-100">
                                                            <label className='text-dark font-size-h5'><i className="icon fas fa-screen-users"></i>
                                                                {t('Pages.UsersControl.advancedSearch.ClassType.Placeholder')}</label>
                                                            <select
                                                                defaultValue={this.state.ClassTypeId}
                                                                onChange={e => this.setClassTypeId(e.target.value)}
                                                                className="form-control"
                                                                name="ClassTypeId"
                                                                placeholder={t("Pages.UsersControl.advancedSearch.ClassType.Placeholder")}
                                                                title={t("Pages.UsersControl.advancedSearch.ClassType.title")}  >
                                                                <option value="">{t("Pages.UsersControl.advancedSearch.ClassType.Placeholder")}</option>
                                                                {this.renderClassTypeOption()}
                                                            </select>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="form-group w-100" style={{
                                                            zIndex: 9999998
                                                        }}>
                                                            <label className='text-dark font-size-h5'><i className="icon fas fa-chalkboard-teacher"></i>
                                                                {t('Pages.Statistics.TeacherSearch')}</label>
                                                            <div>
                                                                <Select
                                                                    isClearable={true}
                                                                    name="TeacherId"
                                                                    placeholder={t('Pages.Statistics.TeacherSearch')}
                                                                    isRtl={true}
                                                                    isMulti={true}
                                                                    isLoading={!isLoadedTeacher}
                                                                    isSearchable={true}
                                                                    ref={TeacherId => this.TeacherId = TeacherId}
                                                                    options={this.state.teachers}
                                                                    filterOption={this.filterOptions}
                                                                    theme={theme => ({
                                                                        ...theme,
                                                                        borderRadius: 0,
                                                                        colors: {
                                                                            ...theme.colors,
                                                                            primary25: '#92278f',
                                                                            primary: '#92278f',
                                                                        },
                                                                    })}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="form-group w-100" style={{
                                                            zIndex: 9999997
                                                        }}>
                                                            <label className='text-dark font-size-h5'><i className="icon fas fa-screen-users"></i>
                                                                {t('Pages.UsersControl.advancedSearch.ClassType.Placeholder')}</label>
                                                            <Select
                                                                name="ClassRoomId"
                                                                title={t('Pages.Missions.ClassRoom.title')}
                                                                placeholder={t('Pages.Missions.ClassRoom.placeholder')}
                                                                onChange={e => this.setSelectedOption(e)}
                                                                isRtl={true}
                                                                isMulti={true}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                ref={ClassRoomId => this.ClassRoomId = ClassRoomId}
                                                                options={this.state.classRooms}
                                                                filterOption={this.filterOptions}
                                                                theme={theme => ({
                                                                    ...theme,
                                                                    borderRadius: 0,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#92278f',
                                                                        primary: '#92278f',
                                                                    },
                                                                })}
                                                            />
                                                        </div>

                                                        <div className="form-group w-100" onClick={() => this.checkStudent()} style={{
                                                            zIndex: 9999996
                                                        }}>
                                                            <label className='text-dark font-size-h5'><i className="icon fas fa-user-graduate"></i>{t('Pages.Statistics.StudentSearch')}</label>
                                                            <Select
                                                                name="StudentId"
                                                                title={t('Pages.Statistics.StudentSearch')}
                                                                placeholder={t('Pages.Statistics.StudentSearch')}
                                                                onChange={e => this.setSelectedOptionStudent(e)}
                                                                isRtl={true}
                                                                isMulti={true}
                                                                isLoading={!isLoadedStudentInClassRooms}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                ref={StudentId => this.StudentId = StudentId}
                                                                options={this.state.students}
                                                                filterOption={this.filterOptions}
                                                                theme={theme => ({
                                                                    ...theme,
                                                                    borderRadius: 0,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#92278f',
                                                                        primary: '#92278f',
                                                                    },
                                                                })}
                                                            />
                                                        </div>
                                                    </>
                                            }

                                            <div className="form-group w-100">
                                                <button className="custom-button w-100" onClick={() => this.submit()}  >
                                                    <span>
                                                        <i className={`icon fal fa-magnifying-glass`}></i>
                                                        {
                                                            t('Pages.Search.submit')
                                                        }
                                                    </span>
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 mb-50">
                                <div className="widget widget-category h-100">
                                    <div className="widget-body">
                                        <table className="table table-striped table-hover table-bordered my-0 w-100 bg-white">
                                            <thead>
                                                <tr className="text-center">
                                                    <th title={t(`PageHeader.${this.state.Type}`)} className="align-middle">
                                                        {
                                                            this.state.Type === "Books" ?
                                                                <i className="icon fa-light fa-book"></i>
                                                                :
                                                                this.state.Type === "Lessons" ?
                                                                    <i className="icon fa-light fa-layer-group"></i>
                                                                    :
                                                                    this.state.Type === "Exams" ?
                                                                        <i className="icon fa-light fa-pencil-alt"></i>
                                                                        :
                                                                        this.state.Type === "Quizzes" ?
                                                                            <i className="icon fa-light fa-question "></i>
                                                                            :
                                                                            this.state.Type === "Fun" ?
                                                                                <i className="icon fa-light fa-gamepad-alt "></i>
                                                                                :
                                                                                <i className="icon fa-light fa-paperclip  "></i>

                                                        }
                                                        <span className="d-none d-lg-inline-block">
                                                            {t(`PageHeader.${this.state.Type}`)}
                                                        </span>
                                                    </th>
                                                    <th title={t('ControlPanel.Indices.title')} className="align-middle">
                                                        <i className="icon fa-light fa-indent "></i>
                                                        <span className="d-none d-lg-inline-block">
                                                            {t('ControlPanel.Indices.title')}
                                                        </span>
                                                    </th>
                                                    <th title={t('Pages.Search.Table.Order')} className="align-middle">
                                                        <i className="icon fa-light fa-sort-numeric-down"></i>
                                                        <span className="d-none d-lg-inline-block">
                                                            {t('Pages.Search.Table.Order')}
                                                        </span>
                                                    </th>
                                                    <th title={t('Pages.Search.Table.View')} className="align-middle">
                                                        <i className="icon fa-light fa-eye"></i>
                                                        <span className="d-none d-lg-inline-block">
                                                            {t('Pages.Search.Table.View')}
                                                        </span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.renderCols()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer></Footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        studentInClassRooms: state.studentInClassRooms,
        teachers: state.teachers,
        classRooms: state.classRooms,
        user: state.user,
        indices: state.indices,
        schools: state.schools
    };
};

export default connect(mapStateToProps, { fetchIndices, fetchSchools, fetchStudentInMultiClassRooms, fetchClassRooms, fetchTeachers })(withTranslation('common')(LessonsStatistics));
