import React from "react";
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";

import FooterBottom from "../../Footer/FooterBottom";
import TreeView from "../Indices/TreeView";
import { fetchIndices, updateIndicesRemoveCount } from '../Indices/actions/';
import { getIndeices, setIndeices, getFiles, setFiles, removeIndeicesCount } from "../../../context/sessionStorage";
import {
    fetchFiles as fetch,
    removeFile as remove,
    undoDeleteFile as undo,
    clearFile as clear,
    fetchFile,
    clearFileInfo
} from './actions';
import ListItem from "./ListItem";
import EditModal from './Modal/EditModal';
import DragDropModal from './Modal/DragDropModal';
import api from '../../../api/api';
import history from '../../../history';

class Files extends React.Component {

    state = { currentItem: '', showDragDropModal: false, indices: [], search: '', showEditFormModal: false, onUpdate: false };

    componentDidMount() {
        let indices = getIndeices();
        if (indices === null || indices.length === 0) {
            this.props.fetchIndices();
        }
        this.setState({
            indices: indices
        });

        const { id } = this.props.match.params;
        if (id !== '0') {
            this.setState({
                currentItem: {
                    id: parseInt(id)
                }
            });
            this.props.fetch(id);
        }
    }

    setSearch = value => {
        this.setState({
            search: value
        });
    }

    Sync = () => {
        if (this.state.currentItem) {
            this.props.fetch(this.state.currentItem.id);
        }
    }

    ShowEditForm = () => {
        this.setState({
            showEditFormModal: true,
            onUpdate: false
        });
    }

    showEdit = id => {
        this.props.fetchFile(id);

        this.setState({
            showEditFormModal: true,
            onUpdate: true
        });
    }

    setCurrentItem = item => {
        this.setState({ currentItem: item });
        let checkFromServer = false;
        let files = getFiles();
        if (files === null || files.length === 0)
            checkFromServer = true;
        else {
            let filterFile = files.files.filter(v => v.Index.Id === item.id);
            if (filterFile === null || filterFile.length === 0)
                checkFromServer = true;
        }
        if (checkFromServer) {
            this.props.fetch(item.id);
        }
        history.push(`/ControlPanel/Files/${item.id}`);
    }

    GetNode(nodes, Id) {
        var result = false;
        if (nodes) {
            nodes.forEach(node => {
                if (node.id === Id && node.Level >= 2) {
                    result = node.nodes;
                }
            });
            if (result) {
                return result;
            }
            nodes.forEach(node => {
                var subResult = this.GetNode(node.nodes, Id);
                if (subResult) {
                    result = subResult;
                    return false;
                }
            });
        }
        return result;
    }

    renderFiles() {
        const { t, user } = this.props;
        let files = this.props.files;
        let isLoaded = false;

        if (files) {
            isLoaded = files.isLoaded;
        }

        let toRemove = [];
        if (files) {
            toRemove = files.toRemove;
        }

        let indices = this.props.indices;
        if (indices) {
            setIndeices(indices);
        } else {
            indices = this.state.indices;
        }

        if (files && files.files && files.files.length > 0) {
            setFiles(files);
        } else {
            files = getFiles();
        }

        let search = '';
        if (this.state.search) {
            search = this.state.search;
        }

        if (files && files.files && files.files.length > 0 && this.state.currentItem) {
            let searchText = search.replace(/([ًٌٍَُِّْ])/g, "").replace(/([أإٱآ])/g, "ا");
            let filterFile = files.files.filter(v => v.SearchName.includes(searchText) && v.Index.Id === this.state.currentItem.id);

            if (isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.Files.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            if (filterFile.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.Files.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.Files.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            if (filterFile.length === 0) {
                if (indices && indices.nodes && this.state.currentItem) {
                    var nodes = this.GetNode(indices.nodes, this.state.currentItem.id);
                    if (nodes && nodes.length > 0) {
                        return <div className="d-flex flex-wrap justify-content-center justify-content-sm-around">
                            {
                                nodes.map(item => {
                                    return <div className="pr-1 bookWrap mb-50 " key={item.id}>
                                        <Link to="#" className='w-100' onClick={() => this.setCurrentItem(item)}>
                                            <div className="shop-item">
                                                <div className="product-thumb">
                                                    {
                                                        item.icon ?
                                                            <LazyLoadImage src={item.icon} alt={`${item.label}`} />
                                                            :
                                                            <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.label}`} />
                                                    }
                                                </div>
                                                <div className="product-content">
                                                    <h5 className="title">{item.label}</h5>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                })
                            }
                        </div>
                    }
                }
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.Files.NotFound")}
                            </h4>
                        </div>
                    </div>
                );
            }

            return filterFile.sort((a, b) => a.Sort < b.Sort ? 1 : -1).map(file =>
                (toRemove && toRemove.includes(file.Id)) ? "" :
                    <ListItem
                        key={file.Id}
                        user={user} IsAdmin={true}
                        setIndex={this.setCurrentItem}
                        item={file}
                        indices={indices.nodes}
                        edit={this.showEdit}
                        remove={this.showDelete} />
            );
        }

        if (!this.state.currentItem) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-glasses "></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.Files.SelectIndex")}
                        </h4>
                    </div>
                </div>
            );
        }
        if (files && files.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.Files.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.Files.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    handleClose = () => {
        this.props.clearFileInfo();
        this.setState({
            showEditFormModal: false,
            showDragDropModal: false,
            onUpdate: false
        });
    }

    Sort = () => {
        if (this.state.currentItem) {
            this.setState({
                showDragDropModal: true,
            });
        }
    }

    setSort = () => {
        this.Sync();

        this.setState({
            showDragDropModal: false
        });
    }

    showDelete = id => {
        if (id) {
            this.props.remove(id);
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDelete(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteFile(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });
        }
    }

    undoDelete = id => {
        this.props.undo(id);
    }

    deleteFile = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        let Index = '';
        var nodes = getFiles();
        if (nodes) {
            Index = nodes.files.filter(v => v.Id === id)[0].Index;
        }

        api.delete(`/EducationalFiles/${id}`).then(response => {
            this.props.clear(id);
            this.props.updateIndicesRemoveCount(Index.Id);
            removeIndeicesCount(Index.Id);
            this.Sync();

            this.setState({
                indices: this.state.indices
            });

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.Files.toast.Delete.Title')}</div> <div className="h6">{t('Pages.Files.toast.Delete.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });

        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    render() {
        const { t } = this.props;

        let indices = this.props.indices;
        if (indices) {
            setIndeices(indices);
        } else {
            indices = this.state.indices;
        }

        let isLoaded = false;
        let current = null;
        let files = this.props.files;
        if (files) {
            isLoaded = files.isLoaded;
            current = files.current;
        }

        let currentId = (this.state.currentItem) ? parseInt(this.state.currentItem.id) : 0;

        let nodes = this.props.files;
        if (files && files.files && files.files.length > 0) {
            nodes = this.props.files.files.sort((a, b) => a.Sort < b.Sort ? 1 : -1);
        } else {
            nodes = getFiles()
            if (nodes) {
                nodes = nodes.files.sort((a, b) => a.Sort < b.Sort ? 1 : -1);
            }
        }
        return (
            <>
                {
                    this.state.showDragDropModal ?
                        <DragDropModal
                            show={true}
                            IndexId={currentId}
                            nodes={nodes}
                            API={`/EducationalFiles/`}
                            setSort={this.setSort}
                            handleClose={this.handleClose}></DragDropModal>
                        : ""
                }
                {
                    this.state.showEditFormModal ?
                        <EditModal
                            onUpdate={this.state.onUpdate}
                            item={current}
                            updateFile={this.Sync}
                            handleClose={this.handleClose}
                            show={this.state.showEditFormModal}></EditModal>
                        : ""
                }
                <section className="pos-rel padding-top padding-bottom" style={{
                    minHeight: '75vh'
                }}>
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">
                        <Helmet>
                            <title>{t("PageHeader.Files")} | {t('alarabeyya')}</title>
                        </Helmet>

                        <div className="row justify-content-center" data-aos="fade-top" data-aos-easing="linear">
                            <div className="col-lg-8">
                                <div className="section-header">
                                    <span className="cate">{t('ControlPanel.title')}</span>
                                    <h3 className="title">{t('PageHeader.Files')} </h3>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-30-none">
                            <div className="col-lg-4 mb-50">
                                {indices ?
                                    <TreeView
                                        currentId={currentId}
                                        data={indices.nodes}
                                        toRemove={indices.toRemove}
                                        type='Files'
                                        setCurrentItem={this.setCurrentItem}></TreeView>
                                    : ""
                                }
                            </div>
                            <div className="col-lg-8 mb-50">
                                <div className="widget widget-search" >
                                    <div className="widget-body">
                                        <div className="d-flex">
                                            <div className="p-2 flex-grow-1 ">
                                                <form className="search-form">
                                                    <input type="text" onChange={e => this.setSearch(e.target.value)} defaultValue={this.state.search} placeholder={t("Pages.Files.search")} />
                                                    <button type="button"><i className="fas fa-search"></i></button>
                                                </form>
                                            </div>
                                            <div className="pt-3">
                                                <div onClick={() => this.ShowEditForm()} title={t("Pages.Files.Add")} className="btn btn-primary ">
                                                    <i className="fas fa-plus"></i>
                                                </div>
                                                <div onClick={() => this.Sort()} title={t("Pages.Lessons.Sort")} className="btn btn-dark mx-1">
                                                    <i className="fas fa-sort-numeric-up"></i>
                                                </div>
                                                <div onClick={() => this.Sync()} title={t("Pages.Lessons.Sync")} className="btn btn-danger">
                                                    {
                                                        isLoaded ?
                                                            <i className="fas fa-sync-alt"></i>
                                                            :
                                                            <i className="fas fa-sync-alt fa-spin"></i>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.renderFiles()}
                            </div>
                        </div>
                    </div>
                </section>
                <footer>
                    <FooterBottom></FooterBottom>
                </footer>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        indices: state.indices,
        files: state.files
    };
};

export default connect(mapStateToProps, { fetchIndices, updateIndicesRemoveCount, fetch, remove, undo, fetchFile, clear, clearFileInfo })(withTranslation('common')(Files));