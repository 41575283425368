import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Footer from "../Footer/FooterBottom";

class Prices extends React.Component {


    render() {
        const { t } = this.props;

        return (
            <>
                <Helmet>
                    <title>{t("PageHeader.Prices")} | {t('alarabeyya')}</title>
                </Helmet>
                <section className="pos-rel padding-bottom padding-top d-flex oh align-items-center justify-content-center">
                    <div className="bottom-shape-left" style={{
                        right: "50px",
                        top: "calc(50% - 350px)"
                    }}>
                        <LazyLoadImage src="/assets/images/about/4275.png" style={{
                            transform: 'scaleX(-1)'
                        }} alt={`${t('alarabeyya')}`} />
                    </div>

                    <div className="container h-100">

                        <div className="row clearfix justify-content-center">

                            <div className="featured-block col-md-12 " >
                                <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" >

                                    <div className="icon-box d-flex justify-content-center flex-column cursor-pointer">
                                        <span className="icon"> <i className="fal fa-user-graduate display-4 text-white"></i></span>
                                    </div>
                                    <h5 className="cursor-pointer mt-1 mb-3">
                                        الاشتراكات الفردية
                                    </h5>

                                    <div className="table-responsive">
                                        <table className="table table-hover table-bordered ">
                                            <tr>
                                                <th></th>
                                                <th>المجّانيّ</th>
                                                <th>الأَساسيّ </th>
                                                <th>المتقدّم</th>
                                                <th>الشامل</th>
                                            </tr>
                                            <tr>
                                                <th>السعر</th>
                                                <td>
                                                    لا يوجد
                                                </td>
                                                <td>
                                                    <span className="mx-1">
                                                        20
                                                    </span>
                                                    <i className="fal fa-dollar-sign"></i>
                                                </td>
                                                <td>
                                                    <span className="mx-1">
                                                        30
                                                    </span>
                                                    <i className="fal fa-dollar-sign"></i>
                                                </td>
                                                <td>
                                                    <span className="mx-1">
                                                        85
                                                    </span>
                                                    <i className="fal fa-dollar-sign"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>مدة الاشتراك</th>
                                                <td>مدى الحياة</td>
                                                <td>سنة</td>
                                                <td>سنة</td>
                                                <td>سنة</td>
                                            </tr>
                                            <tr>
                                                <th>الدعم الفني</th>
                                                <td>محدود</td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                            </tr>
                                            <tr>
                                                <th>الدروس المحوسبة</th>
                                                <td>المجّانيّ فقط</td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                            </tr>
                                            <tr>
                                                <th>الامتحانات المحوسبة</th>
                                                <td>المجّانيّ فقط</td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                            </tr>
                                            <tr>
                                                <th>اختبر نفسك</th>
                                                <td>المجّانيّ فقط</td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                            </tr>
                                            <tr>
                                                <th>الألعاب المحوسبة</th>
                                                <td>المجّانيّ فقط</td>
                                                <td><i className="fa-solid fa-xmark mx-1 text-danger"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                            </tr>
                                            <tr>
                                                <th>الكتب المحوسبة</th>
                                                <td>المجّانيّ فقط</td>
                                                <td><i className="fa-solid fa-xmark mx-1 text-danger"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                            </tr>
                                            <tr>
                                                <th>الملفات التعليميّة</th>
                                                <td>المجّانيّ فقط</td>
                                                <td><i className="fa-solid fa-xmark mx-1 text-danger"></i></td>
                                                <td><i className="fa-solid fa-xmark mx-1 text-danger"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                            </tr>
                                            <tr>
                                                <th>حقيبة المعلم</th>
                                                <td>المجّانيّ فقط</td>
                                                <td><i className="fa-solid fa-xmark mx-1 text-danger"></i></td>
                                                <td><i className="fa-solid fa-xmark mx-1 text-danger"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>
                                                    <Link to="/Register/Single" className={`custom-button info-button`} >
                                                        <span>
                                                            اِبدَأْ تجربتك المجّانيّة الآن
                                                        </span>
                                                    </Link>
                                                </td>
                                                <td colSpan={3}>
                                                    <Link to="/Register/Single" className={`custom-button primary-button`} >
                                                        <span>
                                                            سجل الآن
                                                        </span>
                                                    </Link>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>

                                    <p className="text-justify w-100 px-3 font-weight-bold">
                                        سوف تحصل على أكثر 200 مادة تعليميّة مجّانًا وتشمل اكثر من 100 ملف محوسب و اكثر من 50 درس مجاني في جميع المراحل وتشمل اكثر 50 امتحان محوسب واكثر من 20 لعبة مجانية.
                                    </p>

                                </div>
                            </div>


                            <div className="featured-block col-md-12 " >
                                <div className="inner-box wow fadeInLeft animated" data-wow-delay="0ms" data-wow-duration="1500ms" >

                                    <div className="icon-box d-flex justify-content-center flex-column cursor-pointer">
                                        <span className="icon"> <i className="fal fa-school display-4 text-white"></i></span>
                                    </div>
                                    <h5 className="cursor-pointer mt-1 mb-3">
                                        {t('Pages.Register.School.title')}
                                    </h5>

                                    <div className="table-responsive">
                                        <table className="table table-hover table-bordered ">
                                            <tr>
                                                <th></th>
                                                <th>أَساسيّ </th>
                                                <th>أَساسيّ  موسّع</th>
                                                <th>متقدم</th>
                                                <th>متقدم موسّع</th>
                                                <th>شامل </th>
                                                <th>شامل  موسّع</th>
                                            </tr>
                                            <tr>
                                                <th>السعر</th>
                                                <td>
                                                    <span className="mx-1">
                                                        50
                                                    </span>
                                                    <i className="fal fa-dollar-sign"></i>
                                                </td>
                                                <td>
                                                    <span className="mx-1">
                                                        100
                                                    </span>
                                                    <i className="fal fa-dollar-sign"></i>
                                                </td>
                                                <td>
                                                    <span className="mx-1">
                                                        120
                                                    </span>
                                                    <i className="fal fa-dollar-sign"></i>
                                                </td>
                                                <td>
                                                    <span className="mx-1">
                                                        250
                                                    </span>
                                                    <i className="fal fa-dollar-sign"></i>
                                                </td>
                                                <td>
                                                    <span className="mx-1">
                                                        400
                                                    </span>
                                                    <i className="fal fa-dollar-sign"></i>
                                                </td>
                                                <td>
                                                    <span className="mx-1">
                                                        850
                                                    </span>
                                                    <i className="fal fa-dollar-sign"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>مدة الاشتراك</th>
                                                <td>سنة</td>
                                                <td>سنة</td>
                                                <td>سنة</td>
                                                <td>سنة</td>
                                                <td>سنة</td>
                                                <td>سنة</td>
                                            </tr>
                                            <tr>
                                                <th>عدد المعلمين</th>
                                                <td>1</td>
                                                <td>1</td>
                                                <td>1</td>
                                                <td>1</td>
                                                <td>طاقم اللغة</td>
                                                <td>طاقم اللغة</td>
                                            </tr>
                                            <tr>
                                                <th>عدد الطلاب</th>
                                                <td>20</td>
                                                <td>70</td>
                                                <td>20</td>
                                                <td>70</td>
                                                <td>غير محدود</td>
                                                <td>غير محدود</td>
                                            </tr>
                                            <tr>
                                                <th>الدعم الفني</th>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                            </tr>
                                            <tr>
                                                <th>ارسال المهام</th>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                            </tr>
                                            <tr>
                                                <th>تقييم الطلاب</th>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                            </tr>
                                            <tr>
                                                <th>الدروس المحوسبة</th>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                            </tr>
                                            <tr>
                                                <th>الامتحانات المحوسبة</th>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                            </tr>
                                            <tr>
                                                <th>اختبر نفسك</th>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                            </tr>
                                            <tr>
                                                <th>الألعاب المحوسبة</th>
                                                <td><i className="fa-solid fa-xmark mx-1 text-danger"></i></td>
                                                <td><i className="fa-solid fa-xmark mx-1 text-danger"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-xmark mx-1 text-danger"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                            </tr>
                                            <tr>
                                                <th>الكتب المحوسبة</th>
                                                <td><i className="fa-solid fa-xmark mx-1 text-danger"></i></td>
                                                <td><i className="fa-solid fa-xmark mx-1 text-danger"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-xmark mx-1 text-danger"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                            </tr>
                                            <tr>
                                                <th>الملفات التعليميّة</th>
                                                <td><i className="fa-solid fa-xmark mx-1 text-danger"></i></td>
                                                <td><i className="fa-solid fa-xmark mx-1 text-danger"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-xmark mx-1 text-danger"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                            </tr>
                                            <tr>
                                                <th>حقيبة المعلم</th>
                                                <td><i className="fa-solid fa-xmark mx-1 text-danger"></i></td>
                                                <td><i className="fa-solid fa-xmark mx-1 text-danger"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                                <td><i className="fa-solid fa-xmark mx-1 text-danger"></i></td>
                                                <td><i className="fa-solid fa-check mx-1 text-success"></i></td>
                                            </tr>
                                        </table>
                                    </div>

                                    <Link to="/Register/Single" className={`custom-button primary-button`} >
                                        <span>
                                            سجل الآن
                                        </span>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <Footer />
            </>
        );
    }
}

export default withTranslation('common')(Prices);