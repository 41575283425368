import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { Player } from '@lottiefiles/react-lottie-player';
import { toast } from 'react-toastify';
import { Field, reduxForm } from 'redux-form';
import Rating from "react-rating";
import moment from "moment";
import { connect } from 'react-redux';

import TreeView from "../../Admin/Indices/TreeView";
import api from '../../../api/api';
import { ShareLessonBySocket, notificationIsView } from '../../../api/socket.io';
import StudentInClassRoom from '../../School/StudentInClassRooms/';

import history from '../../../history';
import SendMissionForm from "../Forms/SendMissionForm";
import { setLessonIsView, fetchLesson, removeCurrentLessons } from "../../Admin/Lessons/actions";

class LessonItem extends React.Component {

    state = {
        moreInfo: false,
        pathList: [],
        SelectedQuestions: [],
        showCopy: false,
        showPlayer: false,
        showDoneCopy: false,
        showSendMission: false,
        AllQuestion: true,
        copyId: 0,
        src: "",
        studentType: "all"
    };

    constructor(props) {
        super(props);
        this.pathList = [];
        this._toastId = new React.createRef();
    }

    setAllQuestion = AllQuestion => {
        this.setState({
            AllQuestion
        });
        if (!AllQuestion) {
            this.props.removeCurrentLessons();
            this.props.fetchLesson(this.props.lesson.Id);
        }
    }

    moreInfo = () => {
        this.setState({
            moreInfo: !this.state.moreInfo
        });
    }

    showCopy = () => {
        this.setState({
            showCopy: true,
            src: process.env.PUBLIC_URL + "/assets/effect/public/8170-file-printer.json"
        });
    }

    showSendMission = () => {
        this.setState({
            showSendMission: true
        });
    }

    showShare = () => {
        this.props.initialize({
            ClassRoomId: "",
        });
        this.setState({
            showShare: true,
            ClassRoomId: ""
        });
    }

    setIndex(node) {
        if (this.props.setIndex) {
            this.props.setIndex(node);
        } else {
            this.pathList = [];
            this.getNodePath(this.props.indices, this.props.lesson.Index.Id, 0);
            var list = this.pathList.reverse();
            history.push(`/${list[1].type}/${list[0].id}/${node.id}`);

        }
    }

    renderPath(currentId) {
        this.pathList = [];
        this.getNodePath(this.props.indices, currentId, 1);
        if (this.pathList) {
            const length = this.pathList.length;
            return this.pathList.reverse().map((node, index) => {
                return (
                    <span
                        key={node.id}>
                        <Link
                            onClick={() => this.setIndex(node)}
                            to="#">
                            {node.label}
                        </Link>
                        {(length !== index + 1) ? <i className="fas fa-arrow-left text-info m-2"></i> : ""}
                    </span>
                );
            });
        }
        return "";
    }

    getNodePath(nodes, currentId, Level) {
        if (nodes) {
            nodes.forEach(node => {
                const isOpen = this.HasNode(node, currentId);
                if (isOpen) {
                    this.getNodePath(node.nodes, currentId, Level);
                    if (node.Level > Level) {
                        this.pathList.push(node);
                    }
                }
            });
        }
    }

    HasNode(node, currentId) {
        if (node) {
            return (currentId === node.id) || this.hasSelectedNodeInChildren(node.nodes, currentId);
        }
        return false;
    }

    hasSelectedNodeInChildren(nodes, currentId) {
        var result = false;
        nodes.forEach(node => {
            result = result || (currentId === node.id) || this.hasSelectedNodeInChildren(node.nodes, currentId);
        });
        return result;
    }

    openViewWindow = Id => {
        const { lesson, user } = this.props;
        if (user && user.user) {
            if (lesson.IsView === false && user.user.UserType.Id === 5) {
                if (lesson.Index.Type === "Exams") {
                    notificationIsView("EXAMS", user.user.Id);
                } else if (lesson.Index.Type === "Quizzes") {
                    notificationIsView("QUIZZES", user.user.Id);
                } else if (lesson.Index.Type === "Lessons") {
                    notificationIsView("LESSON", user.user.Id);
                } else if (lesson.Index.Type === "Games") {
                    notificationIsView("GAMES", user.user.Id);
                } else if (lesson.Index.Type === "SelfDevelopment") {
                    notificationIsView("SELFDEVELOPMENT", user.user.Id);
                } else {
                    notificationIsView("BOOK", user.user.Id);
                }
                this.props.setLessonIsView(lesson.Id);
            }
            if (this.props.type === "Exams" || this.props.type === "Quizzes") {
                window.openExamView(Id);
            } else if (this.props.type === "Games" || this.props.type === "Fun") {
                window.openGameView(Id);
            } else if (this.props.type === "SelfDevelopment") {
                window.openSelfDevelopmentView(Id);
            } else {
                window.openLessonView(Id);
            }
        } else {
            history.push("/Login");
        }
    }

    openPDFViewWindow = Id => {
        const { lesson, user } = this.props;
        if ((user && user.user) || lesson.IsPublic) {
            window.openPDFViewWindow(Id);
        } else {
            history.push("/Login");
        }
    }

    handleClose = () => {
        this.setState({
            showCopy: false,
            showShare: false,
            showSendMission: false,
            AllQuestion: true
        });
        this.props.removeCurrentLessons();
    }

    setCurrentItem = item => {
        this.setState({ currentItem: item });
    }

    handleCopy = id => {
        let IndexId = (this.state.currentItem) ? parseInt(this.state.currentItem.id) : 0;
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        if (IndexId) {
            this.setState({
                showPlayer: true
            });
            api.post(`/LessonCopy/${id}`,
                {
                    IndexId,
                    Questions: this.state.SelectedQuestions
                }
            ).then(response => {
                if (response.data.data.Id !== 0) {
                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-copy"></i> {t('Success.CopyLesson.Title')}</div> <div className="h6">{t('Success.CopyLesson.Body')}</div></div>,
                        type: toast.TYPE.SUCCESS,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                    });

                    this.setState({
                        copyId: response.data.data.Id,
                        showDoneCopy: true,
                        AllQuestion: true,
                        SelectedQuestions: [],
                        src: process.env.PUBLIC_URL + "/assets/effect/public/9917-success.json"
                    });

                } else {
                    toast.update(this._toastId.current, {
                        render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Error.CopyError.Title')}</div> <div className="h6">{t('Error.CopyError.Body')}</div></div>,
                        type: toast.TYPE.ERROR,
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }).catch(error => {
                this.setState({
                    showDoneCopy: false,
                    showCopy: false
                });
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        } else {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Error.IndexNotSelected.Title')}</div> <div className="h6">{t('Error.IndexNotSelected.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    renderCopy() {
        if (this.state.showCopy === true) {
            const { t, lesson } = this.props;
            let IndexId = (this.state.currentItem) ? parseInt(this.state.currentItem.id) : 0;

            if (this.state.showDoneCopy === true) {
                this.pathList = [];
                let type = "";
                this.getNodePath(this.props.indices, IndexId, 1);
                if (this.pathList.length > 0) {
                    type = this.pathList[this.pathList.length - 1].type;
                }
                if (type === "Lessons") {
                    type = "Lesson";
                }
                return (
                    <>
                        <Modal
                            key="ModalSuccessCopyLesson"
                            size="xl"
                            show={this.state.showCopy}
                            onHide={() => this.handleClose()}
                            backdrop="static"
                            keyboard={false}
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <i className="icon fal fa-copy"></i>
                                    {t('Success.CopyLesson.Title')}  : {lesson.Name}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className={`w-100`}>
                                    <Player
                                        autoplay
                                        loop
                                        src={this.state.src}
                                        style={{ width: '50%' }}
                                    ></Player>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="row col-12">
                                    <div className="col-6">
                                        <Link className="btn btn-info w-100" to={`/ControlPanel/${type ? type === "Fun" ? "Games" : type : 'Lesson'}/Edit/${this.state.copyId}`} title={t("Pages.Lessons.action.edit")} >
                                            <i className="icon fas fa-edit"></i>
                                            {t('Pages.Lessons.action.edit')}
                                        </Link>
                                    </div>
                                    <div className="col-6">
                                        <button className="btn btn-danger" onClick={() => this.handleClose()}>
                                            <i className="icon fas fa-times"></i>
                                            {t('Close')}
                                        </button>
                                    </div>
                                </div>
                            </Modal.Footer>
                        </Modal>
                    </>
                );
            }
            return (
                <>
                    <Modal
                        key="ModalCopyLesson"
                        size="xl"
                        show={this.state.showCopy}
                        onHide={() => this.handleClose()}
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false} >
                        <Modal.Header closeButton={!this.state.showPlayer}>
                            <Modal.Title>
                                <i className="icon fal fa-copy"></i>
                                {t('Pages.Lessons.tools.copy')}   : {lesson.Name}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {
                                this.state.showPlayer === false ?
                                    <>
                                        <TreeView
                                            IsAdmin={false}
                                            currentId={IndexId}
                                            title={t('Pages.Lessons.tools.copy_title')}
                                            data={this.props.indices}
                                            setCurrentItem={this.setCurrentItem} />

                                        <div className="form-group">
                                            <label className="text-dark font-size-h5" htmlFor="AllQuestion"> <i className="icon far fa-globe"></i>{t(`Pages.Lessons.tools.copy_all_question.title`)}</label>
                                            <div className="custom-control custom-checkbox my-3 mr-sm-4 py-2 checkbox-dark">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    onChange={e => this.setAllQuestion(e.target.checked)}
                                                    ref={AllQuestion => this.AllQuestion = AllQuestion}
                                                    defaultChecked={this.state.AllQuestion}
                                                    name="AllQuestion"
                                                    id="AllQuestion" />
                                                <label className="custom-control-label" htmlFor="AllQuestion" >
                                                    {t(`Pages.Lessons.tools.copy_all_question.placeholder`)}
                                                </label>
                                            </div>
                                        </div>
                                        {
                                            this.state.AllQuestion ? "" :
                                                <>

                                                    {
                                                        this.props.lessons && this.props.lessons.current && this.props.lessons.current.Questions ?
                                                            <div className={`w-100 bg-white px-3 py-4`}>
                                                                {
                                                                    this.props.lessons.current.Questions.sort((a, b) => a.Sort - b.Sort).map((item, index) => {
                                                                        return <div className="form-group" key={`Question-${item.Id}`}>
                                                                            <div className="custom-control custom-checkbox my-3 mr-sm-4 py-2 checkbox-info">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    onChange={e => this.handleChangeSelect(e.target.checked, item.Id)}
                                                                                    name={`Question-${item.Id}`}
                                                                                    id={`Question-${item.Id}`} />
                                                                                <label className="custom-control-label d-flex" htmlFor={`Question-${item.Id}`}>
                                                                                    <div className="text-danger h3 mb-0 mx-1">{index + 1}- </div>
                                                                                    <div dangerouslySetInnerHTML={{
                                                                                        __html: item.Name
                                                                                    }} ></div>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                            :
                                                            <div className={`w-100 bg-white`}>
                                                                <Player
                                                                    autoplay
                                                                    loop
                                                                    src={process.env.PUBLIC_URL + "/assets/effect/loading/8852-searching-for-word.json"}
                                                                    style={{ width: '50%' }}
                                                                ></Player>
                                                            </div>
                                                    }
                                                </>
                                        }
                                    </>
                                    :
                                    <div className={`w-100 bg-primary`}>
                                        <Player
                                            autoplay
                                            loop
                                            src={this.state.src}
                                            style={{ width: '50%' }}
                                        ></Player>
                                    </div>
                            }

                        </Modal.Body>
                        {
                            this.state.showPlayer === false ?
                                <Modal.Footer>
                                    <div className="row col-12">
                                        <div className="col-6">
                                            <button className="btn btn-success" onClick={() => this.handleCopy(lesson.Id)}>
                                                <i className="icon fas fa-copy"></i>
                                                {t('Pages.Lessons.tools.copy')}
                                            </button>
                                        </div>
                                        <div className="col-6">
                                            <button className="btn btn-danger" onClick={() => this.handleClose()}>
                                                <i className="icon fas fa-times"></i>
                                                {t('Close')}
                                            </button>
                                        </div>
                                    </div>
                                </Modal.Footer>
                                : ""
                        }

                    </Modal>
                </>
            );
        }
        return "";
    }

    handleChangeSelect = (isChecked, QuestionId) => {
        if (isChecked) {
            let SelectedQuestions = this.state.SelectedQuestions;
            SelectedQuestions.push(QuestionId);
            this.setState({
                SelectedQuestions
            });
        } else {
            let SelectedQuestions = this.state.SelectedQuestions.filter(p => p !== QuestionId);
            this.setState({
                SelectedQuestions
            });
        }
    }

    onChange = ClassRoomId => {
        this.setState({ ClassRoomId });
    }

    ShowToAll = (ClassRoomId, LessonId) => {
        const { t, lesson } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.post(`/LessonPermissions/${ClassRoomId}`, {
            isAdd: true,
            LessonId: LessonId,
            StudentType: this.state.studentType
        }
        ).then(response => {
            if (response.data.code === "success") {
                toast.update(this._toastId.current, {
                    render: () => <div>
                        <div className="h5">
                            <i className="icon fal fa-mind-share"></i>
                            {this.props.type === "Games" ? t("Success.GamesPermissionShow.Title") : t("Success.LessonPermissionShow.Title")}
                        </div>
                        <div className="h6">
                            {this.props.type === "Games" ? t("Success.GamesPermissionShow.Body") : t("Success.LessonPermissionShow.Body")}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
                this.props.getStudent(ClassRoomId);

                if (this.props.studentInClassRooms && this.props.studentInClassRooms.studentInClassRooms) {
                    let studentList = this.props.studentInClassRooms.studentInClassRooms.map(p => p.Id);
                    if (studentList) {
                        ShareLessonBySocket(studentList, lesson, this.props.type);
                    }
                }

            } else {
                toast.update(this._toastId.current, {
                    render: () => <div>
                        <div className="h5">
                            <i className="icon fal fa-exclamation-triangle"></i>
                            {t('Error.LessonPermissions.Title')}
                        </div>
                        <div className="h6">
                            {t('Error.LessonPermissions.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }
    HideFromAll = (ClassRoomId, LessonId) => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.post(`/LessonPermissions/${ClassRoomId}`, {
            isAdd: false,
            LessonId,
            StudentType: this.state.studentType
        }
        ).then(response => {
            if (response.data.code === "success") {
                toast.update(this._toastId.current, {
                    render: () => <div>
                        <div className="h5">
                            <i className="icon fal fa-mind-share"></i>
                            {this.props.type === "Games" ? t("Success.GamesPermissionHide.Title") : t("Success.LessonPermissionHide.Title")}
                        </div>
                        <div className="h6">
                            {this.props.type === "Games" ? t("Success.GamesPermissionHide.Body") : t("Success.LessonPermissionHide.Body")}
                        </div>
                    </div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
                this.props.getStudent(ClassRoomId);
            } else {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Error.LessonPermissions.Title')}</div> <div className="h6">{t('Error.LessonPermissions.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    setSelectedStudents = studentType => {
        this.setState({
            studentType
        })
    }

    renderShare() {
        if (this.state.showShare === true) {
            const { t, lesson, classRooms, user, type } = this.props;
            const shareTitle = type === "Games" ? t("Pages.Games.action.share") : t("Pages.Lessons.action.share");
            if (classRooms && classRooms.classRooms) {
                return (
                    <>
                        <Modal
                            centered
                            key="ModalShareLesson"
                            size="lg"
                            show={this.state.showShare}
                            onHide={() => this.handleClose()}
                            backdrop="static"
                            keyboard={false}
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <i className="icon fal fa-mind-share "></i>
                                    {shareTitle}   : <span className="text-info"> {lesson.Name}</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="widget widget-category">
                                    <div className="widget-body">
                                        <div className="contact-form mb-0">
                                            <div className="w-100 d-flex justify-content-center">
                                                <div className="form-group">
                                                    <label className='text-dark font-size-h5'>
                                                        {t('Pages.Lessons.forms.ClassRoom.title')}
                                                    </label>
                                                    <Field
                                                        component="select"
                                                        title={t('Pages.Lessons.forms.ClassRoom.title')}
                                                        placeholder={t('Pages.Lessons.forms.ClassRoom.Placeholder')}
                                                        id="ClassRoomId"
                                                        name="ClassRoomId"
                                                        onChange={(e) => this.onChange(e.target.value)}
                                                        ref={ClassRoomId => this.ClassRoomId = ClassRoomId}
                                                        required={true}
                                                        className="form-control">
                                                        <option></option>
                                                        {
                                                            classRooms.classRooms.filter(p => p.Teacher && p.Teacher.Id === user.user.Id).sort((a, b) => a.Name > b.Name ? 1 : -1).map(item => {
                                                                return <option key={`ClassRoom${item.Id}`} value={item.Id}>{item.Name}</option>
                                                            })
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                            {
                                                this.state.ClassRoomId ?
                                                    <div className="w-100 d-flex justify-content-center">
                                                        <button onClick={() => this.ShowToAll(this.state.ClassRoomId, lesson.Id)} className="btn btn-info mx-1" title={t('Pages.Lessons.forms.ShowToAll')}>
                                                            <i className="icon fal fa-mind-share "></i>
                                                            <span className="d-none d-lg-inline-block">
                                                                {t('Pages.Lessons.forms.ShowToAll')}  (
                                                                {
                                                                    this.state.studentType === "all" ?
                                                                        t("Pages.StudentInClassRoomsControl.AllStudents") :
                                                                        this.state.studentType === "with_assistant" ?
                                                                            t("Pages.StudentInClassRoomsControl.NeedAssistant") :
                                                                            t("Pages.StudentInClassRoomsControl.StudentsWithoutNeedAssistant")
                                                                }
                                                                )
                                                            </span>
                                                        </button>
                                                        <button onClick={() => this.HideFromAll(this.state.ClassRoomId, lesson.Id)} className="btn btn-dark mx-1" title={t('Pages.Lessons.forms.HideFromAll')}>
                                                            <i className="icon fal fa-times "></i>
                                                            <span className="d-none d-lg-inline-block">
                                                                {t('Pages.Lessons.forms.HideFromAll')}  (
                                                                {
                                                                    this.state.studentType === "all" ?
                                                                        t("Pages.StudentInClassRoomsControl.AllStudents") :
                                                                        this.state.studentType === "with_assistant" ?
                                                                            t("Pages.StudentInClassRoomsControl.NeedAssistant") :
                                                                            t("Pages.StudentInClassRoomsControl.StudentsWithoutNeedAssistant")
                                                                }
                                                                )
                                                            </span>
                                                        </button>
                                                    </div>
                                                    : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.ClassRoomId ?
                                        <StudentInClassRoom
                                            type={this.props.type}
                                            Lesson={lesson}
                                            LessonId={lesson.Id}
                                            forShare={true}
                                            classRoomId={this.state.ClassRoomId}
                                            setSelectedStudents={this.setSelectedStudents}
                                        />
                                        : ""
                                }
                            </Modal.Body>
                        </Modal>
                    </>
                );
            } else {
                this._toastId.current = toast(t('Processing'), {
                    position: "bottom-left",
                    autoClose: false
                });
                toast.update(this._toastId.current, {
                    render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
                    autoClose: false,
                    closeOnClick: false,
                });

                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5">
                        <i className="icon fal fa-exclamation-triangle"></i>
                        {t('Error.NotClassSelected.Title')}
                    </div>
                        <div className="h6">
                            {t('Error.NotClassSelected.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
        return "";
    }

    setFavorite = id => {
        this.props.setFavorite(id);
    }

    renderSendMission() {
        if (this.state.showSendMission === true) {
            const { t, lesson, classRooms, user } = this.props;
            if (classRooms && classRooms.classRooms) {
                return <SendMissionForm handleClose={this.handleClose} lesson={lesson} user={user} classRooms={classRooms}></SendMissionForm>
            } else {
                this._toastId.current = toast(t('Processing'), {
                    position: "bottom-left",
                    autoClose: false
                });
                toast.update(this._toastId.current, {
                    render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
                    autoClose: false,
                    closeOnClick: false,
                });

                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5">
                        <i className="icon fal fa-exclamation-triangle"></i>
                        {t('Error.NotClassSelected.Title')}
                    </div>
                        <div className="h6">
                            {t('Error.NotClassSelected.Body')}
                        </div>
                    </div>,
                    type: toast.TYPE.ERROR,
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
        return "";
    }

    copyId = Id => {
        navigator.clipboard.writeText(Id);

        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        toast.update(this._toastId.current, {
            render: () => <div>
                <div className="h5">
                    <i className="icon fal fa-clipboard"></i>
                    {t('Pages.Templates.copy_to_clipboard.Title')}
                </div>
                <div className="h6">
                    {t('Pages.Templates.copy_to_clipboard.Body')}
                </div>
            </div>,
            type: toast.TYPE.INFO,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
        });
    }

    render() {
        const { t, lesson, IsAdmin, type, user, noBio, isFromHome } = this.props;

        if (lesson) {
            let isFavoriteClass = "far";
            if (lesson.isFavorite === true) {
                isFavoriteClass = "fas text-danger ";
            }
            let initialRating = 0;
            if (lesson.RateCount > 0) {
                initialRating = lesson.Stars / lesson.RateCount;
            }

            const startDate = moment(lesson.CreationDate);
            const timeEnd = moment();
            const diff = timeEnd.diff(startDate);
            const diffDuration = moment.duration(diff);
            const Days = diffDuration.asDays();
            const shareTitle = type === "Games" ? t("Pages.Games.action.share") : t("Pages.Lessons.action.share");
            return (
                <>
                    {
                        user && user.user && user.user.UserType && (user.user.UserType.Id === 3 || user.user.UserType.Id === 4) ?
                            <>
                                {this.renderShare()}
                                {this.renderSendMission()}
                            </>
                            : ""
                    }
                    {IsAdmin ? this.renderCopy() : ""}
                    <div className={`col-sm ${this.props.isSmall === true ? "" : "px-0 mx-1"}`}  >
                        <div className="product-list-item mb-3" style={{
                            marginBottom: this.props.isSmall === true ? "10px" : "",
                            minHeight: this.props.isSmall === true ? "130px" : ""
                        }}>
                            {
                                lesson.IsPublic ?
                                    <div className="ribbon-corner ribbon-fold" data-position="left top" style={{
                                        fontSize: this.props.isSmall === true ? "13px" : "",
                                        left: this.props.isSmall === true ? "15px" : "",
                                    }}> {t("Pages.Lessons.Public")} </div>
                                    : ""
                            }
                            {
                                lesson.IsActive ?
                                    Days <= 14 ?
                                        <div className="ribbon-corner ribbon-fold" data-position="right top" title={t("New")} style={{
                                            right: this.props.isSmall === true ? "15px" : "",
                                            fontSize: this.props.isSmall === true ? "13px" : ""
                                        }}>
                                            <span className="blink">
                                                {t("New")}
                                            </span>
                                        </div> : ""
                                    :
                                    <div className="ribbon-corner ribbon-fold" data-position="right top" title={t("Pages.Lessons.NotActive")}>
                                        <i className="fas fa-lock"></i>
                                    </div>
                            }
                            <div className="product-thumb" style={{
                                height: this.props.isSmall === true ? "unset" : "",
                                minHeight: this.props.isSmall === true ? "unset" : "",
                                width: this.props.isSmall === true ? "35%" : ""
                            }}>
                                {
                                    lesson.Icon ?
                                        <LazyLoadImage src={lesson.Icon} alt={`${lesson.Name}`} />
                                        :
                                        <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${lesson.Name}`} />
                                }
                                {
                                    this.props.isSmall === true ? "" :
                                        <div className="product-action-link">
                                            <div className="my-1 mr-1" onClick={() => this.openViewWindow(lesson.Id)} title={t("Pages.Lessons.action.view")}><i className="far fa-eye"></i></div>
                                            {
                                                IsAdmin ?
                                                    <>
                                                        <Link className="my-1 mr-1" to={`/ControlPanel/${!type || type === "Lessons" ? 'Lesson' : type}/Edit/${lesson.Id}`} title={t("Pages.Lessons.action.edit")}><i className="far fa-edit"></i></Link>
                                                        <div className="my-1 mr-1" onClick={() => this.props.remove(lesson.Id)} title={t("Pages.Lessons.action.remove")}><i className="far fa-trash"></i></div>
                                                        <div className="my-1 mr-1" onClick={() => this.moreInfo()} title={t("Pages.Lessons.action.info")}><i className="far fa-info"></i></div>
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            this.props.setFavorite ?
                                                                <div className="my-1 mr-1"><Link title={t("Favorite")} to="#" onClick={() => this.setFavorite(lesson.Id)}><i className={`${isFavoriteClass} fa-heart`}></i></Link></div>
                                                                : ""
                                                        }
                                                        {
                                                            !isFromHome && user && user.user && user.user.UserType && (user.user.UserType.Id === 3 || user.user.UserType.Id === 4) ?
                                                                <>
                                                                    <div className="my-1 mr-1">
                                                                        <Link title={shareTitle} to="#" onClick={() => this.showShare()}>
                                                                            <i className={`far fa-mind-share`}></i>
                                                                        </Link>
                                                                    </div>
                                                                    <div className="my-1 mr-1">
                                                                        <Link title={t("Pages.Lessons.action.sendMission")} to="#" onClick={() => this.showSendMission()}>
                                                                            <i className={`far fa-bullseye-arrow`}></i>
                                                                        </Link>
                                                                    </div>
                                                                </> : ""
                                                        }
                                                    </>
                                            }
                                        </div>
                                }
                            </div>
                            <div className="product-content py-2" style={{
                                padding: this.props.isSmall === true ? " 10px 10px 5px 5px" : "",
                                width: this.props.isSmall === true ? "65%" : ""
                            }}>
                                <h5 className="title mb-0" style={{
                                    fontSize: this.props.isSmall === true ? "18px" : ""
                                }}><Link to="#" className="text-truncate pt-2 w-100 mb-0 text-center text-md-right" title={lesson.Name} onClick={() => this.openViewWindow(lesson.Id)}>{lesson.Name}</Link></h5>
                                <div style={{
                                    fontSize: this.props.isSmall === true ? "13px" : ""
                                }}>
                                    <Rating
                                        initialRating={initialRating}
                                        direction="rtl"
                                        emptySymbol="far fa-star text-warning"
                                        fullSymbol="fas fa-star text-warning"
                                        fractions={2}
                                        readonly={true}
                                    />
                                    {
                                        this.props.isSmall === true ?
                                            <span className="mx-1" title={t("Pages.Lessons.action.ViewCount")}>
                                                (<i className="far fa-eye text-warning"></i> {lesson.ViewCount})
                                            </span>
                                            :
                                            <span className="product-view-count"> ( {lesson.RateCount} {t("Pages.Lessons.RateCount")}  , <span title={t("Pages.Lessons.action.ViewCount")}><i className="far fa-eye text-warning"></i> {lesson.ViewCount}</span> )</span>
                                    }

                                </div>
                                {
                                    this.props.isSmall === true ? "" :
                                        <h6 className="subtitle d-none d-lg-block">
                                            {this.renderPath(lesson.Index.Id)}
                                        </h6>
                                }
                                {
                                    noBio ? "" :
                                        <div className="d-flex justify-content-between align-items-center flex-wrap">
                                            <div className="px-1 ml-auto">
                                                <div className=" d-none d-lg-block">
                                                    {lesson.Bio}
                                                </div>
                                                <div>
                                                    {
                                                        lesson.TotalMark ?
                                                            <span className="ml-3" title={t('Pages.Missions.yourMark')}>
                                                                <i className="icon fal fa-tally"></i>
                                                                <span className="font-weight-bold ml-1" >
                                                                    <span className={`${(lesson.Mark / lesson.TotalMark * 100) < 50 ? "text-danger" : "text-success"} ml-1`}>
                                                                        {lesson.Mark}
                                                                    </span>
                                                                    /  {lesson.TotalMark}
                                                                </span>
                                                                (
                                                                <span className={`${(lesson.Mark / lesson.TotalMark * 100) < 50 ? "text-danger" : "text-success"} `}>
                                                                    <span className="font-weight-bold text-dark" >%</span>
                                                                    {Math.round(lesson.Mark / lesson.TotalMark * 100, 2)}
                                                                </span>

                                                                )
                                                            </span>
                                                            : ""
                                                    }
                                                    {
                                                        lesson.UserTime && lesson.UserTime.Clock ?
                                                            <span title={t('Pages.Lessons.yourTime')} style={{
                                                                direction: 'ltr'
                                                            }}>
                                                                <i className="icon fal fa-user-clock"></i>
                                                                {
                                                                    lesson.UserTime.Clock.hour ?
                                                                        <span title={t('Pages.Missions.TimeHour.title')}>
                                                                            {lesson.UserTime.Clock.hour}
                                                                        </span>
                                                                        : "00"
                                                                }
                                                                :
                                                                {
                                                                    lesson.UserTime.Clock.minutes ?
                                                                        <span title={t('Pages.Missions.TimeMinutes.title')}>
                                                                            {lesson.UserTime.Clock.minutes >= 10 ? lesson.UserTime.Clock.minutes : "0" + lesson.UserTime.Clock.minutes}
                                                                        </span>
                                                                        : "00"
                                                                }
                                                                :
                                                                {
                                                                    lesson.UserTime.Clock.seconds ?
                                                                        <span title={t('Pages.Missions.TimeSeconds.title')}>
                                                                            {lesson.UserTime.Clock.seconds >= 10 ? lesson.UserTime.Clock.seconds : "0" + lesson.UserTime.Clock.seconds}
                                                                        </span>
                                                                        : "00"
                                                                }
                                                            </span>
                                                            : ""
                                                    }
                                                </div>
                                            </div>
                                            {
                                                IsAdmin ?
                                                    <>
                                                        <Link to="#" className="cate mr-1 btn btn-light " onClick={() => this.showCopy(lesson.Id)} title={t("Pages.Lessons.tools.copy")}>
                                                            <i className="fad fa-copy text-default"></i>
                                                        </Link>
                                                        <Link to="#" className="cate mr-1 btn btn-light " onClick={() => this.copyId(lesson.Id)} title={t("Pages.Lessons.tools.copyid")}>
                                                            <i className="fad fa-clipboard text-success"></i>
                                                        </Link>
                                                    </> : ""
                                            }
                                            {
                                                lesson.Url ?
                                                    <Link to="#" className="cate mr-1 btn btn-light " onClick={() => this.openPDFViewWindow(lesson.Id)} title={t("Pages.Lessons.tools.viewPDF")}>
                                                        <i className="fad fa-file-pdf text-primary"></i>
                                                    </Link>
                                                    : ""
                                            }
                                        </div>
                                }

                            </div>
                            {
                                IsAdmin && this.state.moreInfo ?
                                    <div className=" w-100 pt-2 mt-1 mb-2 px-3 d-flex flex-wrap justify-content-around" style={{
                                        borderTop: '1px dashed #92278f'
                                    }}>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.DataEntry")}  :
                                                <div className="text-secondary">
                                                    {lesson.User.Name}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.CreationDate")}  :
                                                <div className="text-secondary">
                                                    {lesson.CreationDate}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.UpdateDate")}  :
                                                <div className="text-secondary">
                                                    {lesson.UpdateDate}
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ""
                            }
                        </div>
                    </div>
                </>
            );
        }
        return "";
    }
}

const mapStateToProps = state => {
    return {
        lessons: state.lessons,
        studentInClassRooms: state.studentInClassRooms
    };
};

export default connect(mapStateToProps, { fetchLesson, setLessonIsView, removeCurrentLessons })(withTranslation('common')(reduxForm({
    form: 'LessonItemForm'
})(LessonItem)));