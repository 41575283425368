import React from "react";
import { withTranslation } from 'react-i18next'
import { Link } from "react-router-dom";


class ClassRoomItem extends React.Component {

    state = { showStudent: false, selectedStudent: [], showAssistantStudent: false, selectAll: false, selectAllAssistant: false, isFirst: true };

    static getDerivedStateFromProps(props, state) {
        if (props.selectedStudent && state.isFirst) {

            state.isFirst = false;
            state.selectedStudent = props.selectedStudent;

            if (state.selectedStudent.length > 0) {
                var selectAll = true;
                props.classRoom.Students.forEach(student => {
                    if (!state.selectedStudent.includes(student.Id)) {
                        selectAll = false;
                    }
                });
                state.selectAll = selectAll;

                var selectAllAssistant = true;
                props.classRoom.Students.filter(p => p.NeedAssistant === true).forEach(student => {
                    if (!state.selectedStudent.includes(student.Id)) {
                        selectAllAssistant = false;
                    }
                });
                state.selectAllAssistant = selectAllAssistant;
            }
        }
        return state;
    }

    showStudent = () => {
        this.setState({
            showStudent: !this.state.showStudent
        });
    }

    showAssistantStudent = () => {
        this.setState({
            showAssistantStudent: !this.state.showAssistantStudent
        });
    }

    SelectStudent = Id => {
        const { classRoom } = this.props;
        let selectedStudent = this.state.selectedStudent;
        selectedStudent.push(Id);
        let selectAll = false;
        if (selectedStudent.length === classRoom.Students.length) {
            selectAll = true;
        }

        var selectAllAssistant = true;
        classRoom.Students.filter(p => p.NeedAssistant === true).forEach(student => {
            if (!selectedStudent.includes(student.Id)) {
                selectAllAssistant = false;
            }
        });

        this.setState({
            selectedStudent,
            selectAll,
            selectAllAssistant
        });
        this.props.setSelect(selectedStudent);
    }
    DeselectStudent = Id => {
        const { classRoom } = this.props;

        let selectedStudent = this.state.selectedStudent;
        selectedStudent = selectedStudent.filter(function (ele) {
            return ele !== Id;
        });

        var selectAllAssistant = this.state.selectAllAssistant;
        if (selectAllAssistant) {
            selectAllAssistant = !(classRoom.Students && classRoom.Students.filter(p => p.NeedAssistant === true).some(p => p.Id === Id));
        }

        this.setState({
            selectedStudent,
            selectAll: false,
            selectAllAssistant
        });
        this.props.setDeselect([Id]);
    }

    selectAll = value => {
        if (value) {
            const { classRoom } = this.props;
            let selectedStudent = [];
            classRoom.Students && classRoom.Students.forEach(student => {
                selectedStudent.push(student.Id);
            });
            this.setState({
                selectedStudent
            });
            this.props.setSelect(selectedStudent);
            this.setState({
                selectAll: true,
                selectAllAssistant: true
            });
        } else {
            this.props.setDeselect(this.state.selectedStudent);
            this.setState({
                selectedStudent: []
            });
            this.setState({
                selectAll: false,
                selectAllAssistant: false
            });
        }

    }

    selectAllAssistant = value => {

        const { classRoom } = this.props;
        let selectedStudent = [];
        classRoom.Students && classRoom.Students.filter(p => p.NeedAssistant === true).forEach(student => {
            selectedStudent.push(student.Id);
        });

        if (value) {
            this.props.setSelect(selectedStudent);
            selectedStudent = [...selectedStudent, ...this.state.selectedStudent];
            this.setState({
                selectedStudent
            });
            this.setState({
                selectAll: selectedStudent.length === classRoom.Students.length,
                selectAllAssistant: value
            });
        } else {
            this.props.setDeselect(selectedStudent);
            this.setState({
                selectedStudent: this.state.selectedStudent.filter((e) => !selectedStudent.includes(e))
            });

            this.setState({
                selectAll: false,
                selectAllAssistant: value
            });
        }

    }

    render() {
        const { t, classRoom } = this.props;
        const classRoomGroups = Object.groupBy(classRoom.Students, ({ NeedAssistant }) => NeedAssistant);
        return <li>
            <Link to="#" >
                <span>
                    {
                        this.state.selectAll ?
                            <i className="icon fal fa-check-square" title={t('Pages.Missions.deselect')} onClick={() => this.selectAll(false)}></i>
                            :
                            <i className="icon fal fa-square" title={t('Pages.Missions.select')} onClick={() => this.selectAll(true)}></i>
                    }
                    <span onClick={this.showStudent}>
                        <i className="icon fal fa-users-class" ></i> {classRoom.Name}
                    </span>
                </span>
            </Link>
            {
                this.state.showStudent ?
                    <div className="widget-body pl-0 pb-0" >
                        <ul>
                            {
                                classRoom.Students && classRoomGroups.true ?
                                    <li>
                                        <Link to="#" >
                                            <span>
                                                {
                                                    this.state.selectAllAssistant ?
                                                        <i className="icon fal fa-check-square" title={t('Pages.Missions.deselect')} onClick={() => this.selectAllAssistant(false)}></i>
                                                        :
                                                        <i className="icon fal fa-square" title={t('Pages.Missions.select')} onClick={() => this.selectAllAssistant(true)}></i>
                                                }
                                                <span onClick={this.showAssistantStudent}>
                                                    <i className="icon fal fa-chalkboard-teacher" ></i>
                                                    {t("Pages.StudentInClassRoomsControl.NeedAssistant")}
                                                </span>
                                            </span>
                                        </Link>
                                        {
                                            this.state.showAssistantStudent ? <div className="widget-body pl-0 pb-0" >
                                                <ul>
                                                    {
                                                        classRoom.Students && classRoom.Students.filter(p => p.NeedAssistant === true).map(student => {
                                                            return <li key={`student-${student.Id}`}>
                                                                <Link to="#" >
                                                                    <span onClick={() =>
                                                                        this.state.selectedStudent.includes(student.Id) ?
                                                                            this.DeselectStudent(student.Id) :
                                                                            this.SelectStudent(student.Id)
                                                                    }>
                                                                        {
                                                                            this.state.selectedStudent.includes(student.Id) ?
                                                                                <i className="icon fal fa-check-square" title={t('Pages.Missions.deselect')}></i>
                                                                                :
                                                                                <i className="icon fal fa-square" title={t('Pages.Missions.select')}></i>
                                                                        }
                                                                        <i className="icon fal fa-user-graduate" ></i> {student.Name}
                                                                    </span>
                                                                </Link>
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            </div> : ""
                                        }

                                    </li>
                                    : ""
                            }
                            {
                                classRoom.Students && classRoom.Students.filter(p => p.NeedAssistant === false).map(student => {
                                    return <li key={`student-${student.Id}`}>
                                        <Link to="#" >
                                            <span onClick={() =>
                                                this.state.selectedStudent.includes(student.Id) ?
                                                    this.DeselectStudent(student.Id) :
                                                    this.SelectStudent(student.Id)
                                            }>
                                                {
                                                    this.state.selectedStudent.includes(student.Id) ?
                                                        <i className="icon fal fa-check-square" title={t('Pages.Missions.deselect')}></i>
                                                        :
                                                        <i className="icon fal fa-square" title={t('Pages.Missions.select')}></i>
                                                }
                                                <i className="icon fal fa-user-graduate" ></i> {student.Name}
                                            </span>
                                        </Link>
                                    </li>
                                })
                            }
                        </ul>
                    </div>
                    : ""
            }
        </li>

    }

}
export default withTranslation('common')(ClassRoomItem);