import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Footer from "../Footer/Footer";
import LastNews from './LastNews';
import { fetchIndices } from '../Admin/Indices/actions/';
import { getIndeices, setIndeices, setObject, getObject } from "../../context/sessionStorage";
import {
    setLessonFavorite as setFavorite
} from './actions/';
import CarouselComponent from './Carousel/';
import "../scss/book.scss";
import RankList from "./HomeSlider/RankList";
import api from "../../api/api";
import Sky from "../Admin/Question/View/Background/Sky";
import Grass from "../Admin/Question/View/Background/Grass";
import GameObjects from "../Admin/Question/View/GameObjects";

class Home extends React.Component {

    state = {
        indices: [], positionIndex: 0,
        lessons: [], lessonsIsLoaded: false,
        Quizzes: [], QuizzesIsLoaded: false,
        Exams: [], ExamsIsLoaded: false,
        Files: [], FilesIsLoaded: false,
        Games: [], GamesIsLoaded: false,
        showMenu: false, index: 0,
        ListOfInfo: [], label: ""
    };

    constructor(props) {
        super(props);
        window.scrollToTop();
        this.pathList = [];
        this.bookList = [];
        this.timer = "";
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    static getDerivedStateFromProps(props, state) {
        const { ranks } = props;
        state.ListOfInfo = [];
        if (ranks && ranks.ranks && ranks.ranks.Point && ranks.ranks.Point.length > 0) {
            const rankItem = ranks.ranks.Point[1];
            if (rankItem) {
                state.label = rankItem.Label;
                if (rankItem.MostStudentsHavePoints && rankItem.MostStudentsHavePoints.length > 0) {
                    state.ListOfInfo.push(rankItem.MostStudentsHavePoints[0]);
                }
                if (rankItem.MostSchoolsHavePoints && rankItem.MostSchoolsHavePoints.length > 0) {
                    state.ListOfInfo.push(rankItem.MostSchoolsHavePoints[0]);
                }
                if (rankItem.MostUsersHavePoints && rankItem.MostUsersHavePoints.length > 0) {
                    state.ListOfInfo.push(rankItem.MostUsersHavePoints[0]);
                }
            }
        }
        return state;
    }

    componentDidMount() {
        let indices = getIndeices();
        if (indices === null || indices.length === 0) {
            this.props.fetchIndices();
        }

        let Lessons = getObject("LastLessons");
        if (Lessons === null || Lessons.length === 0) {
            this.getLastNews("Lessons");
        } else {
            this.setState({
                lessonsIsLoaded: true,
                lessons: Lessons
            });
        }

        let Exams = getObject("LastExams");
        if (Exams === null || Exams.length === 0) {
            this.getLastNews("Exams");
        } else {
            this.setState({
                ExamsIsLoaded: true,
                Exams: Exams
            });
        }

        let Quizzes = getObject("LastQuizzes");
        if (Quizzes === null || Quizzes.length === 0) {
            this.getLastNews("Quizzes");
        } else {
            this.setState({
                QuizzesIsLoaded: true,
                Quizzes: Quizzes
            });
        }

        let Files = getObject("LastFiles");
        if (Files === null || Files.length === 0) {
            this.getLastNews("Files");
        } else {
            this.setState({
                FilesIsLoaded: true,
                Files: Files
            });
        }

        let Games = getObject("LastGames");
        if (Games === null || Games.length === 0) {
            this.getLastNews("Games");
        } else {
            this.setState({
                GamesIsLoaded: true,
                Games: Games
            });
        }

        this.setTimer();

        this.setState({
            indices: indices
        });
    }

    setTimer = () => {
        if (this.timer) {
            clearInterval(this.timer);
        }
        this.timer = setInterval(() => {
            if (this.state.index >= this.state.ListOfInfo.length - 1) {
                this.setState({
                    index: 0
                });
            } else {
                this.setState({
                    index: this.state.index + 1
                });
            }
        }, 7 * 1000);
    }

    getLastNews = type => {
        api.post(`/LastLessons/?type=${type}`).then(response => {
            if (type === "Lessons") {
                this.setState({
                    lessons: response.data.data,
                    lessonsIsLoaded: true
                });
            } else if (type === "Exams") {
                this.setState({
                    Exams: response.data.data,
                    ExamsIsLoaded: true
                });
            } else if (type === "Quizzes") {
                this.setState({
                    Quizzes: response.data.data,
                    QuizzesIsLoaded: true
                });
            } else if (type === "Files") {
                this.setState({
                    Files: response.data.data,
                    FilesIsLoaded: true
                });
            } else if (type === "Games") {
                this.setState({
                    Games: response.data.data,
                    GamesIsLoaded: true
                });
            }
            setObject("Last" + type, response.data.data);
        });
    }

    getNodePath(nodes, indexType) {
        if (nodes) {
            nodes.forEach(node => {
                const isOpen = this.HasNode(node, indexType);
                if (isOpen) {
                    this.getNodePath(node.nodes, indexType);
                    if (indexType === node.indexType) {
                        this.pathList.push(node);
                    }
                }
            });
        }
    }

    HasNode(node, indexType) {
        if (node) {
            return (indexType === node.indexType) || this.hasSelectedNodeInChildren(node.nodes, indexType);
        }
        return false;
    }

    hasSelectedNodeInChildren(nodes, indexType) {
        var result = false;
        nodes.forEach(node => {
            result = result || (indexType === node.indexType) || this.hasSelectedNodeInChildren(node.nodes, indexType);
        });
        return result;
    }

    renderBook = () => {

        const chunkSize = 3;
        const arr = this.bookList.sort((a, b) => a.sort > b.sort ? 1 : -1);
        const groups = arr.map((e, i) => {
            return i % chunkSize === 0 ? arr.slice(i, i + chunkSize) : null;
        }).filter(e => { return e; });

        return groups.slice(0, 5).map((group, index) => {
            return <div className="d-flex justify-content-center flex-wrap" key={`bookshelf-${index}`}>
                {
                    group.map(book => {
                        return <div className="book-item float-right" key={book.id} title={book.label}>
                            <Link to={`/Tracks/BookLessons/${book.id}`} >
                                <div className="bk-img">
                                    <div className="bk-wrapper">
                                        <div className="bk-book bk-bookdefault">
                                            <div className="bk-front">
                                                <div className="bk-cover">
                                                    <LazyLoadImage className="h-100 w-100" src={book.icon ? book.icon : "/assets/images/logo/130p150.png"} />
                                                </div>
                                            </div>
                                            <div className="bk-back"></div>
                                            <div className="bk-left overflow-hidden" >
                                                <h2>{book.label}</h2>
                                                <LazyLoadImage className="float-left w-100 h-100" src={book.icon ? book.icon : "/assets/images/logo/130p150.png"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    })
                }
                <div className="shelf mb-30"> </div>
            </div>
        });
    }

    renderBookRow = () => {
        const arr = this.bookList.sort((a, b) => a.sort > b.sort ? 1 : -1);

        return arr.map(book => {
            return <Link to={`/Tracks/BookLessons/${book.id}`} key={book.id} title={book.label} className=" featured-block col-lg-3 col-md-4 col-sm-6 aos-init aos-animate" data-aos="flip-left">
                <div className="inner-box wow fadeInLeft  animated" data-wow-delay="0ms" data-wow-duration="1500ms">
                    <span className="border-one"></span>
                    <span className="border-two"></span>
                    <div className="book-item" >
                        <div className="bk-img">
                            <div className="bk-wrapper">
                                <div className="bk-book bk-bookdefault">
                                    <div className="bk-front">
                                        <div className="bk-cover">
                                            <LazyLoadImage className="h-100 w-100" src={book.icon ? book.icon : "/assets/images/logo/130p150.png"} />
                                        </div>
                                    </div>
                                    <div className="bk-back"></div>
                                    <div className="bk-left overflow-hidden" >
                                        <h2>{book.label}</h2>
                                        <LazyLoadImage className="float-left w-100 h-100" src={book.icon ? book.icon : "/assets/images/logo/130p150.png"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="feature-content">
                        <h5 className="title">{book.label}</h5>
                    </div>
                </div>
            </Link>
        })
    }

    setBookList = (books, index) => {
        books.nodes.forEach(bookClass => {
            bookClass.nodes.forEach(book => {
                this.bookList.push({
                    ...book,
                    index
                });
            });
        });
    }

    showMenu = () => {
        this.setState({
            showMenu: !this.state.showMenu
        });
    }

    setIndex = index => {
        this.setState({
            index
        });
        this.setTimer();
    }

    renderWinner = item => {
        if (item) {
            const { t } = this.props;
            const Name = item.FirstName ? (item.FirstName ? item.FirstName + " " : "") + (item.SecondName ? item.SecondName + " " : "") + (item.LastName ? item.LastName + " " : "") : item.Name;
            const count = this.state.ListOfInfo.length;
            const sex = item.Sex ? item.Sex : "female";
            return <div className="teacher-single-item winner-custom-width mb-2">
                <div className=" d-flex flex-wrap flex-column align-content-center align-items-stretch mb-0 ">
                    <div className="position-relative d-block" style={{ zIndex: 99 }}>
                        <div className="position-absolute w-100" style={{ marginTop: '-36px' }} >
                            <div className=" d-flex justify-content-around align-items-end" >
                                {
                                    count === 2 || count === 3 ?
                                        <div className="display-4" onClick={() => this.setIndex(0)}>
                                            <i className={`right-star fa-solid fa-star ${this.state.index === 0 ? "home-star-color" : "star-color"} text-warning`}></i>
                                        </div>
                                        : ""
                                }
                                {
                                    count === 1 || count === 3 ?
                                        <div className="display-4 pb-4" onClick={() => this.setIndex(count === 1 ? 0 : 1)}>
                                            <i className={`fa-solid fa-star ${this.state.index === 1 ? "home-star-color" : "star-color"}  text-warning`}></i>
                                        </div>
                                        : ""
                                }
                                {
                                    count === 2 || count === 3 ?
                                        <div className="display-4" onClick={() => this.setIndex(count === 2 ? 1 : 2)}>
                                            <i className={`left-star fa-solid fa-star ${this.state.index === 2 ? "home-star-color" : "star-color"}  text-warning`}></i>
                                        </div>
                                        : ""
                                }
                            </div>
                        </div>
                    </div>

                    <div className="teacher-single-thumb d-flex align-items-center justify-content-center m-0" title={Name} style={{
                        borderColor: '#92278f',
                        borderWidth: '5px',
                        backgroundColor: '#f7f9ee'
                    }}>
                        <LazyLoadImage src={`${item.Photo ? item.Photo : "/assets/images/logo/sub.png"}`} alt={Name} />
                    </div>
                    <div className="w-auto mt--60" style={{ zIndex: 99 }}>
                        <div className="d-flex justify-content-around align-items-center bg-white rounded-custom pt-2">
                            <div className="h4 mb-0">
                                <i className={`right-star fa-solid fa-star star-color- text-warning`}></i>
                            </div>
                            <div className="h6 mb-0 text-center">
                                <div className="h6 dark-blue-text mb-0 text-default text-truncate" title={t('Pages.Home.Month')}>
                                    {t('Pages.Home.Month')} {this.state.label}
                                </div>
                                <div className="h5 dark-blue-text text-truncate" title={t(`Pages.Home.WinnerMonthly_${sex}`)}>
                                    {t(`Pages.Home.WinnerMonthly_${sex}`)}
                                </div>
                            </div>
                            <div className="h4 mb-0">
                                <i className={`left-star fa-solid fa-star star-color- text-warning`}></i>
                            </div>
                        </div>
                        <div className="text-center mx-3">
                            <div className={`bg-default p-3 h4 mb-0 rounded-custom `}>
                                <div className="text-center">
                                    <div className={`h5 text-truncate mb-0 pb-1`} title={Name}>
                                        {Name}
                                    </div>
                                </div>
                            </div>
                            {
                                item.SchoolId !== 1 && item.SchoolName ?
                                    <span className="d-flex justify-content-center mb-0" style={{ marginTop: '-15px', height: '35px' }}>
                                        <div className="py-2 px-2 rounded-custom h6 mb-0 text-truncate" title={item.SchoolName} style={{ background: '#4c258b' }}>
                                            {item.SchoolName}
                                        </div>
                                    </span>
                                    :
                                    item.SchoolId === 1 ?
                                        <span className="d-flex justify-content-center mb-0" style={{ marginTop: '-15px', height: '35px' }}>
                                            <div className="py-2 px-2 rounded-custom h6 mb-0 text-truncate" title={t('Pages.Home.Count.Users')} style={{ background: '#4c258b' }}>
                                                {t('Pages.Home.Count.Users')}
                                            </div>
                                        </span>
                                        :
                                        <span className="d-flex justify-content-center mb-0" style={{ marginTop: '-15px', height: '35px' }}>
                                            <div className="py-2 px-2 rounded-custom h6 mb-0 text-truncate" title={t('Pages.Home.Count.School')} style={{ background: '#4c258b' }}>
                                                {t('Pages.Home.Count.School')}
                                            </div>
                                        </span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        }
        return "";
    }

    renderWinners = () => {
        if (this.state.ListOfInfo.length > 0 && this.state.ListOfInfo.length > this.state.index) {
            return this.renderWinner(this.state.ListOfInfo[this.state.index]);
        }
        return "";
    }

    render() {
        const { t, user } = this.props;

        let indices = this.props.indices;
        if (indices) {
            setIndeices(indices);
        } else {
            indices = this.state.indices;
        }

        this.bookList = [];
        this.pathList = [];
        if (indices && indices.nodes) {
            this.getNodePath(indices.nodes, "Books");
        }

        this.pathList.forEach((books, index) => {
            this.setBookList(books, index)
        });

        return (
            <>
                <Helmet>
                    <title>{t("Home")} | {t('alarabeyya')}</title>
                </Helmet>
                <div className="position-sticky">
                    <section className="banner-section style-2  oh" >
                        <div className="top-shape-content">
                            <LazyLoadImage src="/assets/images/banner/banner04.png" alt={`${t('alarabeyya')}`} />
                        </div>
                        <div className="mx-5">
                            <div className={`d-flex justify-content-between banner-container position-relative align-items-center `}>

                                <div className="banner-content style-2 cl-white w-100 d-flex justify-content-end">
                                    {
                                        this.state.ListOfInfo.length > 0 ?
                                            this.renderWinners() :
                                            <>
                                                <h1 className="title"> {t('PageHeader.head')}</h1>
                                                <p>{t('PageHeader.Short')}</p>
                                            </>
                                    }
                                    <div className={`banner-buttons`}>
                                        {
                                            user && user.isLogin === true ?
                                                <>
                                                    <Link to="/Profile" className="custom-button mx-1" title={t('Pages.Profile.title')}>
                                                        <span>
                                                            <i className="icon fal fa-user"></i>
                                                            {t('Pages.Profile.title')}
                                                        </span>
                                                    </Link>
                                                    <Link to="/Logout" className="custom-button my-2 mx-1" title={t('Logout')}>
                                                        <span>
                                                            <i className="icon fal fa-power-off"></i>
                                                            {t('Logout')}
                                                        </span>
                                                    </Link>
                                                </>
                                                :
                                                <>
                                                    <Link to="/Register" className="custom-button mx-1">
                                                        <span>
                                                            <i className="icon fal fa-registered"></i>
                                                            {t('Register_Now')}
                                                        </span>
                                                    </Link>
                                                    <Link to="/Login" className="custom-button my-2 mx-1">
                                                        <span>
                                                            <i className="icon fal fa-sign-in-alt"></i>
                                                            {t('Login')}
                                                        </span>
                                                    </Link>
                                                </>
                                        }

                                    </div>
                                </div>
                                <div className="banner-content p-0 oh cl-white mr-auto d-flex align-items-center" style={{
                                    zIndex: 100
                                }}>
                                    <CarouselComponent />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className=" pos-rel last-news-section oh pt-5 " style={{
                        background: 'linear-gradient(180deg, rgba(57,179,246,1) 0%, rgba(60,197,250,1) 100%)'
                    }}>
                        <Sky absolute={true} />

                        <div className="row mx-2 mb-3">
                            <LastNews
                                hasOffset={true}
                                background='#fffee8'
                                typeToFacorite="Lessons"
                                setFavorite={this.props.setFavorite}
                                Items={this.state.lessons}
                                isLoaded={this.state.lessonsIsLoaded}
                                indices={indices}
                                user={user}
                                showMenu={this.showMenu}
                                title={` <i class="fas fa-layer-group"></i> ` + t("Pages.Home.Last.Lessons")} />

                            <LastNews
                                background='#f8f4ff'
                                typeToFacorite="Files"
                                setFavorite={this.props.setFavorite}
                                indices={indices}
                                Items={this.state.Files}
                                isLoaded={this.state.FilesIsLoaded}
                                showMenu={this.showMenu}
                                user={user}
                                type="Files"
                                title={` <i class="fas fa-paperclip"></i> ` + t("Pages.Home.Last.Files")} />
                            <LastNews
                                hasOffset={true}
                                typeToFacorite="Exams"
                                type="Exams"
                                background='#fff5fd'
                                setFavorite={this.props.setFavorite}
                                Items={this.state.Exams}
                                isLoaded={this.state.ExamsIsLoaded}
                                showMenu={this.showMenu}
                                user={user}
                                indices={indices}
                                title={` <i class="fas fa-pencil-alt"></i> ` + t("Pages.Home.Last.Exams")} />

                            <LastNews
                                typeToFacorite="Games"
                                type="Games"
                                background='#d8e5fb'
                                setFavorite={this.props.setFavorite}
                                Items={this.state.Games}
                                isLoaded={this.state.GamesIsLoaded}
                                showMenu={this.showMenu}
                                user={user}
                                indices={indices}
                                title={` <i class="fas fa-gamepad-alt"></i> ` + t("Pages.Home.Last.Games")} />


                            <LastNews
                                isCenter={true}
                                typeToFacorite="Quizzes"
                                type="Exams"
                                background='#f2ffeb'
                                setFavorite={this.props.setFavorite}
                                Items={this.state.Quizzes}
                                isLoaded={this.state.QuizzesIsLoaded}
                                user={user}
                                showMenu={this.showMenu}
                                indices={indices}
                                title={` <i class="fas fa-question"></i> ` + t("Pages.Home.Last.Quizzes")} />
                        </div>

                    </section>
                </div>
                <RankList user={user} />

                <section className="pos-rel padding-bottom padding-top oh" style={{
                    backgroundColor: '#8ab412',
                    backgroundImage: `url(${process.env.PUBLIC_URL + "/assets/images/Grass/bg.png"})`,
                    backgroundPositionX: 'center',
                    backgroundPositionY: 'top',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    top: '0'
                }}>
                    <Grass absolute={true} />
                    <div className="row offset-xl-2 col-xl-10 col-lg-12">
                        <div className="col-12">
                            <div className="row justify-content-end mt-5 mb-3">
                                <div className="col-lg-10 text-center">
                                    <h3 className="title display-4 text-white">
                                        {t("Pages.Home.Publish")}
                                    </h3>
                                </div>
                            </div>
                            <div className="row justify-content-end mt-5 mb-3">
                                <div className="col-lg-10">
                                    {this.renderBookRow()}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-xs-12 mb-3 float-right d-none">
                            <div className="widget widget-cate h4gory" style={{
                                background: "#8dc53e"
                            }}>
                                <div className="widget-header">
                                    <h5 className="title " style={{
                                        color: "#fff"
                                    }}>
                                        <i className="icon fal fa-books"></i>
                                        {t("Pages.Home.Publish")}
                                    </h5>
                                </div>
                                <div className="widget-body" style={{
                                    background: '#e4f4fd'
                                }} >
                                    {this.renderBook()}
                                </div>
                                <div className="tags-area p-0" >
                                    <div className="d-flex justify-content-center w-100 publish-continer">
                                        <div className="theme-btn btn-style-two d-inline-flex justify-content-center w-100 show-more publish" style={{
                                            background: "#8dc53e"
                                        }}>
                                            <span className="txt pl-2">
                                                {
                                                    !this.state.showAll ?
                                                        t('showAll') :
                                                        <>
                                                            <i className="icon fal fa-window-close"></i>  {t('hideAll')}
                                                        </>
                                                }
                                            </span>
                                            {
                                                !this.state.showAll ?
                                                    <div className="mouse-btn-down">
                                                        <div className="chevron"></div>
                                                        <div className="chevron"></div>
                                                        <div className="chevron"></div>
                                                    </div>
                                                    : ""
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="position-fixed d-none" style={{
                    bottom: '75px',
                    right: '10px',
                    zIndex: 999
                }}>
                    <Link to="/CompetitionInfo" title={t('Pages.Competition.Title')} >
                        <GameObjects objectCss="CompetitionLogo" />
                    </Link>
                </div>
                <div className="position-sticky">
                    <Footer />
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        indices: state.indices,
        ranks: state.ranks,
        user: state.user
    };
};

export default connect(mapStateToProps, { setFavorite, fetchIndices })(withTranslation('common')(Home));