import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { Field, reduxForm } from 'redux-form';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import Rating from "react-rating";
import moment from "moment";

import StudentInClassRoom from '../../School/StudentInClassRooms/';
import api, { apiBlob } from '../../../api/api';
import { ShareFileBySocket, notificationIsView } from '../../../api/socket.io';
import history from '../../../history';
import Rate from "../../Home/Tracks/Rate";
import { setFileIsView } from './actions';

class ListItem extends React.Component {

    state = {
        moreInfo: false,
        showDoneCopy: false,
        pathList: [],
        showRate: false,
        hideRate: false,
        studentType: "all"
    };

    constructor(props) {
        super(props);
        this.pathList = [];
        this._toastId = new React.createRef();
    }

    showShare = () => {
        this.props.initialize({
            ClassRoomId: "",
        });
        this.setState({
            showShare: true,
            ClassRoomId: ""
        });
    }

    moreInfo = () => {
        this.setState({
            moreInfo: !this.state.moreInfo
        });
    }

    setIndex(node) {
        if (this.props.setIndex) {
            this.props.setIndex(node);
        } else {
            this.pathList = [];
            this.getNodePath(this.props.indices, this.props.item.Index.Id, 0);
            var list = this.pathList.reverse();
            history.push(`/Files/${list[0].id}/${node.id}`);
        }
    }

    renderPath(currentId) {
        this.pathList = [];
        this.getNodePath(this.props.indices, currentId, 1);
        if (this.pathList) {
            const length = this.pathList.length;
            return this.pathList.reverse().map((node, index) => {
                return (
                    <span
                        key={node.id}>
                        <Link
                            onClick={() => this.setIndex(node)}
                            to="#">
                            {node.label}
                        </Link>
                        {(length !== index + 1) ? <i className="fas fa-arrow-left text-info m-2"></i> : ""}
                    </span>
                );
            });
        }
        return "";
    }

    getNodePath(nodes, currentId, Level) {
        if (nodes) {
            nodes.forEach(node => {
                const isOpen = this.HasNode(node, currentId);
                if (isOpen) {
                    this.getNodePath(node.nodes, currentId, Level);
                    if (node.Level > Level) {
                        this.pathList.push(node);
                    }
                }
            });
        }
    }

    HasNode(node, currentId) {
        if (node) {
            return (currentId === node.id) || this.hasSelectedNodeInChildren(node.nodes, currentId);
        }
        return false;
    }

    hasSelectedNodeInChildren(nodes, currentId) {
        var result = false;
        nodes.forEach(node => {
            result = result || (currentId === node.id) || this.hasSelectedNodeInChildren(node.nodes, currentId);
        });
        return result;
    }

    openViewWindow = lesson => {
        if (lesson) {
            if (lesson.Type === "Lessons") {
                window.openLessonView(lesson.Id);
            } else {
                window.openExamView(lesson.Id);
            }
        }
    }

    openLessonListView = () => {
        this.setState({ show: true });
    }

    handleClose = () => {
        this.setState({ show: false });
    }

    setFavorite = id => {
        this.props.setFavorite(id);
    }

    setSelectedStudents = studentType => {
        this.setState({
            studentType
        })
    }

    renderShare() {
        if (this.state.showShare === true) {
            const { t, item, classRooms, user } = this.props;
            if (classRooms && classRooms.classRooms) {
                return (
                    <>
                        <Modal
                            centered
                            key="ModalShareFile"
                            size="lg"
                            show={this.state.showShare}
                            onHide={() => this.handleClose()}
                            backdrop="static"
                            keyboard={false}
                            enforceFocus={false} >
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <i className="icon fal fa-mind-share "></i>
                                    {t('Pages.Files.action.share')}   : <span className="text-info"> {item.Name}</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="widget widget-category">
                                    <div className="widget-body">
                                        <div className="contact-form mb-0">
                                            <div className="w-100 d-flex justify-content-center">
                                                <div className="form-group">
                                                    <label className='text-dark font-size-h5'>
                                                        {t('Pages.Lessons.forms.ClassRoom.title')}
                                                    </label>
                                                    <Field
                                                        component="select"
                                                        title={t('Pages.Lessons.forms.ClassRoom.title')}
                                                        placeholder={t('Pages.Lessons.forms.ClassRoom.Placeholder')}
                                                        id="ClassRoomId"
                                                        name="ClassRoomId"
                                                        onChange={(e) => this.onChange(e.target.value)}
                                                        ref={ClassRoomId => this.ClassRoomId = ClassRoomId}
                                                        required={true}
                                                        className="form-control">
                                                        <option></option>
                                                        {
                                                            classRooms.classRooms.filter(p => p.Teacher && p.Teacher.Id === user.user.Id).sort((a, b) => a.Name > b.Name ? 1 : -1).map(item => {
                                                                return <option key={`ClassRoom${item.Id}`} value={item.Id}>{item.Name}</option>
                                                            })
                                                        }
                                                    </Field>
                                                </div>
                                            </div>
                                            {
                                                this.state.ClassRoomId ?
                                                    <div className="w-100 d-flex justify-content-center">
                                                        <button onClick={() => this.ShowToAll(this.state.ClassRoomId, item.Id)} className="btn btn-info mx-1 col-4" title={t('Pages.Lessons.forms.ShowToAll')}>
                                                            <i className="icon fal fa-mind-share "></i>
                                                            <span className="d-none d-lg-inline-block">
                                                                {t('Pages.Lessons.forms.ShowToAll')} (
                                                                {
                                                                    this.state.studentType === "all" ?
                                                                        t("Pages.StudentInClassRoomsControl.AllStudents") :
                                                                        this.state.studentType === "with_assistant" ?
                                                                            t("Pages.StudentInClassRoomsControl.NeedAssistant") :
                                                                            t("Pages.StudentInClassRoomsControl.StudentsWithoutNeedAssistant")
                                                                }
                                                                )
                                                            </span>
                                                        </button>
                                                        <button onClick={() => this.HideFromAll(this.state.ClassRoomId, item.Id)} className="btn btn-dark mx-1 col-4" title={t('Pages.Lessons.forms.HideFromAll')}>
                                                            <i className="icon fal fa-times "></i>
                                                            <span className="d-none d-lg-inline-block">
                                                                {t('Pages.Lessons.forms.HideFromAll')}  (
                                                                {
                                                                    this.state.studentType === "all" ?
                                                                        t("Pages.StudentInClassRoomsControl.AllStudents") :
                                                                        this.state.studentType === "with_assistant" ?
                                                                            t("Pages.StudentInClassRoomsControl.NeedAssistant") :
                                                                            t("Pages.StudentInClassRoomsControl.StudentsWithoutNeedAssistant")
                                                                }
                                                                )
                                                            </span>
                                                        </button>
                                                    </div>
                                                    : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.ClassRoomId ?
                                        <StudentInClassRoom
                                            File={item}
                                            FileId={item.Id}
                                            forShare={true}
                                            classRoomId={this.state.ClassRoomId}
                                            setSelectedStudents={this.setSelectedStudents}
                                        />
                                        : ""
                                }
                            </Modal.Body>
                        </Modal>
                    </>
                );
            }
        }
        return "";
    }

    handleClose = () => {
        this.setState({
            showShare: false,
            show: false
        });
    }

    onChange = ClassRoomId => {
        this.setState({ ClassRoomId });
    }

    ShowToAll = (ClassRoomId, FileId) => {
        const { t, item } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.post(`/EducationalFilesPermissions/${ClassRoomId}`, {
            isAdd: true,
            FileId,
            StudentType: this.state.studentType
        }
        ).then(response => {
            if (response.data.code === "success") {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-mind-share"></i> {t('Success.FilePermissionShow.Title')}</div> <div className="h6">{t('Success.FilePermissionShow.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
                this.props.getStudent(ClassRoomId);

                if (this.props.studentInClassRooms && this.props.studentInClassRooms.studentInClassRooms) {
                    let studentList = this.props.studentInClassRooms.studentInClassRooms.map(p => p.Id);
                    if (studentList) {
                        ShareFileBySocket(studentList, item);
                    }
                }
            } else {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Error.LessonPermissions.Title')}</div> <div className="h6">{t('Error.LessonPermissions.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }
    HideFromAll = (ClassRoomId, FileId) => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.post(`/EducationalFilesPermissions/${ClassRoomId}`, {
            isAdd: false,
            FileId,
            StudentType: this.state.studentType
        }
        ).then(response => {
            if (response.data.code === "success") {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-mind-share"></i> {t('Success.FilePermissionHide.Title')}</div> <div className="h6">{t('Success.FilePermissionHide.Body')}</div></div>,
                    type: toast.TYPE.SUCCESS,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
                this.props.getStudent(ClassRoomId);
            } else {
                toast.update(this._toastId.current, {
                    render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Error.LessonPermissions.Title')}</div> <div className="h6">{t('Error.LessonPermissions.Body')}</div></div>,
                    type: toast.TYPE.ERROR,
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }
    download = token => {
        const { t, item, user } = this.props;

        if (user && user.user) {

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });
            toast.update(_toastId, {
                render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
                autoClose: false,
                closeOnClick: false,
            });

            apiBlob.post(`/EducationalFiles/?token=${token}`).then(response => {
                if (response) {
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style.display = "none";
                    const blob = new Blob([response.data]),
                        url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = item.Name + '.' + item.extension;
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
                toast.dismiss(_toastId);
                this.showRate();
                if (item.IsView === false && user.user.UserType.Id === 5) {
                    notificationIsView("FILE", user.user.Id);
                    this.props.setFileIsView(item.Id);
                }
            }).catch(error => {
                toast.dismiss(_toastId);
                history.push("/NoPermissions");
            });
        } else {
            history.push("/Login");
        }
    }

    showRate = () => {
        this.setState({
            showRate: !this.state.showRate
        });
    }

    hideRate = () => {
        this.setState({
            showRate: false,
            hideRate: true
        });
    }

    openPDFViewWindow = Id => {
        const { item, user } = this.props;
        if ((user && user.user) || item.IsPublic) {
            window.openFilePDFViewWindow(Id);
        } else {
            history.push("/Login");
        }
    }

    copyId = Id => {
        navigator.clipboard.writeText(Id);

        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        toast.update(this._toastId.current, {
            render: () => <div>
                <div className="h5">
                    <i className="icon fal fa-clipboard"></i>
                    {t('Pages.Templates.copy_to_clipboard.Title')}
                </div>
                <div className="h6">
                    {t('Pages.Templates.copy_to_clipboard.Body')}
                </div>
            </div>,
            type: toast.TYPE.INFO,
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
        });
    }

    render() {
        const { t, item, IsAdmin, user, noBio, isFromHome } = this.props;
        if (item) {
            let isFavoriteClass = "far";
            if (item.isFavorite === true) {
                isFavoriteClass = "fas text-danger ";
            }
            let initialRating = 0;
            if (item.RateCount > 0) {
                initialRating = item.Stars / item.RateCount;
            }

            const startDate = moment(item.CreationDate);
            const timeEnd = moment();
            const diff = timeEnd.diff(startDate);
            const diffDuration = moment.duration(diff);
            const Days = diffDuration.asDays();

            return (
                <>
                    {!this.state.hideRate && !item.isRated && this.state.showRate && user && user.user && user.user.UserType && user.user.UserType.Id !== 5 ?
                        <Rate
                            id={item.Id}
                            IsForLesson={false}
                            hideRate={this.hideRate}
                            showRate={this.showRate}>
                        </Rate>
                        : ""
                    }
                    {user && user.user && user.user.UserType && (user.user.UserType.Id === 3 || user.user.UserType.Id === 4) ? this.renderShare() : ""}
                    {
                        this.state.show ?
                            <Modal
                                size="md"
                                centered
                                show={this.state.show}
                                onHide={this.handleClose}
                                enforceFocus={false}
                                keyboard={false}>
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <div className="h5 mb-0">
                                            <i className="fad fa-layer-group text-primary icon"></i> {t("Pages.Lessons.tools.viewLessons")}
                                        </div>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="widget widget-category">
                                        <div className="widget-body">
                                            <ul>
                                                {
                                                    item.Lessons && item.Lessons.length > 1 ?
                                                        item.Lessons.map(lesson =>
                                                            <li key={`lessonId-${lesson.Id}`}>
                                                                <Link to="#" onClick={() => ((user && user.user) || lesson.IsPublic) ? this.openViewWindow(lesson) : history.push("/Login")}>
                                                                    <div>
                                                                        {lesson.Name}
                                                                    </div>
                                                                    <div className="cate btn btn-light" title={`${lesson.Type === "Lessons" ? t("Pages.Lessons.tools.view") : t("Pages.Lessons.tools.viewExam")} (${lesson.Name}) `}>

                                                                        <i className={`fad fa-eye ${lesson.Type === "Lessons" ? "text-primary" : "text-default"}`}></i>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        )
                                                        : ""
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                            : ""
                    }
                    <div className={`col-sm ${this.props.isSmall === true ? "" : "px-0 mx-1"}`}  >
                        <div className="product-list-item mb-3" style={{
                            minHeight: this.props.isSmall === true ? "130px" : "",
                            marginBottom: this.props.isSmall === true ? "10px" : "",
                        }}>
                            {
                                item.IsPublic ?
                                    <div className="ribbon-corner ribbon-fold" data-position="left top" style={{
                                        left: this.props.isSmall === true ? "15px" : "",
                                        fontSize: this.props.isSmall === true ? "13px" : ""
                                    }}> {t("Pages.Lessons.Public")} </div>
                                    :
                                    item.IsForTeacher ?
                                        <div className="ribbon-corner ribbon-fold" data-position="right top" title={t("Pages.Files.forms.FilesInfo.IsForTeacher.title")} style={{
                                            right: this.props.isSmall === true ? "15px" : "",
                                            fontSize: this.props.isSmall === true ? "13px" : ""
                                        }}>
                                            <div className="mt-1"> {t("Pages.Files.forms.FilesInfo.IsForTeacher.sub")} </div>
                                        </div> : ""
                            }
                            {
                                Days <= 14 ?
                                    <div className="ribbon-corner ribbon-fold" data-position="right top" title={t("New")} style={{
                                        right: this.props.isSmall === true ? "15px" : "",
                                        fontSize: this.props.isSmall === true ? "13px" : ""
                                    }}>
                                        <span className="blink">
                                            {t("New")}
                                        </span>
                                    </div> : ""
                            }
                            <div className="product-thumb" style={{
                                width: this.props.isSmall === true ? "35%" : "",
                                height: this.props.isSmall === true ? "unset" : "",
                                minHeight: this.props.isSmall === true ? "unset" : "",
                            }}>
                                {
                                    item.Icon ?
                                        <LazyLoadImage src={item.Icon} alt={`${item.Name}`} />
                                        :
                                        <LazyLoadImage src="/assets/images/logo/130p150.png" alt={`${item.Name}`} />
                                }
                                {
                                    this.props.isSmall === true ? "" :
                                        <div className="product-action-link">
                                            <div className="my-1" onClick={() => this.download(item.token)} title={t("Pages.Files.action.download")}><i className="far fa-download"></i></div>
                                            {
                                                IsAdmin ?
                                                    <>
                                                        <div className="my-1" onClick={() => this.props.edit(item.Id)} title={t("Pages.Lessons.action.edit")}><i className="far fa-edit"></i></div>
                                                        <div className="my-1 mr-1" onClick={() => this.props.remove(item.Id)} title={t("Pages.Lessons.action.remove")}><i className="far fa-trash"></i></div>
                                                        <div className="my-1 mr-1" onClick={() => this.moreInfo()} title={t("Pages.Lessons.action.info")}><i className="far fa-info"></i></div>
                                                    </>
                                                    :
                                                    <>
                                                        {
                                                            this.props.setFavorite ?
                                                                <div className="my-1 mr-1"><Link title={t("Favorite")} to="#" onClick={() => this.setFavorite(item.Id)}><i className={`${isFavoriteClass} fa-heart`}></i></Link></div>
                                                                : ""
                                                        }
                                                        {
                                                            !isFromHome && user && user.user && user.user.UserType && (user.user.UserType.Id === 3 || user.user.UserType.Id === 4) ?
                                                                <>
                                                                    <div className="my-1 mr-1">
                                                                        <Link title={t("Pages.Files.action.share")} to="#" onClick={() => this.showShare()}>
                                                                            <i className={`far fa-mind-share`}></i>
                                                                        </Link>
                                                                    </div>
                                                                </> : ""
                                                        }
                                                    </>
                                            }
                                        </div>
                                }
                            </div>
                            <div className="product-content py-2" style={{
                                width: this.props.isSmall === true ? "65%" : "",
                                padding: this.props.isSmall === true ? " 10px 10px 5px 5px" : "",
                            }}>
                                <h5 className="title mb-0" style={{
                                    fontSize: this.props.isSmall === true ? "18px" : ""
                                }}><Link to="#" className="text-truncate pt-2 w-100 mb-0 text-center text-md-right" title={item.Name} onClick={() => this.download(item.token)}> {item.Name} </Link></h5>
                                <div style={{
                                    fontSize: this.props.isSmall === true ? "13px" : ""
                                }}>
                                    <Rating
                                        initialRating={initialRating}
                                        direction="rtl"
                                        emptySymbol="far fa-star text-warning"
                                        fullSymbol="fa-solid fa-star text-warning"
                                        fractions={2}
                                        readonly={true}
                                    />
                                    {
                                        this.props.isSmall === true ?
                                            <span className="mx-1" title={t("Pages.Files.action.ViewCount")}>
                                                (<i className="far fa-download text-warning"></i> {item.ViewCount})
                                            </span>
                                            :
                                            <span className="product-view-count"> ( {item.RateCount} {t("Pages.Files.RateCount")} , <span title={t("Pages.Files.action.ViewCount")}><i className="far fa-download text-warning"></i> {item.ViewCount}</span> )</span>
                                    }

                                </div>
                                {
                                    this.props.isSmall === true ? "" :
                                        <h6 className="subtitle d-none d-lg-block">
                                            {this.renderPath(item.Index.Id)}
                                        </h6>
                                }
                                {
                                    noBio ? "" :
                                        <div className="d-flex justify-content-between align-items-center">
                                            <p className="px-1 ml-auto d-none d-lg-block">
                                                {item.Bio}
                                            </p>
                                            {
                                                item.Lessons && item.Lessons.length > 0 ?
                                                    item.Lessons.length === 1 ?
                                                        <div className="d-flex align-items-center mr-1">
                                                            <Link to="#" className="cate btn btn-light " onClick={() => ((user && user.user) || item.Lessons[0].IsPublic) ? this.openViewWindow(item.Lessons[0]) : history.push("/Login")} title={`${t("Pages.Lessons.tools.view")} (${item.Lessons[0].Name}) `}>
                                                                <i className="fad fa-eye text-default"></i>
                                                            </Link>
                                                        </div>
                                                        :
                                                        <div className="d-flex align-items-center mr-1">
                                                            <Link to="#" className="cate btn btn-light " onClick={() => this.openLessonListView()} title={t("Pages.Lessons.tools.viewLessons")}>
                                                                <i className="fad fa-layer-group text-default"></i>
                                                            </Link>
                                                        </div>
                                                    : ""
                                            }
                                            {
                                                IsAdmin ?
                                                    <>
                                                        <Link to="#" className="cate mr-1 btn btn-light " onClick={() => this.copyId(item.Id)} title={t("Pages.Lessons.tools.copyid")}>
                                                            <i className="fad fa-clipboard text-success"></i>
                                                        </Link>
                                                    </> : ""
                                            }
                                            {
                                                item.extension === '.pdf' ?
                                                    <Link to="#" className="cate btn btn-light mr-1" onClick={() => this.openPDFViewWindow(item.Id)} title={t("Pages.Lessons.tools.viewPDF")}>
                                                        <i className="fad fa-file-pdf text-primary"></i>
                                                    </Link>
                                                    : ""
                                            }
                                        </div>
                                }
                            </div>
                            {
                                IsAdmin && this.state.moreInfo ?
                                    <div className=" w-100 pt-2 mt-1 mb-2 px-3 d-flex flex-wrap justify-content-around" style={{
                                        borderTop: '1px dashed #92278f'
                                    }}>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.DataEntry")}  :
                                                <div className="text-secondary">
                                                    {item.User.Name}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.CreationDate")}  :
                                                <div className="text-secondary">
                                                    {item.CreationDate}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <div className="title">
                                                {t("Pages.Lessons.UpdateDate")}  :
                                                <div className="text-secondary">
                                                    {item.UpdateDate}
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ""
                            }
                        </div>
                    </div>
                </>
            );
        }
        return "";
    }
}

const mapStateToProps = state => {
    return {
        studentInClassRooms: state.studentInClassRooms
    };
};

export default connect(mapStateToProps, { setFileIsView })(withTranslation('common')(reduxForm({
    form: 'ListItemForm'
})(ListItem)));