import React from "react";
import { withTranslation } from 'react-i18next'
import { Field, reduxForm, getFormValues } from "redux-form";
import { connect } from 'react-redux';
import { toast } from "react-toastify";

class SchoolForm extends React.Component {

    renderField = ({
        input,
        title,
        type,
        placeholder,
        required,
        className,
        style,
        meta: { touched, error }
    }) => (
        <>
            <input {...input} title={title} placeholder={placeholder} style={style} required={required} type={type} autoComplete="off" spellCheck={false} className={`${className} ${(touched && error) ? 'is-invalid' : ''}`} />
            {touched && error && <div className="invalid-feedback">{this.props.t(error)}</div>}
        </>
    );

    checkSubmit = () => {
        const { t } = this.props;

        let _toastId = toast(t('Processing.Title'), {
            position: "bottom-left",
            autoClose: false
        });

        toast.update(_toastId, {
            render: () => <div>
                <div className="h5">
                    <i className="icon fal fa-bug"></i> {t('Pages.Register.error_AllInformationIsRequired_toast.Title')}
                </div>
                <div className="h6">
                    {t('Pages.Register.error_AllInformationIsRequired_toast.Body')}
                </div>
            </div>,
            type: toast.TYPE.ERROR,
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    onSubmit = () => {
        this.props.onSubmit({
            ...this.props.formValues
        });
    }

    render() {
        const { t, pristine, submitting, handleSubmit } = this.props;
        return (
            <>
                <div className="contact-form-area">

                    <form method="POST" name="SchoolForm" className="contact-form" onSubmit={
                        handleSubmit(values => {
                            return new Promise(resolve => resolve())
                                .then(() => {
                                    const errors = validate(values, this.props);
                                    return Object.keys(errors).length !== 0;
                                })
                                .then(errors => {
                                    if (errors) {
                                        this.checkSubmit();
                                    } else {
                                        this.onSubmit();
                                    }
                                })
                        })
                    } autoComplete="new-password">

                        <div className="form-group">
                            <label className='text-dark font-size-h5'>{t('Pages.Register.SchoolName.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                            <div>
                                <Field
                                    ref={SchoolName => this.SchoolName = SchoolName}
                                    name="SchoolName"
                                    component={this.renderField}
                                    type="text"
                                    required={true}
                                    placeholder={t('Pages.Register.SchoolName.Placeholder')}
                                    title={t('Pages.Register.SchoolName.title')}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className='text-dark font-size-h5'>{t('Pages.Register.SchoolAddress.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                            <div>
                                <Field
                                    ref={SchoolAddress => this.SchoolAddress = SchoolAddress}
                                    name="SchoolAddress"
                                    component={this.renderField}
                                    type="text"
                                    required={true}
                                    placeholder={t('Pages.Register.SchoolAddress.Placeholder')}
                                    title={t('Pages.Register.SchoolAddress.title')}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className='text-dark font-size-h5'>{t('Pages.Register.SchoolCode.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                            <div>
                                <Field
                                    ref={SchoolCode => this.SchoolCode = SchoolCode}
                                    name="SchoolCode"
                                    component={this.renderField}
                                    type="text"
                                    required={true}
                                    placeholder={t('Pages.Register.SchoolCode.Placeholder')}
                                    title={t('Pages.Register.SchoolCode.title')}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className='text-dark font-size-h5'>{t('Pages.Register.SchoolEmail.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                            <div>
                                <Field
                                    ref={SchoolEmail => this.SchoolEmail = SchoolEmail}
                                    name="SchoolEmail"
                                    component={this.renderField}
                                    type="email"
                                    required={true}
                                    placeholder={t('Pages.Register.SchoolEmail.Placeholder')}
                                    title={t('Pages.Register.SchoolEmail.title')}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className='text-dark font-size-h5'>{t('Pages.Register.SchoolPhone.title')} <i title={t('required')} className="fas fa-star-of-life font-size-10 text-danger"> </i></label>
                            <div>
                                <Field
                                    ref={SchoolPhone => this.SchoolPhone = SchoolPhone}
                                    name="SchoolPhone"
                                    component={this.renderField}
                                    type="text"
                                    required={true}
                                    placeholder={t('Pages.Register.SchoolPhone.Placeholder')}
                                    title={t('Pages.Register.SchoolPhone.title')}
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="form-group w-100 text-center d-flex justify-content-around align-items-center">

                            <button className={`custom-button ${(pristine || submitting) ? "secondary-button" : "success-button"}  `} disabled={(pristine || submitting)} >
                                <span className="d-flex justify-content-center align-items-center">
                                    {t('Pages.Register.Send')}
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        formValues: getFormValues('SchoolForm')(state)
    };
};

const validate = values => {
    const errors = {};

    if (!values.SchoolName) {
        errors.SchoolName = 'Pages.Register.SchoolName.error';
    }

    if (!values.SchoolAddress) {
        errors.SchoolAddress = 'Pages.Register.SchoolAddress.error';
    }

    if (!values.SchoolPhone) {
        errors.SchoolPhone = 'Pages.Register.SchoolPhone.error';
    }

    if (!values.SchoolCode) {
        errors.SchoolCode = 'Pages.Register.SchoolCode.error';
    }

    if (!values.SchoolEmail) {
        errors.SchoolEmail = 'Pages.Register.SchoolEmail.error';
    } else if (!/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i.test(values.SchoolEmail)) {
        errors.SchoolEmail = 'Pages.Register.SchoolEmail.error_notmatch';
    }

    return errors;
}

const formSchoolForm = reduxForm({
    form: 'SchoolForm',
    validate
});

export default connect(mapStateToProps, {})(withTranslation('common')(formSchoolForm(SchoolForm)));