import React from "react";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { LazyLoadImage } from "react-lazy-load-image-component";
import Select from 'react-select';

import SchoolItem from "./SchoolItem";
import {
    fetchRegistrationSchools as fetch,
    removeRegistrationSchools as remove,
    undoRegistrationSchools as undo,
    clearRegistrationSchools as clear
} from './actions';
import api from '../../../api/api';
import { notificationIsView as notificationIsViewFromSocket } from '../../../api/socket.io';
import Footer from "../../Footer/FooterBottom";
import UserSubMenu from '../../Home/User/UserSubMenu';
import configDataCountries from "../../Config/countries.json";

class RegistrationSchoolsList extends React.Component {

    state = {
        search: '',
        IsActive: null,
        CountryCustom: null,
        showAdd: false,
        Email: "",
        Phone: "",
        Name: "",
        showRegistered: false,
        isStartSearch: false,
        Code: "",
        Limit: 20
    };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
        this.state.countries = configDataCountries.countries.sort((a, b) => a.label > b.label ? 1 : -1).map(item => {
            return {
                value: item.value,
                name: item.label,
                label: <div><LazyLoadImage src={process.env.PUBLIC_URL + item.icon} />  {item.label}</div>,
            };
        });
    }


    Sync = () => {
        setTimeout(() => {
            this.props.fetch(
                {
                    Country: this.Country.state.value ? this.Country.state.value.value : "",
                    CountryCustom: this.state.CountryCustom,
                    Email: this.state.Email,
                    Phone: this.state.Phone,
                    Limit: this.state.Limit,
                    Name: this.state.Name,
                    Code: this.state.Code
                }
            );
        }, 500);
    }

    showApproved = id => {
        if (id) {
            this.props.remove(id);
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('Pages.SchoolsControl.ApprovedProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDelete(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-window-close"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.approvedItem(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-check-square"></i> {t('Pages.SchoolsControl.Approved')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });

        }
    }

    approvedItem = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.put(`/RegistrationSchools/${id}`).then(response => {

            this.props.clear(id);

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.SchoolsControl.toast.Approved.Title')}</div> <div className="h6">{t('Pages.SchoolsControl.toast.Approved.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });
            notificationIsViewFromSocket("SCHOOL_REGISTER", this.props.user.user.Id);
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    showDelete = id => {
        if (id) {
            this.props.remove(id);
            const { t } = this.props;

            let _toastId = toast(t('Processing'), {
                position: "bottom-left",
                autoClose: false
            });

            toast.update(_toastId, {
                render: () => <div>
                    <i className="fa-spin fas fa-spinner"></i> {t('DeleteProcessing')}
                    <div className="row row-cols-2">
                        <div className="col">
                            <button type="button" className="btn btn-danger btn-block " onClick={() => { this.undoDelete(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-trash-undo"></i> {t('undo')}
                            </button>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-light btn-block " onClick={() => { this.deleteItem(id); toast.dismiss(_toastId); }}>
                                <i className="icon fal fa-recycle"></i> {t('confirm')}
                            </button>
                        </div>
                    </div>
                </div>,
                type: toast.TYPE.DARK,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true
            });

        }
    }

    deleteItem = id => {
        const { t } = this.props;

        let _toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(_toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner"></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.delete(`/RegistrationSchools/${id}`).then(response => {

            this.props.clear(id);

            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-exclamation-triangle"></i> {t('Pages.SchoolsControl.toast.Delete.Title')}</div> <div className="h6">{t('Pages.SchoolsControl.toast.Delete.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });
            notificationIsViewFromSocket("SCHOOL_REGISTER", this.props.user.user.Id);
        }).catch(error => {
            toast.update(_toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    undoDelete = id => {
        this.props.undo(id);
    }

    renderSchools() {
        const { t, schools } = this.props;
        let isLoaded = false;
        let toRemove = [];

        if (schools) {
            toRemove = schools.toRemove;
            isLoaded = schools.isLoaded;
        }

        if (schools && schools.schools && schools.schools.length > 0) {
            let filterSchools = schools.schools.filter(v => v.Name.includes(this.state.search));

            if (isLoaded === false) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-sync-alt fa-spin"></i>
                            </div>
                            <h4 className="display-3 subtitle">
                                {t("Pages.SchoolsControl.Loading")}
                            </h4>
                        </div>
                    </div>
                );
            }

            if (filterSchools.length === 0 && this.state.search) {
                return (
                    <div className="product-list-item" >
                        <div className="product-content w-100 text-center ">
                            <div className="display-1 text-danger">
                                <i className="fad fa-telescope"></i>
                            </div>
                            <h4 className=" subtitle">
                                {t("Pages.SchoolsControl.NotFound")}
                            </h4>
                            <div className="h2 text-danger">
                                "{this.state.search}"
                                {t("Pages.SchoolsControl.IsNotHere")}
                            </div>
                        </div>
                    </div>
                );
            }

            return filterSchools.sort((a, b) => a.Sort > b.Sort ? 1 : -1).map(school =>
                (toRemove && toRemove.includes(school.Id)) ? "" :
                    <SchoolItem
                        key={school.Id}
                        item={school}
                        approved={this.showApproved}
                        remove={this.showDelete}
                    ></SchoolItem>
            );
        }

        if (schools && schools.isLoaded === false) {
            return (
                <div className="product-list-item" >
                    <div className="product-content w-100 text-center ">
                        <div className="display-1 text-danger">
                            <i className="fad fa-sync-alt fa-spin"></i>
                        </div>
                        <h4 className="display-3 subtitle">
                            {t("Pages.SchoolsControl.Loading")}
                        </h4>
                    </div>
                </div>
            );
        }

        return (
            <div className="product-list-item" >
                <div className="product-content w-100 text-center ">
                    <div className="display-1 text-danger">
                        <i className="fad fa-telescope"></i>
                    </div>
                    <h4 className="display-3 subtitle">
                        {t("Pages.SchoolsControl.NotFound")}
                    </h4>
                </div>
            </div>
        );
    }

    filterOptions = (candidate, input) => {
        if (input) {
            return candidate.data.name.includes(input);
        }
        return true;
    }

    setCountry = CountryCustom => {
        this.setState({
            CountryCustom
        });
    }

    setLimit = Limit => {
        this.setState({
            Limit
        });
    }

    setPhone = Phone => {
        this.setState({
            Phone
        });
    }

    setCode = Code => {
        this.setState({
            Code
        });
    }

    setName = Name => {
        this.setState({
            Name
        });
    }

    setEmail = Email => {
        this.setState({
            Email
        });
    }

    render() {
        const { t, user } = this.props;

        return (
            <>

                <section className="pos-rel padding-bottom padding-top ">
                    <div className="top-shape-center">
                        <LazyLoadImage src="/assets/css/img/gallery1.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="bottom-shape-left">
                        <LazyLoadImage src="/assets/css/img/bottom-shape.png" alt={`${t('alarabeyya')}`} />
                    </div>
                    <div className="container">

                        <div className="row ">
                            <div className="col-lg-12 mb-50">

                                <div className="widget widget-category" >

                                    <div className="widget-header">
                                        <h5 className="title">
                                            <i className="fal fa-search "></i>
                                            {t("Pages.SchoolsControl.showRegistered")}
                                        </h5>
                                    </div>
                                    <div className="widget-body">
                                        <div className="contact-form">
                                            <div className="row">
                                                <div className="form-group col-md-3">
                                                    <div>
                                                        <Select
                                                            defaultValue={this.state.CountryIndex >= 0 ? {
                                                                label: this.state.countries[this.state.CountryIndex].label,
                                                                value: this.state.countries[this.state.CountryIndex].value
                                                            } : ""}
                                                            name="Country"
                                                            placeholder={t('Pages.Register.Country.Placeholder')}
                                                            isClearable={true}
                                                            isRtl={true}
                                                            isSearchable={true}
                                                            ref={Country => this.Country = Country}
                                                            options={this.state.countries}
                                                            filterOption={this.filterOptions}
                                                            theme={theme => ({
                                                                ...theme,
                                                                borderRadius: 0,
                                                                colors: {
                                                                    ...theme.colors,
                                                                    primary25: '#92278f',
                                                                    primary: '#92278f',
                                                                },
                                                            })}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <select
                                                        defaultValue={this.state.Country}
                                                        onChange={e => this.setCountry(e.target.value)}
                                                        className="form-control"
                                                        name="CountryCustom"
                                                        title={t("Pages.UsersControl.advancedSearch.Country.title")}  >
                                                        <option value="">{t("Pages.UsersControl.advancedSearch.Country.title")}  </option>
                                                        <option value={false}>{t("Pages.UsersControl.advancedSearch.Country.OutSide")}  </option>
                                                        <option value={true}>{t("Pages.UsersControl.advancedSearch.Country.InSide")}  </option>
                                                    </select>
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <input
                                                        defaultValue={this.state.Name}
                                                        onChange={e => this.setName(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={t("Pages.UsersControl.advancedSearch.name")}
                                                        title={t("Pages.UsersControl.advancedSearch.name")} />
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <input
                                                        defaultValue={this.state.Email}
                                                        onChange={e => this.setEmail(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={t("Pages.UsersControl.advancedSearch.email")}
                                                        title={t("Pages.UsersControl.advancedSearch.email")} />
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <input
                                                        defaultValue={this.state.Phone}
                                                        onChange={e => this.setPhone(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={t("Pages.UsersControl.advancedSearch.phone")}
                                                        title={t("Pages.UsersControl.advancedSearch.phone")} />
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <input
                                                        defaultValue={this.state.Code}
                                                        onChange={e => this.setCode(e.target.value)}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder={t("Pages.UsersControl.advancedSearch.Code")}
                                                        title={t("Pages.UsersControl.advancedSearch.Code")} />
                                                </div>

                                                <div className="form-group col-md-3">
                                                    <input
                                                        defaultValue={this.state.Limit}
                                                        onChange={e => this.setLimit(e.target.value)}
                                                        type="number"
                                                        step={1}
                                                        className="form-control"
                                                        placeholder={t("Pages.UsersControl.advancedSearch.limit")}
                                                        title={t("Pages.UsersControl.advancedSearch.limit")} />
                                                </div>

                                                <div className="form-group col-md-3 ">
                                                    <button className="custom-button" onClick={() => this.Sync()} title={t("Pages.UsersControl.Sync")} >
                                                        <span>
                                                            <i className="fas fa-sync-alt mx-1"></i>
                                                            {t('Pages.UsersControl.advancedSearch.send')}
                                                        </span>
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row ">
                                    <div className="col-lg-8 mb-50">

                                        {this.renderSchools()}
                                    </div>

                                    <div className="col-lg-4 mb-50">
                                        <aside>
                                            <UserSubMenu user={user} />
                                        </aside>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <Footer />
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        schools: state.registrationSchools,
        user: state.user
    };
};

export default connect(mapStateToProps, { fetch, remove, clear, undo })(withTranslation('common')(RegistrationSchoolsList));
