import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Modal from 'react-bootstrap/Modal';

import history from '../../../history';
import Backgrounds from "../../Admin/Question/View/Background";
import api from "../../../api/api";
import { subscriptionsInitial } from "../../actions/";
import SchoolForm from "../Registration/SchoolRegister/Forms/Step2";

class Subscription extends React.Component {

    state = { showRegisterForm: false, type: "" };

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
        if (this.props.user &&
            this.props.user.user &&
            this.props.user.user.IsActive &&
            (this.props.user.user.UserType.Id === 2 || this.props.user.user.UserType.Id === 3 || this.props.user.user.UserType.Id === 4)
            && this.props.location && this.props.location.location && this.props.location.location.country_code !== "il"
        ) {

        } else {
            if (this.props.location && this.props.location.location && this.props.location.location.country_code === "il") {
                history.push("/User/Renwal");
            } else {
                history.push("/Profile");
            }
        }
    }

    componentDidMount() {
        this.props.subscriptionsInitial();
        window.scrollToPosition("main-renwal-section");
    }

    getVisaLink = type => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        api.get(`/PayProglobalPaymentSubscription/?type=${type}`).then(response => {
            window.location.href = response.data;
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });

        toast.dismiss(this._toastId.current);
    }

    checkIsRegisterSchool = type => {
        const { user } = this.props;
        if (user.user?.UserType?.Id !== 2) {
            this.getVisaLink(type);
        } else {
            this.setState({
                showRegisterForm: true,
                type
            });
        }
    }

    hideRegisterForm = () => {
        this.setState({
            showRegisterForm: false,
            type: ""
        });
    }

    onSubmit = formValues => {
        const { t } = this.props;
        let toastId = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(toastId, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        const postData = { ...formValues, Type: this.state.type };
        api.post(`/SchoolRegister/`,
            postData
        ).then(response => {
            window.location.href = response.data;
        }).catch(error => {
            toast.update(toastId, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });
    }

    render() {
        const { t, user, services } = this.props;

        const S1 = services.services?.filter(p => p.IndexType === "S1")[0];
        const S2 = services.services?.filter(p => p.IndexType === "S2")[0];
        const S3 = services.services?.filter(p => p.IndexType === "S3")[0];

        const T1 = services.services?.filter(p => p.IndexType === "T1")[0];
        const T2 = services.services?.filter(p => p.IndexType === "T2")[0];
        const T3 = services.services?.filter(p => p.IndexType === "T3")[0];
        const T4 = services.services?.filter(p => p.IndexType === "T4")[0];
        const T5 = services.services?.filter(p => p.IndexType === "T5")[0];
        const T6 = services.services?.filter(p => p.IndexType === "T6")[0];

        return <>

            {
                this.state.showRegisterForm ?
                    <Modal
                        size="lg"
                        centered
                        show={this.state.showRegisterForm}
                        onHide={this.hideRegisterForm}
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <i className="icon fa-solid fa-school-flag px-1 text-primary"></i>
                                {t("Pages.Register.steps.SchoolForm")}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <SchoolForm onSubmit={this.onSubmit} />
                        </Modal.Body>
                    </Modal>
                    : ""
            }
            <Helmet>
                <title>
                    {`${user.user.FirstName} ${user.user.SecondName} ${user.user.LastName}`} |  {t('Pages.Profile.services.title')}
                </title>
            </Helmet>
            <style dangerouslySetInnerHTML={{ __html: `html{width: calc(100% );overflow-x: unset;}header{ display:none; }body{overflow-x: hidden;}section{padding-top:5px !important;}.footer-bottom{display:none;}` }}></style>

            <section className="pos-rel call-in-action call-overlay oh" id="main-renwal-section" style={{
                backgroundColor: '#75cdff'
            }}>

                <div className="position-relative padding-bottom w-100 d-flex align-items-center justify-content-center" style={{
                    minHeight: '100vh'
                }}>
                    <Backgrounds CustomBackground="Registration" />
                    <div className="container h-100" >
                        <div className="widget widget-category col-12 mx-auto px-0">
                            <div className="widget-header p-3 d-flex justify-content-center align-items-center">
                                <LazyLoadImage src="/assets/images/logo/logo-1.png" alt={`${t('alarabeyya')}`} />
                            </div>
                            <div className="widget-body px-3" style={{
                                background: '#fff8eb'
                            }}>
                                {
                                    user.user?.UserType?.Id !== 2 ? "" :
                                        <>
                                            <div className="row justify-content-center">
                                                <div className="col-lg-8">
                                                    <div className="section-header mb-3 mt-3">
                                                        <h3 className="title">
                                                            <i className="fal fa-user-graduate mx-1"></i>
                                                            الاشتراكات الفردية
                                                        </h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row justify-content-center mb-30-none mb-4">
                                                <div className="col-md-6 col-lg-4 mb-1">
                                                    <div className="feature-item px-3 pb-2 h-100">
                                                        <div className="feature-content">
                                                            <h5 className="title">الأَساسيّ</h5>
                                                            <span className="cate font-weight-bold h5 mt-2">
                                                                {
                                                                    S1?.IsSelected || S2?.IsSelected || S3?.IsSelected ? "" :
                                                                        <>
                                                                            <span className="mx-1">
                                                                                {S1?.Price}
                                                                            </span>
                                                                            {
                                                                                S1?.IsNIS ?
                                                                                    <i title={t("Pages.Profile.services.shekel")} className="fal fa-shekel-sign"></i> :
                                                                                    <i title={t("Pages.Profile.services.dollar")} className="fal fa-dollar-sign"></i>
                                                                            }
                                                                        </>
                                                                }
                                                            </span>
                                                            <p className="text-right">
                                                                ويشمل :
                                                            </p>
                                                            <div className="list w-100 text-right p-0">
                                                                <ul>
                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                        اشتراك سنة كاملة.
                                                                    </li>
                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                        الدروس المحوسبة.
                                                                    </li>
                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                        الامتحانات المحوسبة.
                                                                    </li>
                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                        اختبر نفسك.
                                                                    </li>
                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-xmark mx-1 text-danger"></i>
                                                                        الالعاب التعليميّة.
                                                                    </li>
                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-xmark mx-1 text-danger"></i>
                                                                        الكتب المحوسبة.
                                                                    </li>
                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-xmark mx-1 text-danger"></i>
                                                                        الملفات التعليميّة.
                                                                    </li>
                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-xmark mx-1 text-danger"></i>
                                                                        حقيبة المعلم.
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            {
                                                                S1?.IsSelected || S2?.IsSelected || S3?.IsSelected ?
                                                                    <>
                                                                        <div className="ribbon-corner pt-0" data-position="right top" style={{
                                                                            right: '10px',
                                                                            fontSize: "14px"
                                                                        }}>
                                                                            {t("Pages.Profile.services.isPaid")}
                                                                        </div>
                                                                        <p className="text-right mt-4">
                                                                            ينتهي الاشتراك في تاريخ :
                                                                        </p>
                                                                        <h5 className="title">
                                                                            {user.user.ActivateUntilDate}
                                                                        </h5>
                                                                    </>
                                                                    :
                                                                    <Link to="#" onClick={() => this.getVisaLink("S1")} className={`custom-button success-button mt-3`} >
                                                                        <span>
                                                                            {t('Pages.Profile.services.payNow')}
                                                                        </span>
                                                                    </Link>
                                                            }


                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 mb-1">
                                                    <div className="feature-item px-3 pb-2 h-100">
                                                        <div className="feature-content">
                                                            <h5 className="title">المتقدّم</h5>
                                                            <span className="cate font-weight-bold h5 mt-2">
                                                                {
                                                                    S2?.IsSelected || S3?.IsSelected ? "" :
                                                                        <>
                                                                            <span className="mx-1">
                                                                                {S1?.IsSelected ? S2?.Price - S1?.Price : S2?.Price}
                                                                            </span>
                                                                            {
                                                                                S2?.IsNIS ?
                                                                                    <i title={t("Pages.Profile.services.shekel")} className="fal fa-shekel-sign"></i> :
                                                                                    <i title={t("Pages.Profile.services.dollar")} className="fal fa-dollar-sign"></i>
                                                                            }
                                                                        </>
                                                                }
                                                            </span>
                                                            <p className="text-right">
                                                                ويشمل :
                                                            </p>
                                                            <div className="list w-100 text-right p-0">
                                                                <ul>
                                                                    {
                                                                        S1?.IsSelected ?
                                                                            <li className="h6">
                                                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                                اشتراك حتى تاريخ&nbsp;{user.user.ActivateUntilDate}.
                                                                            </li>
                                                                            :
                                                                            <li className="h6">
                                                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                                اشتراك سنة كاملة.
                                                                            </li>
                                                                    }

                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                        الدروس المحوسبة.
                                                                    </li>
                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                        الامتحانات المحوسبة.
                                                                    </li>
                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                        اختبر نفسك.
                                                                    </li>
                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                        الالعاب التعليميّة.
                                                                    </li>
                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                        الكتب المحوسبة.
                                                                    </li>
                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-xmark mx-1 text-danger"></i>
                                                                        الملفات التعليميّة.
                                                                    </li>
                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-xmark mx-1 text-danger"></i>
                                                                        حقيبة المعلم.
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            {
                                                                S2?.IsSelected || S3?.IsSelected ?
                                                                    <>
                                                                        <div className="ribbon-corner pt-0" data-position="right top" style={{
                                                                            right: '10px',
                                                                            fontSize: "14px"
                                                                        }}>
                                                                            {t("Pages.Profile.services.isPaid")}
                                                                        </div>
                                                                        <p className="text-right mt-4">
                                                                            ينتهي الاشتراك في تاريخ :
                                                                        </p>
                                                                        <h5 className="title">
                                                                            {user.user.ActivateUntilDate}
                                                                        </h5>
                                                                    </>
                                                                    :
                                                                    <Link to="#" onClick={() => this.getVisaLink("S2")} className={`custom-button mt-3`} >
                                                                        {
                                                                            S1?.IsSelected ?
                                                                                <span style={{
                                                                                    fontSize: '18px',
                                                                                    padding: '5px 20px',
                                                                                    lineHeight: '27px'
                                                                                }}>
                                                                                    {t('Pages.Profile.services.renewal')}&nbsp;حتى تاريخ
                                                                                    <div>
                                                                                        {user.user.ActivateUntilDate}
                                                                                    </div>
                                                                                </span>
                                                                                :
                                                                                <span>
                                                                                    {t('Pages.Profile.services.payNow')}
                                                                                </span>
                                                                        }
                                                                    </Link>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 mb-1">
                                                    <div className="feature-item px-3 pb-2 h-100">
                                                        <div className="feature-content">
                                                            <h5 className="title">الشامل</h5>
                                                            <span className="cate font-weight-bold h5 mt-2">
                                                                {
                                                                    S3?.IsSelected ? "" :
                                                                        <>
                                                                            <span className="mx-1">
                                                                                {
                                                                                    S2?.IsSelected ?
                                                                                        S3?.Price - S2?.Price :
                                                                                        S1?.IsSelected ?
                                                                                            S3?.Price - S1?.Price :
                                                                                            S3?.Price
                                                                                }
                                                                            </span>
                                                                            {
                                                                                S3?.IsNIS ?
                                                                                    <i title={t("Pages.Profile.services.shekel")} className="fal fa-shekel-sign"></i> :
                                                                                    <i title={t("Pages.Profile.services.dollar")} className="fal fa-dollar-sign"></i>
                                                                            }
                                                                        </>
                                                                }
                                                            </span>
                                                            <p className="text-right">
                                                                ويشمل :
                                                            </p>
                                                            <div className="list w-100 text-right p-0">
                                                                <ul>
                                                                    {
                                                                        S2?.IsSelected || S1?.IsSelected ?
                                                                            <li className="h6">
                                                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                                اشتراك حتى تاريخ&nbsp;{user.user.ActivateUntilDate}.
                                                                            </li>
                                                                            :
                                                                            <li className="h6">
                                                                                <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                                اشتراك سنة كاملة.
                                                                            </li>
                                                                    }

                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                        الدروس المحوسبة.
                                                                    </li>
                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                        الامتحانات المحوسبة.
                                                                    </li>
                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                        اختبر نفسك.
                                                                    </li>
                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                        الالعاب التعليميّة.
                                                                    </li>
                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                        الكتب المحوسبة.
                                                                    </li>
                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                        الملفات التعليميّة.
                                                                    </li>
                                                                    <li className="h6">
                                                                        <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                        حقيبة المعلم.
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            {
                                                                S3?.IsSelected ?
                                                                    <>
                                                                        <div className="ribbon-corner pt-0" data-position="right top" style={{
                                                                            right: '10px',
                                                                            fontSize: "14px"
                                                                        }}>
                                                                            {t("Pages.Profile.services.isPaid")}
                                                                        </div>
                                                                        <p className="text-right mt-4">
                                                                            ينتهي الاشتراك في تاريخ :
                                                                        </p>
                                                                        <h5 className="title">
                                                                            {user.user.ActivateUntilDate}
                                                                        </h5>
                                                                    </>
                                                                    :
                                                                    <Link to="#" onClick={() => this.getVisaLink("S3")} className={`custom-button orange-button mt-3`} >
                                                                        {
                                                                            S1?.IsSelected || S2?.IsSelected ?
                                                                                <span style={{
                                                                                    fontSize: '18px',
                                                                                    padding: '5px 20px',
                                                                                    lineHeight: '27px'
                                                                                }}>
                                                                                    {t('Pages.Profile.services.renewal')}&nbsp;حتى تاريخ
                                                                                    <div>
                                                                                        {user.user.ActivateUntilDate}
                                                                                    </div>
                                                                                </span>
                                                                                :
                                                                                <span>
                                                                                    {t('Pages.Profile.services.payNow')}
                                                                                </span>
                                                                        }
                                                                    </Link>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                user.user.ActivateUntilDate !== "0" ?
                                                    <div className="d-flex w-100 flex-wrap justify-content-center mb-4">
                                                        <Link to="/Profile" className={`custom-button primary-button mt-3`} >
                                                            <span style={{
                                                                fontSize: '18px',
                                                                padding: '5px 20px',
                                                                lineHeight: '27px'
                                                            }}>
                                                                الاستمرار في الاشتراك الحالي
                                                                <div>
                                                                    {user.user.ActivateUntilDate}
                                                                </div>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                    :
                                                    <div className="d-flex w-100 flex-wrap justify-content-center mb-4">
                                                        <Link to="/Profile" className={`custom-button danger-button mt-3`} >
                                                            <span>
                                                                انتقل للموقع لبدء التجربة المجانية
                                                            </span>
                                                        </Link>
                                                    </div>
                                            }
                                        </>
                                }

                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="section-header mb-3 mt-3">
                                            <h3 className="title">
                                                <i className="fal fa-chalkboard-user mx-1"></i>
                                                اشتراكات مدارس صغيرة
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center mb-30-none mb-4">
                                    <div className="col-md-6 col-xl-3 mb-2">
                                        <div className="feature-item px-3 pb-2 h-100">
                                            <div className="feature-content">
                                                <h5 className="title">أَساسيّ</h5>
                                                <span className="cate font-weight-bold h5 mt-2">
                                                    {
                                                        T1?.IsSelected || T2?.IsSelected || T3?.IsSelected || T4?.IsSelected || T5?.IsSelected || T6?.IsSelected ? "" :
                                                            <>
                                                                <span className="mx-1">
                                                                    {T1?.Price}
                                                                </span>
                                                                {
                                                                    T1?.IsNIS ?
                                                                        <i title={t("Pages.Profile.services.shekel")} className="fal fa-shekel-sign"></i> :
                                                                        <i title={t("Pages.Profile.services.dollar")} className="fal fa-dollar-sign"></i>
                                                                }
                                                            </>
                                                    }
                                                </span>
                                                <p className="text-right">
                                                    ويشمل :
                                                </p>
                                                <div className="list w-100 text-right p-0">
                                                    <ul>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            اشتراك سنة كاملة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            عدد المعلمين 1.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            عدد الطلاب 20.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            الدروس المحوسبة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            الامتحانات المحوسبة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            اختبر نفسك.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            ارسال المهام.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            تقييم الطلاب.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-xmark mx-1 text-danger"></i>
                                                            الالعاب التعليميّة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-xmark mx-1 text-danger"></i>
                                                            الكتب المحوسبة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-xmark mx-1 text-danger"></i>
                                                            الملفات التعليميّة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-xmark mx-1 text-danger"></i>
                                                            حقيبة المعلم.
                                                        </li>
                                                    </ul>
                                                </div>
                                                {
                                                    T1?.IsSelected || T2?.IsSelected || T3?.IsSelected || T4?.IsSelected || T5?.IsSelected || T6?.IsSelected ?
                                                        <>
                                                            <div className="ribbon-corner pt-0" data-position="right top" style={{
                                                                right: '10px',
                                                                fontSize: "14px"
                                                            }}>
                                                                {t("Pages.Profile.services.isPaid")}
                                                            </div>
                                                            <p className="text-right mt-4">
                                                                ينتهي الاشتراك في تاريخ :
                                                            </p>
                                                            <h5 className="title">
                                                                {user.user.ActivateUntilDate}
                                                            </h5>
                                                        </>
                                                        :
                                                        <Link to="#" onClick={() => this.checkIsRegisterSchool("T1")} className={`custom-button success-button mt-3`} >
                                                            <span>
                                                                {t('Pages.Profile.services.payNow')}
                                                            </span>
                                                        </Link>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-3 mb-2">
                                        <div className="feature-item px-3 pb-2 h-100">
                                            <div className="feature-content">
                                                <h5 className="title">أَساسيّ موسّع	</h5>
                                                <span className="cate font-weight-bold h5 mt-2">
                                                    {
                                                        T2?.IsSelected || T3?.IsSelected || T4?.IsSelected || T5?.IsSelected || T6?.IsSelected ? "" :
                                                            <>
                                                                <span className="mx-1">
                                                                    {T1?.IsSelected ? T2?.Price - T1?.Price : T2?.Price}
                                                                </span>
                                                                {
                                                                    T2?.IsNIS ?
                                                                        <i title={t("Pages.Profile.services.shekel")} className="fal fa-shekel-sign"></i> :
                                                                        <i title={t("Pages.Profile.services.dollar")} className="fal fa-dollar-sign"></i>
                                                                }
                                                            </>
                                                    }
                                                </span>
                                                <p className="text-right">
                                                    ويشمل :
                                                </p>
                                                <div className="list w-100 text-right p-0">
                                                    <ul>
                                                        {
                                                            T1?.IsSelected ?
                                                                <li className="h6">
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    اشتراك حتى تاريخ&nbsp;{user.user.ActivateUntilDate}.
                                                                </li>
                                                                :
                                                                <li className="h6">
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    اشتراك سنة كاملة.
                                                                </li>
                                                        }
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            عدد المعلمين 1.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            عدد الطلاب 70.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            الدروس المحوسبة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            الامتحانات المحوسبة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            اختبر نفسك.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            ارسال المهام.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            تقييم الطلاب.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-xmark mx-1 text-danger"></i>
                                                            الالعاب التعليميّة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-xmark mx-1 text-danger"></i>
                                                            الكتب المحوسبة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-xmark mx-1 text-danger"></i>
                                                            الملفات التعليميّة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-xmark mx-1 text-danger"></i>
                                                            حقيبة المعلم.
                                                        </li>
                                                    </ul>
                                                </div>
                                                {
                                                    T2?.IsSelected || T3?.IsSelected || T4?.IsSelected || T5?.IsSelected || T6?.IsSelected ?
                                                        <>
                                                            <div className="ribbon-corner pt-0" data-position="right top" style={{
                                                                right: '10px',
                                                                fontSize: "14px"
                                                            }}>
                                                                {t("Pages.Profile.services.isPaid")}
                                                            </div>
                                                            <p className="text-right mt-4">
                                                                ينتهي الاشتراك في تاريخ :
                                                            </p>
                                                            <h5 className="title">
                                                                {user.user.ActivateUntilDate}
                                                            </h5>
                                                        </>
                                                        :
                                                        <Link to="#" onClick={() => this.checkIsRegisterSchool("T2")} className={`custom-button mt-3`} >
                                                            <span>
                                                                {t('Pages.Profile.services.payNow')}
                                                            </span>
                                                        </Link>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-3 mb-2">
                                        <div className="feature-item px-3 pb-2 h-100">
                                            <div className="feature-content">
                                                <h5 className="title"> متقدم	</h5>
                                                <span className="cate font-weight-bold h5 mt-2">
                                                    {
                                                        T3?.IsSelected || T4?.IsSelected || T5?.IsSelected || T6?.IsSelected ? "" :
                                                            <>
                                                                <span className="mx-1">
                                                                    {
                                                                        T2?.IsSelected ?
                                                                            T3?.Price - T2?.Price :
                                                                            T1?.IsSelected ?
                                                                                T3?.Price - T1?.Price :
                                                                                T3?.Price
                                                                    }
                                                                </span>
                                                                {
                                                                    T3?.IsNIS ?
                                                                        <i title={t("Pages.Profile.services.shekel")} className="fal fa-shekel-sign"></i> :
                                                                        <i title={t("Pages.Profile.services.dollar")} className="fal fa-dollar-sign"></i>
                                                                }
                                                            </>
                                                    }
                                                </span>
                                                <p className="text-right">
                                                    ويشمل :
                                                </p>
                                                <div className="list w-100 text-right p-0">
                                                    <ul>
                                                        {
                                                            T2?.IsSelected || T1?.IsSelected ?
                                                                <li className="h6">
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    اشتراك حتى تاريخ&nbsp;{user.user.ActivateUntilDate}.
                                                                </li>
                                                                :
                                                                <li className="h6">
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    اشتراك سنة كاملة.
                                                                </li>
                                                        }
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            عدد المعلمين 1.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            عدد الطلاب 20.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            الدروس المحوسبة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            الامتحانات المحوسبة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            اختبر نفسك.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            ارسال المهام.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            تقييم الطلاب.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            الالعاب التعليميّة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            الكتب المحوسبة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            الملفات التعليميّة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            حقيبة المعلم.
                                                        </li>
                                                    </ul>
                                                </div>
                                                {
                                                    T3?.IsSelected || T4?.IsSelected || T5?.IsSelected || T6?.IsSelected ?
                                                        <>
                                                            <div className="ribbon-corner pt-0" data-position="right top" style={{
                                                                right: '10px',
                                                                fontSize: "14px"
                                                            }}>
                                                                {t("Pages.Profile.services.isPaid")}
                                                            </div>
                                                            <p className="text-right mt-4">
                                                                ينتهي الاشتراك في تاريخ :
                                                            </p>
                                                            <h5 className="title">
                                                                {user.user.ActivateUntilDate}
                                                            </h5>
                                                        </>
                                                        :
                                                        <Link to="#" onClick={() => this.checkIsRegisterSchool("T3")} className={`custom-button orange-button mt-3`} >
                                                            <span>
                                                                {t('Pages.Profile.services.payNow')}
                                                            </span>
                                                        </Link>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-xl-3 mb-2">
                                        <div className="feature-item px-3 pb-2 h-100">
                                            <div className="feature-content">
                                                <h5 className="title"> متقدم موسّع		</h5>
                                                <span className="cate font-weight-bold h5 mt-2">
                                                    {
                                                        T4?.IsSelected || T5?.IsSelected || T6?.IsSelected ? "" :
                                                            <>
                                                                <span className="mx-1">
                                                                    {
                                                                        T3?.IsSelected ?
                                                                            T4?.Price - T3?.Price :
                                                                            T2?.IsSelected ?
                                                                                T4?.Price - T2?.Price :
                                                                                T1?.IsSelected ?
                                                                                    T4?.Price - T1?.Price :
                                                                                    T4?.Price
                                                                    }
                                                                </span>
                                                                {
                                                                    T4?.IsNIS ?
                                                                        <i title={t("Pages.Profile.services.shekel")} className="fal fa-shekel-sign"></i> :
                                                                        <i title={t("Pages.Profile.services.dollar")} className="fal fa-dollar-sign"></i>
                                                                }
                                                            </>
                                                    }
                                                </span>
                                                <p className="text-right">
                                                    ويشمل :
                                                </p>
                                                <div className="list w-100 text-right p-0">
                                                    <ul>
                                                        {
                                                            T3?.IsSelected || T2?.IsSelected || T1?.IsSelected ?
                                                                <li className="h6">
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    اشتراك حتى تاريخ&nbsp;{user.user.ActivateUntilDate}.
                                                                </li>
                                                                :
                                                                <li className="h6">
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    اشتراك سنة كاملة.
                                                                </li>
                                                        }
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            عدد المعلمين 1.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            عدد الطلاب 70.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            الدروس المحوسبة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            الامتحانات المحوسبة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            اختبر نفسك.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            ارسال المهام.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            تقييم الطلاب.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            الالعاب التعليميّة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            الكتب المحوسبة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            الملفات التعليميّة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            حقيبة المعلم.
                                                        </li>
                                                    </ul>
                                                </div>
                                                {
                                                    T4?.IsSelected || T5?.IsSelected || T6?.IsSelected ?
                                                        <>
                                                            <div className="ribbon-corner pt-0" data-position="right top" style={{
                                                                right: '10px',
                                                                fontSize: "14px"
                                                            }}>
                                                                {t("Pages.Profile.services.isPaid")}
                                                            </div>
                                                            <p className="text-right mt-4">
                                                                ينتهي الاشتراك في تاريخ :
                                                            </p>
                                                            <h5 className="title">
                                                                {user.user.ActivateUntilDate}
                                                            </h5>
                                                        </>
                                                        :
                                                        <Link to="#" onClick={() => this.checkIsRegisterSchool("T4")} className={`custom-button primary-button mt-3`} >
                                                            <span>
                                                                {t('Pages.Profile.services.payNow')}
                                                            </span>
                                                        </Link>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="section-header mb-3 mt-3">
                                            <h3 className="title">
                                                <i className="fal fa-school mx-1"></i>
                                                اشتراكات المدارس
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center mb-30-none mb-4">
                                    <div className="col-md-6 col-lg-5 col-xl-4 mb-1">
                                        <div className="feature-item px-3 pb-2 h-100">
                                            <div className="feature-content">
                                                <h5 className="title">شامل</h5>
                                                <span className="cate font-weight-bold h5 mt-2">
                                                    {
                                                        T5?.IsSelected || T6?.IsSelected ? "" :
                                                            <>
                                                                <span className="mx-1">
                                                                    {
                                                                        T4?.IsSelected ?
                                                                            T5?.Price - T4?.Price :
                                                                            T3?.IsSelected ?
                                                                                T5?.Price - T3?.Price :
                                                                                T2?.IsSelected ?
                                                                                    T5?.Price - T2?.Price :
                                                                                    T1?.IsSelected ?
                                                                                        T5?.Price - T1?.Price :
                                                                                        T5?.Price
                                                                    }
                                                                </span>
                                                                {
                                                                    T5?.IsNIS ?
                                                                        <i title={t("Pages.Profile.services.shekel")} className="fal fa-shekel-sign"></i> :
                                                                        <i title={t("Pages.Profile.services.dollar")} className="fal fa-dollar-sign"></i>
                                                                }
                                                            </>
                                                    }
                                                </span>
                                                <p className="text-right">
                                                    ويشمل :
                                                </p>
                                                <div className="list w-100 text-right p-0">
                                                    <ul>
                                                        {
                                                            T4?.IsSelected || T3?.IsSelected || T2?.IsSelected || T1?.IsSelected ?
                                                                <li className="h6">
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    اشتراك حتى تاريخ&nbsp;{user.user.ActivateUntilDate}.
                                                                </li>
                                                                :
                                                                <li className="h6">
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    اشتراك سنة كاملة.
                                                                </li>
                                                        }
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            طاقم اللغة العربية في المدرسة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            جميع طلاب المدرسة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            الدروس المحوسبة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            الامتحانات المحوسبة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            اختبر نفسك.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            ارسال المهام.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            تقييم الطلاب.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-xmark mx-1 text-danger"></i>
                                                            الالعاب التعليميّة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-xmark mx-1 text-danger"></i>
                                                            الكتب المحوسبة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-xmark mx-1 text-danger"></i>
                                                            الملفات التعليميّة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-xmark mx-1 text-danger"></i>
                                                            حقيبة المعلم.
                                                        </li>
                                                    </ul>
                                                </div>
                                                {
                                                    T5?.IsSelected || T6?.IsSelected ?
                                                        <>
                                                            <div className="ribbon-corner pt-0" data-position="right top" style={{
                                                                right: '10px',
                                                                fontSize: "14px"
                                                            }}>
                                                                {t("Pages.Profile.services.isPaid")}
                                                            </div>
                                                            <p className="text-right mt-4">
                                                                ينتهي الاشتراك في تاريخ :
                                                            </p>
                                                            <h5 className="title">
                                                                {user.user.ActivateUntilDate}
                                                            </h5>
                                                        </>
                                                        :
                                                        <Link to="#" onClick={() => this.checkIsRegisterSchool("T5")} className={`custom-button success-button mt-3`} >
                                                            <span>
                                                                {t('Pages.Profile.services.payNow')}
                                                            </span>
                                                        </Link>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-5 col-xl-4 mb-1">
                                        <div className="feature-item px-3 pb-2 h-100">
                                            <div className="feature-content">
                                                <h5 className="title">شامل موسّع	</h5>
                                                <span className="cate font-weight-bold h5 mt-2">
                                                    {
                                                        T6?.IsSelected ? "" :
                                                            <>
                                                                <span className="mx-1">
                                                                    {
                                                                        T5?.IsSelected ?
                                                                            T6?.Price - T5?.Price :
                                                                            T4?.IsSelected ?
                                                                                T6?.Price - T4?.Price :
                                                                                T3?.IsSelected ?
                                                                                    T6?.Price - T3?.Price :
                                                                                    T2?.IsSelected ?
                                                                                        T6?.Price - T2?.Price :
                                                                                        T1?.IsSelected ?
                                                                                            T6?.Price - T1?.Price :
                                                                                            T6?.Price
                                                                    }
                                                                </span>
                                                                {
                                                                    T6?.IsNIS ?
                                                                        <i title={t("Pages.Profile.services.shekel")} className="fal fa-shekel-sign"></i> :
                                                                        <i title={t("Pages.Profile.services.dollar")} className="fal fa-dollar-sign"></i>
                                                                }
                                                            </>
                                                    }
                                                </span>
                                                <p className="text-right">
                                                    ويشمل :
                                                </p>
                                                <div className="list w-100 text-right p-0">
                                                    <ul>
                                                        {
                                                            T5?.IsSelected || T4?.IsSelected || T3?.IsSelected || T2?.IsSelected || T1?.IsSelected ?
                                                                <li className="h6">
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    اشتراك حتى تاريخ&nbsp;{user.user.ActivateUntilDate}.
                                                                </li>
                                                                :
                                                                <li className="h6">
                                                                    <i className="fa-solid fa-check mx-1 text-success"></i>
                                                                    اشتراك سنة كاملة.
                                                                </li>
                                                        }
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            طاقم اللغة العربية في المدرسة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            جميع طلاب المدرسة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            الدروس المحوسبة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            الامتحانات المحوسبة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            اختبر نفسك.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            ارسال المهام.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            تقييم الطلاب.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            الالعاب التعليميّة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            الكتب المحوسبة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            الملفات التعليميّة.
                                                        </li>
                                                        <li className="h6">
                                                            <i className="fa-solid fa-check mx-1 text-success"></i>
                                                            حقيبة المعلم.
                                                        </li>
                                                    </ul>
                                                </div>
                                                {
                                                    T6?.IsSelected ?
                                                        <>
                                                            <div className="ribbon-corner pt-0" data-position="right top" style={{
                                                                right: '10px',
                                                                fontSize: "14px"
                                                            }}>
                                                                {t("Pages.Profile.services.isPaid")}
                                                            </div>
                                                            <p className="text-right mt-4">
                                                                ينتهي الاشتراك في تاريخ :
                                                            </p>
                                                            <h5 className="title">
                                                                {user.user.ActivateUntilDate}
                                                            </h5>
                                                        </>
                                                        :
                                                        <Link to="#" onClick={() => this.checkIsRegisterSchool("T6")} className={`custom-button mt-3`} >
                                                            <span>
                                                                {t('Pages.Profile.services.payNow')}
                                                            </span>
                                                        </Link>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    user.user?.UserType?.Id !== 2 && user.user.ActivateUntilDate !== "0" ?
                                        <div className="d-flex w-100 flex-wrap justify-content-center mb-4">
                                            <Link to="/Profile" className={`custom-button primary-button mt-3`} >
                                                <span style={{
                                                    fontSize: '18px',
                                                    padding: '5px 20px',
                                                    lineHeight: '27px'
                                                }}>
                                                    الاستمرار في الاشتراك الحالي
                                                    <div>
                                                        {user.user.ActivateUntilDate}
                                                    </div>
                                                </span>
                                            </Link>
                                        </div>
                                        : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>;
    }

}

const mapStateToProps = state => {
    return {
        services: state.services,
        user: state.user,
        location: state.location
    };
};
export default connect(mapStateToProps, { subscriptionsInitial })(withTranslation('common')(Subscription));