import React from "react";
import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import ErrorPage from "../Admin/Question/View/Background/ErrorPage";
import LessonItem from "./Tracks/LessonItem";
import ListItem from "../Admin/Files/ListItem";

class NoPermissions extends React.Component {

    render() {
        const { t, lessons, files } = this.props;

        let no_permission = false;
        if (lessons && lessons.code && lessons.code === "no_permission") {
            no_permission = true;
        }

        if (files && files.code && files.code === "no_permission") {
            no_permission = true;
        }

        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };
        const datetime2 = new Date('2023-02-28T20:00:00');
        const endDate = datetime2.toLocaleString('en-UK', options);

        const now = new Date();
        const nowDateString = now.toLocaleString('en-UK', options);

        let isEnd = false;
        if (endDate < nowDateString) {
            isEnd = true;
        }
        return (
            <>
                <Helmet>
                    <title>{t("Pages.NoPermissions.title")} | {t('alarabeyya')}</title>
                </Helmet>
                <style dangerouslySetInnerHTML={{ __html: `header{ display:none; }section{padding-top:5px !important;padding-bottom:5px !important;}` }}></style>
                <section className="error-section min-height-section" style={{
                    height: '100vh'
                }} >
                    <ErrorPage />
                    <div className="container">
                        <div className="error-wrapper">
                            <div className="inner-logo">
                                <Link to="/">
                                    <LazyLoadImage src="/assets/images/logo/big-logo.png" alt={`${t('alarabeyya')}`} />
                                </Link>
                            </div>
                            {
                                no_permission ?
                                    <div className="container">
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-lg-8 col-xs-12 mb-5  ">
                                                <div className="widget widget-category">
                                                    <div className="widget-header">
                                                        <h5 className="title">{t("Pages.NoPermissions.content.title")}</h5>
                                                    </div>
                                                    <div className="widget-body">
                                                        {
                                                            lessons && lessons.current ?
                                                                <LessonItem
                                                                    IsAdmin={false}
                                                                    lesson={lessons.current}
                                                                />
                                                                :
                                                                files && files.current ?
                                                                    <ListItem
                                                                        IsAdmin={false}
                                                                        item={files.current} />
                                                                    : ""
                                                        }
                                                        <h5 className="title">{t("Pages.NoPermissions.content.Message")}</h5>
                                                    </div>
                                                    <Link className="tags-area p-0" to="/Profile">
                                                        <div className="d-flex justify-content-center w-100">
                                                            <div className="theme-btn btn-style-two d-inline-flex justify-content-center w-100 show-more">
                                                                <span className="txt pl-2">
                                                                    <i className="fal fa-money-bill-alt mx-2"></i>
                                                                    {t('Pages.Profile.services.active')}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <div className="error-content">
                                            {
                                                this.props.isGame ?
                                                    <>
                                                        <h3 className="title"> {isEnd ? t('Pages.Competition.Time.end') : t('Pages.Competition.Time.Title')}</h3>
                                                        <p className=" text-justify">{t("Pages.Competition.Time.Body")}</p>
                                                        <Link to="/Student/DashBoard" className="custom-button">
                                                            <span>{t("Pages.NoPermissions.GoBackToProfile")}</span>
                                                        </Link>
                                                    </>
                                                    :
                                                    <>
                                                        <h3 className="title">{t("Pages.NoPermissions.title")}</h3>
                                                        <p className=" text-justify">{t("Pages.NoPermissions.Message")}</p>
                                                        <Link to="/Profile" className="custom-button">
                                                            <span>{t("Pages.NoPermissions.GoBackToProfile")}</span>
                                                        </Link>
                                                    </>
                                            }
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        lessons: state.lessons,
        files: state.files
    };
};

export default connect(mapStateToProps, {})(withTranslation('common')(NoPermissions));