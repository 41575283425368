import React from "react";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';

import Footer from "../../Footer/FooterBottom";
import { servicesInitial } from "../../actions/";
import history from '../../../history';
import Backgrounds from "../../Admin/Question/View/Background";
import api from '../../../api/api';
import { fetchIndices } from '../../Admin/Indices/actions/';
import { getIndeices, setIndeices } from "../../../context/sessionStorage";

class Renwal extends React.Component {

    state = {
        indices: [],
        cart: [],
        showWarrning: true,
        showRenwal: false,
        showPointInfo: false,
        isFirst: true,
        showPaymentMethod: false,
        showBankTransfer: false,
        showPice: false
    }

    constructor(props) {
        super(props);
        this._toastId = new React.createRef();
        if (this.props.user &&
            this.props.user.user &&
            this.props.user.user.UserType.Id !== 1 &&
            this.props.user.user.UserType.Id !== 5 &&
            this.props.user.user.UserType.Id !== 6 &&
            this.props.user.user.UserType.Id !== 4) {
            this.props.servicesInitial();
        } else {
            history.push("/Profile");
        }
    }

    componentDidMount() {
        let indices = getIndeices();
        if (indices === null || indices.length === 0) {
            this.props.fetchIndices();
        }
        this.props.servicesInitial();
        this.setState({
            cart: [],
            isFirst: true
        });
        window.scrollToPosition("main-renwal-section");
    }

    static getDerivedStateFromProps(props, state) {
        if (props.user && props.user.user && props.services && props.services.services && props.services.services.length > 0) {
            var service = props.services.services.filter(p => p.IndexType.includes("Main"))[0];
            if (props.user.user.ActivateUntilDate === "0" || props.user.user.MustAddMainService === true) {
                var hasMain = state.cart.some(s => s.Id === service.Id);
                if (!hasMain) {
                    state.cart.push(service);
                    state.IsForSchool = service.IsForSchool;

                    if (props.user.user.UserType.Id === 3) {
                        var classService = props.services.services.filter(p => p.IndexType.includes("Class"))[0];
                        if (classService) {
                            var hasMainClass = state.cart.some(s => s.Id === classService.Id);
                            if (!hasMainClass) {
                                state.cart.push(classService);
                            }
                        }
                    }
                }
            }
            //  else if (service.IsSelected === false) {
            //     var hasMainService = state.cart.some(s => s.Id === service.Id);
            //     if (!hasMainService) {
            //         // state.cart.push(service);
            //         state.showWarrning = false;
            //         state.IsForSchool = service.IsForSchool;
            //     }
            // }

            if (props.user.user.MustAddMainService === true) {
                state.showWarrning = false;
            }

            var pendingServices = props.services.services.filter(p => p.IsPending === true && p.IsSelected === false && !p.IndexType.includes("Main"));
            if (pendingServices && pendingServices.length > 0 && state.isFirst) {
                pendingServices.forEach(item => {
                    var hasItem = state.cart.some(s => s.Id === item.Id);
                    if (!hasItem) {
                        state.cart.push(item);
                    }
                });
                state.isFirst = false;
            }

            state.discountPrice = 0;
            state.subPrice = Object.values(state.cart).reduce((r, { Price }) => r + Price, 0);
            if (!service?.IsForSchool) {
                var classServices = state.cart.filter(p => p.IndexType.startsWith("Class"));
                if (classServices.length !== 0) {
                    var factor = Math.floor(classServices.length / 3) * 2;
                    var classPrice = classServices[0].Price;
                    state.discountPrice = factor * classPrice;
                }
            }
            state.totalPrice = state.subPrice - state.discountPrice;
            state.IsNIS = service?.IsNIS;
        }
        return state;
    }

    addServicesToCart = (isChecked, service) => {
        let cart = this.state.cart;
        if (isChecked === true) {
            cart.push(service);
        } else {
            cart = cart.filter(s => s.Id !== service.Id);
        }

        this.setState({
            cart
        });
    }

    hidePice = () => {
        this.setState({
            showPice: false
        });
    }

    checkPrice = () => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });
        var selectedPrivous = this.props.services.services.filter(p => p.IsSelected === true).length;
        var totalService = this.props.services.services.filter(p => p.IndexType.startsWith("Class")).length;

        var hasClassService = this.state.cart.filter(p => p.IndexType.startsWith("Class")).length;
        var hasMainervice = this.state.cart.some(p => p.IndexType.includes("Main"));

        if (this.props.user.user.UserType.Id === 3 && this.props.user.user.ActivateUntilDate === "0" && !hasClassService) {
            var classService = this.props.services.services.filter(p => p.IndexType.startsWith("Class"))[0];
            if (classService) {
                var hasMainClass = this.state.cart.some(s => s.Id === classService.Id);
                if (!hasMainClass) {
                    let cart = this.state.cart;
                    cart.push(classService);
                    this.setState({
                        cart
                    });
                }
            }
        }

        if (hasMainervice === false && this.state.IsForSchool !== true && hasClassService > 0 && hasClassService % 3 !== 0) {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Profile.services.error_toast.Title')}</div> <div className="h6">{t('Pages.Profile.services.error_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (hasMainervice === true && (hasClassService === 0 || hasClassService % 3 !== 0) && this.state.IsForSchool === false
            && (hasClassService + selectedPrivous) !== totalService) {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Profile.services.error_toast.Title')}</div> <div className="h6">{t('Pages.Profile.services.error_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (this.state.IsForSchool && this.props.services.length === 0) {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Profile.services.empty_cart_error_toast.Title')}</div> <div className="h6">{t('Pages.Profile.services.empty_cart_error_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if ((this.state.cart.length === 0) || (this.state.IsForSchool && this.state.cart.length === 1 && this.props.user.user.ActivateUntilDate === "0")) {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Profile.services.empty_cart_error_toast.Title')}</div> <div className="h6">{t('Pages.Profile.services.empty_cart_error_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            this.setState({
                showPice: true
            });
            toast.dismiss(this._toastId.current);
        }
    }

    handleRegister = () => {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        var selectedPrivous = this.props.services.services.filter(p => p.IsSelected === true).length;
        var totalService = this.props.services.services.filter(p => p.IndexType.startsWith("Class")).length;

        var hasClassService = this.state.cart.filter(p => p.IndexType.startsWith("Class")).length;
        var services = this.state.cart.filter(p => !p.IndexType.includes("Main"));
        var hasMainervice = this.state.cart.some(p => p.IndexType.includes("Main"));

        if (hasMainervice === false && this.state.IsForSchool !== true && hasClassService > 0 && hasClassService % 3 !== 0) {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Profile.services.error_toast.Title')}</div> <div className="h6">{t('Pages.Profile.services.error_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (hasMainervice === true && (hasClassService === 0 || hasClassService % 3 !== 0) && this.state.IsForSchool === false
            && (hasClassService + selectedPrivous) !== totalService) {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Profile.services.error_toast.Title')}</div> <div className="h6">{t('Pages.Profile.services.error_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if (this.state.IsForSchool && services.length === 0) {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Profile.services.empty_cart_error_toast.Title')}</div> <div className="h6">{t('Pages.Profile.services.empty_cart_error_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else if ((this.state.cart.length === 0) || (this.state.IsForSchool && this.state.cart.length === 1 && this.props.user.user.ActivateUntilDate === "0")) {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-bug"></i> {t('Pages.Profile.services.empty_cart_error_toast.Title')}</div> <div className="h6">{t('Pages.Profile.services.empty_cart_error_toast.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            if (this.state.IsNIS) {
                this.setState({
                    showPaymentMethod: true
                });
            } else {
                this.submitVisaPay();
            }
            toast.dismiss(this._toastId.current);
        }
    }

    submitVisaPay() {
        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        let postData = {
            services: this.state.cart
        };

        api.put(`/Pay/`, postData).then(response => {
            window.location.href = response.data.data.link;
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });

        toast.dismiss(this._toastId.current);
    }

    hidePaymentMethod = () => {
        this.setState({
            showPaymentMethod: false
        });
    }

    showBankTransfer = () => {
        this.setState({
            showBankTransfer: true
        });
    }

    hideBankTransfer = () => {
        this.setState({
            showBankTransfer: false
        });
    }

    submitBill = () => {

        const { t } = this.props;

        this._toastId.current = toast(t('Processing'), {
            position: "bottom-left",
            autoClose: false
        });
        toast.update(this._toastId.current, {
            render: () => <div> <i className="fa-spin fas fa-spinner "></i> {t('Processing')} </div>,
            autoClose: false,
            closeOnClick: false,
        });

        let postData = {
            services: this.state.cart
        };

        api.put(`/Pay/?isCash=true`, postData).then(response => {

            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-shield-check"></i> {t('Pages.Profile.services.success_toast.Title')}</div> <div className="h6">{t('Pages.Profile.services.success_toast.Body')}</div></div>,
                type: toast.TYPE.SUCCESS,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            this.setState({
                showBankTransfer: false,
                showPaymentMethod: false,
                showRenwal: false
            });
        }).catch(error => {
            toast.update(this._toastId.current, {
                render: () => <div><div className="h5"> <i className="icon fal fa-wifi-slash"></i> {t('Error.NoConnection.Title')}</div> <div className="h6">{t('Error.NoConnection.Body')}</div></div>,
                type: toast.TYPE.ERROR,
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        });

        toast.dismiss(this._toastId.current);

    }

    GetNode(nodes, Id) {
        var result = false;
        if (nodes) {
            nodes.forEach(node => {
                if (node.indexType === Id && node.Level >= 2) {
                    result = node;
                }
            });
            if (result) {
                return result;
            }
            nodes.forEach(node => {
                var subResult = this.GetNode(node.nodes, Id);
                if (subResult) {
                    result = subResult;
                    return false;
                }
            });
        }
        return result;
    }

    renderServices() {
        const { t, user } = this.props;
        return (
            <>
                <div className="row ">
                    <div className="col-lg-12">
                        <div className="widget widget-feature pt-3">
                            <div className="widget-header">
                                <h3 className="title font-weight-bold text-center">
                                    {user.user.ActivateUntilDate !== "0" ? t('Pages.Profile.services.renewal') : t('Pages.Profile.services.active')}
                                </h3>
                            </div>
                            <div className="widget-body row">
                                {
                                    user.user.UserType.Id === 3 ?
                                        <div className="w-100">
                                            {
                                                user.user.ActivateUntilDate !== "0" ?
                                                    this.props.services && this.props.services.services &&
                                                        this.props.services.services.filter(p => p.IndexType.startsWith("Book")) &&
                                                        this.props.services.services.filter(p => p.IndexType.startsWith("Book"))[0] &&
                                                        this.props.services.services.filter(p => p.IndexType.startsWith("Book"))[0].IsSelected ?
                                                        <>
                                                            <h3 className="title py-2 display-5 text-center">
                                                                {t('Pages.Profile.services.isPaid_all')}
                                                            </h3>
                                                        </>
                                                        :
                                                        <>
                                                            <h3 className="title py-2 display-5">
                                                                <i className="fa-solid fa-stars " style={{
                                                                    color: '#92278f'
                                                                }}></i>
                                                                {t('Pages.Profile.services.Special')}
                                                            </h3>
                                                            <ul className="mb-2">
                                                                {this.renderServiceItem("Book", t("Pages.Profile.services.Special_text_for_register_user"))}
                                                            </ul>
                                                        </>
                                                    :
                                                    <div className="d-flex align-items-center flex-column">
                                                        <div className="d-flex justify-content-evenly w-100 flex-wrap">
                                                            {this.renderServiceItemWithImage("Class", true, "ClassRoom")}
                                                            {this.renderServiceItemWithImage("Book", true, "Game")}
                                                        </div>
                                                        <div className="w-75 text-center title mb-4">
                                                            {
                                                                this.state.cart && this.state.cart.filter(p => p.IndexType.includes("Book")).length ?
                                                                    t("Pages.Profile.services.Special_text")
                                                                    :
                                                                    t("Pages.Profile.services.Ordinary_text")
                                                            }
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                        :
                                        <>
                                            <div className="col-lg-12">
                                                <h3 className={`title py-2 display-5 text-center`}>
                                                    <i className="fal fa-users-class " style={{
                                                        color: '#f16421'
                                                    }}></i>
                                                    {t('Pages.Profile.services.Class')}
                                                </h3>
                                                <div className="w-100 mb-4">
                                                    <div className={`contact-form p-3 w-100 mb-2 inner-box p-3 text-center justify-content-center d-flex`} style={{
                                                        background: '#99e4f1',
                                                        border: '1px solid #17a2b8',
                                                        borderRadius: '10px'
                                                    }}  >
                                                        <p>
                                                            <i className="mx-1 fa-solid fa-circle-info"></i>
                                                            {t('Pages.Profile.services.error_toast.Info')}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="mb-3 d-flex flex-wrap align-items-center">
                                                    {this.renderServiceItemWithImage("Class", true, "ClassRoom")}
                                                </div>
                                            </div>

                                            <div className="col-lg-12">

                                                <h3 className="title py-2 display-5 mb-4 text-center">
                                                    <i className="fal fa-gamepad-modern" style={{
                                                        color: '#f16421'
                                                    }}></i>
                                                    {t('Pages.Profile.services.Funs')}
                                                </h3>
                                                <div className="mb-3 d-flex flex-wrap align-items-center">
                                                    {this.renderServiceItemWithImage("Fun", false, "Game")}
                                                </div>
                                            </div>

                                            <div className="col-lg-12">
                                                <h3 className="title py-2 display-5 mb-4 text-center">
                                                    <i className="fal fa-books " style={{
                                                        color: '#f16421'
                                                    }}></i>
                                                    {t('Pages.Profile.services.Books')}
                                                </h3>
                                                <div className="mb-3 d-flex flex-wrap align-items-center">
                                                    {this.renderServiceItemWithImage("Book", false, "Book")}
                                                </div>
                                            </div>

                                            <div className="col-lg-12 ">

                                                <h3 className="title py-2 display-5 text-center">
                                                    <i className="fal fa-backpack " style={{
                                                        color: '#f16421'
                                                    }}></i>
                                                    {t('Pages.Profile.services.Teacher')}
                                                </h3>
                                                <ul className="mb-5">
                                                    {this.renderServiceItem("Teacher", t("Pages.Profile.services.Teacher_text"))}
                                                </ul>
                                            </div>
                                        </>
                                }
                                {
                                    user.user.UserType.Id === 3 &&
                                        user.user.ActivateUntilDate !== "0" &&
                                        this.props.services &&
                                        this.props.services.services &&
                                        this.props.services.services.filter(p => p.IndexType.startsWith("Book")) &&
                                        this.props.services.services.filter(p => p.IndexType.startsWith("Book"))[0] &&
                                        this.props.services.services.filter(p => p.IndexType.startsWith("Book"))[0].IsSelected ? "" :
                                        <div className="w-100 mb-3">
                                            <div className="d-flex justify-content-center align-items-center teacher-single-bottom ">
                                                <button className="custom-button mt-1" onClick={() => this.checkPrice()} >
                                                    <span>
                                                        <i className={`icon mx-1 fal fa-box-check`}></i>
                                                        {t('Pages.Profile.services.checkPrice')}
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-50 d-none">
                        <aside>
                            <div className="widget widget-feature">
                                <div className="widget-header">
                                    <h3 className="title">
                                        <i className="fal fa-shopping-basket icon " style={{
                                            color: '#92278f'
                                        }}></i>
                                        {t('Pages.Profile.services.cart.title')}
                                    </h3>
                                </div>
                                <div className="widget-body">
                                    <ul>
                                        {this.renderCartServiceItem()}
                                    </ul>
                                </div>
                                <div className="tags-area">

                                    <ul className="w-100">
                                        <li className="d-flex justify-content-between align-items-center">
                                            <div className="">
                                                {t('Pages.Profile.services.cart.total')}
                                            </div>
                                            <div className="h5 font-weight-bold " >
                                                {this.state.subPrice}
                                                {
                                                    this.state.IsNIS ?
                                                        <i title={t("Pages.Profile.services.shekel")} className="fal fa-shekel-sign  mx-1" style={{
                                                            color: '#92278f'
                                                        }}></i> :
                                                        <i title={t("Pages.Profile.services.dollar")} className="fal fa-dollar-sign  mx-1" style={{
                                                            color: '#92278f'
                                                        }}></i>
                                                }
                                            </div>
                                        </li>
                                        <li className="d-flex justify-content-between align-items-center">
                                            <div className="">
                                                {t('Pages.Profile.services.cart.discount')}
                                            </div>
                                            <div className="h5 font-weight-bold text-danger" >
                                                {this.state.discountPrice}
                                                {
                                                    this.state.IsNIS ?
                                                        <i title={t("Pages.Profile.services.shekel")} className="fal fa-shekel-sign  mx-1"></i> :
                                                        <i title={t("Pages.Profile.services.dollar")} className="fal fa-dollar-sign  mx-1"></i>
                                                }
                                            </div>
                                        </li>
                                        <li className="d-flex justify-content-between align-items-center">
                                            <div className="">
                                                {t('Pages.Profile.services.cart.ToPay')}
                                            </div>
                                            <div className="h5 font-weight-bold text-success" >
                                                {this.state.totalPrice}
                                                {
                                                    this.state.IsNIS ?
                                                        <i title={t("Pages.Profile.services.shekel")} className="fal fa-shekel-sign  mx-1"></i> :
                                                        <i title={t("Pages.Profile.services.dollar")} className="fal fa-dollar-sign  mx-1"></i>
                                                }
                                            </div>
                                        </li>
                                    </ul>

                                    <div className="w-100 my-1">
                                        <div className="d-flex justify-content-center align-items-center teacher-single-bottom ">
                                            <button className="custom-button mt-1" onClick={() => this.handleRegister()} >
                                                <span>
                                                    <i className={`icon fal ${this.state.IsNIS ? " fa-cash-register" : " fa-credit-card"}`}></i>
                                                    {t('Pages.Profile.services.submit')}
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                    {user.user.ActivateUntilDate !== "0" && this.state.showWarrning ? this.renderWarrningMessage() : ""}
                                </div>
                            </div>
                        </aside>
                    </div>
                </div >
            </>
        );
    }

    renderServiceItemWithImage = (IndexType, hidePrice, picPath) => {
        const { services, t } = this.props;
        if (services && services.services) {
            return services.services.filter(p => p.IndexType.startsWith(IndexType)).map((item, index) => {
                let src = "/assets/images/logo/sub.png";
                const color = item.IsSelected ? "#646262" : "#0d5acc";
                const hasService = this.state.cart.some(s => s.Id === item.Id);
                if (picPath) {
                    src = `/assets/images/${picPath}/${picPath === "Book" ? "1" : index + 1}.png`;
                }
                return <div
                    key={item.Id}
                    className={`pr-1 mb-2 bookWrap position-relative user-select-none mr-4 ${item.IsSelected ? "cursor-not-allowed" : "cursor-pointer"} `}
                    title={`${item.Name}${item.IsSelected ? " - " + t("Pages.Profile.services.isPaid") : ""}`}
                    onClick={() => item.IsSelected ? "" : this.addServicesToCart(!hasService, item)}>
                    <div className="w-100">
                        <div className="shop-item" style={{
                            border: hasService || item.IsSelected ? `2px solid ${color}` : "",
                            padding: !hasService && !item.IsSelected ? "10px" : "8px"
                        }}>
                            {
                                hidePrice || item.IsSelected ? "" :
                                    <div className="ribbon-corner ribbon-fold pt-1" data-position="right top" style={{
                                        right: '5px',
                                        background: '#92278f'
                                    }}>
                                        {item.Price}
                                        {
                                            item.IsNIS ?
                                                <i title={t("Pages.Profile.services.shekel")} className="fal fa-shekel-sign mx-1"></i> :
                                                <i title={t("Pages.Profile.services.dollar")} className="fal fa-dollar-sign mx-1"></i>
                                        }
                                    </div>
                            }
                            {
                                item.IsSelected ?
                                    <div className="ribbon-corner ribbon-fold pt-0" data-position="right top" style={{
                                        right: '5px',
                                        background: '#646262',
                                        fontSize: "14px"
                                    }}>
                                        {t("Pages.Profile.services.isPaid")}
                                    </div> : ""
                            }
                            <div className="product-thumb position-relative  user-select-none" style={{
                                height: '100px',
                                backgroundImage: `url("${src}")`,
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: "contain",
                                backgroundPosition: 'center'
                            }}>
                                {
                                    hasService || item.IsSelected ?
                                        <i className="h1 fa-sharp fa-solid fa-square-check position-absolute m-0" style={{
                                            top: '-2px',
                                            left: '0px',
                                            color: `${color}`
                                        }}></i> : ""
                                }
                            </div>
                            <div className="product-content">
                                <h6 className="text-truncate py-2 w-100 mb-0  user-select-none">
                                    {item.Name}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>;
            });
        }
        return "";
    }

    renderServiceItem = (IndexType, customText) => {
        const { services } = this.props;
        if (services && services.services) {
            const { t } = this.props;
            return services.services.filter(p => p.IndexType.includes(IndexType)).map(item => {
                return <li key={item.Id} className="d-flex justify-content-between align-items-center">
                    <div className="custom-control custom-checkbox my-1 mr-sm-4 py-1 checkbox-primay">
                        <input
                            type="checkbox"
                            disabled={item.IsSelected}
                            className="custom-control-input"
                            onChange={e => this.addServicesToCart(e.target.checked, item)}
                            ref={IsActive => this.IsActive = IsActive}
                            defaultChecked={this.props.IsActive || item.IsSelected || item.IsPending}
                            name={`serviceId-${item.Id}`}
                            id={`serviceId-${item.Id}`} />
                        <label className="custom-control-label" htmlFor={`serviceId-${item.Id}`} >
                            {customText ? customText : item.Name}
                        </label>
                    </div>
                    <div className="h5 cursor-pointer d-none">
                        {item.Price}
                        {
                            item.IsNIS ?
                                <i title={t("Pages.Profile.services.shekel")} className="fal fa-shekel-sign mx-1" style={{
                                    color: '#92278f'
                                }}></i> :
                                <i title={t("Pages.Profile.services.dollar")} className="fal fa-dollar-sign mx-1" style={{
                                    color: '#92278f'
                                }}></i>
                        }

                    </div>
                </li>;
            });
        }
        return "";
    }

    renderCartServiceItem = () => {
        if (this.state.cart) {
            const { t } = this.props;
            return this.state.cart.sort((a, b) => a.Sort > b.Sort ? 1 : -1).map(item => {
                return <li key={item.Id} className="d-flex justify-content-between align-items-center">

                    <div className="">
                        {item.Name}
                    </div>

                    <div className="h5 font-weight-bold cursor-pointer">
                        {item.Price}
                        {
                            item.IsNIS ?
                                <i title={t("Pages.Profile.services.shekel")} className="fal fa-shekel-sign mx-1" style={{
                                    color: '#92278f'
                                }}></i> :
                                <i title={t("Pages.Profile.services.dollar")} className="fal fa-dollar-sign mx-1" style={{
                                    color: '#92278f'
                                }}></i>
                        }
                    </div>
                </li>;
            });
        }
        return "";
    }

    renderWarrningMessage = () => {
        const { t, user } = this.props;
        return <div className="w-100 mt-3">
            <div className={`contact-form p-3 w-100 mb-2 inner-box p-3 pt-4 text-center justify-content-center d-flex `} style={{
                background: 'rgb(241 174 181)',
                border: '1px solid rgb(220 53 69)',
                borderRadius: '10px'
            }}  >
                <div>
                    <div className=" h2 mb-3">
                        <i className="icon fal fa-engine-warning"></i>
                        {t('Pages.Profile.warning.title')}
                    </div>
                    <p>
                        {t('Pages.Profile.warning.body')}
                        <span className="font-weight-bold">
                            {user.user.ActivateUntilDate}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    }



    render() {
        const { t, user } = this.props;
        let indices = this.props.indices;
        if (indices) {
            setIndeices(indices);
        } else {
            indices = this.state.indices;
        }
        return <>
            <Helmet>
                <title>
                    {`${user.user.FirstName} ${user.user.SecondName} ${user.user.LastName}`} |  {t('Pages.Profile.services.title')}
                </title>
            </Helmet>
            {
                this.state.showPice ?
                    <Modal
                        size="md"
                        centered
                        show={this.state.showPice}
                        onHide={this.hidePice}
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <i className={`icon fal fa-box-check`}></i>
                                {t('Pages.Profile.services.cart.total')}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="widget widget-category ">
                                <div className="widget-body">
                                    <div className="d-flex h3  align-items-center mb-3" style={{
                                        justifyContent: "space-evenly"
                                    }}>
                                        <div className="">
                                            {t('Pages.Profile.services.cart.ToPay')}
                                        </div>
                                        <div className=" font-weight-bold text-success" >
                                            {this.state.totalPrice}
                                            {
                                                this.state.IsNIS ?
                                                    <i title={t("Pages.Profile.services.shekel")} className="fal fa-shekel-sign mx-1"></i> :
                                                    <i title={t("Pages.Profile.services.dollar")} className="fal fa-dollar-sign mx-1"></i>
                                            }
                                        </div>
                                    </div>
                                    <div className="w-100 my-1">
                                        <div className="d-flex justify-content-center align-items-center teacher-single-bottom ">
                                            <button className="custom-button mt-1" onClick={() => this.handleRegister()} >
                                                <span>
                                                    <i className={`icon fal ${this.state.IsNIS ? " fa-cash-register" : " fa-credit-card"}`}></i>
                                                    {t('Pages.Profile.services.submit')}
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    {user.user.ActivateUntilDate !== "0" && this.state.showWarrning ? this.renderWarrningMessage() : ""}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    : ""
            }
            {
                this.state.showPaymentMethod ?
                    <Modal
                        size="lg"
                        centered
                        show={this.state.showPaymentMethod}
                        onHide={this.hidePaymentMethod}
                        backdrop="static"
                        keyboard={false}
                        enforceFocus={false} >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <i className="icon fal fa-money-bill-wave"></i>
                                {t('Pages.Profile.services.PaymentMethod')}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="widget widget-category ">
                                <div className="widget-body">
                                    <div className="d-flex justify-content-evenly banner-buttons" style={{
                                        justifyContent: "space-evenly"
                                    }}>
                                        <button className="custom-button" onClick={() => this.submitVisaPay()} >
                                            <span>
                                                <i className="icon fal fa-credit-card"></i>
                                                {t('Pages.Profile.services.CreditCard')}
                                            </span>
                                        </button>

                                        <button className="custom-button" onClick={() => this.showBankTransfer()} >
                                            <span>
                                                <i className="icon fal fa-money-check-edit"></i>
                                                {t('Pages.Profile.services.BankTransfer')}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    : ""
            }
            {
                this.state.showBankTransfer ?
                    <Modal
                        size="lg"
                        centered
                        show={this.state.showBankTransfer}
                        onHide={this.hideBankTransfer}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <i className="icon fal fa-money-check-edit"></i>
                                {t('Pages.Profile.services.BankTransfer')}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="widget widget-category ">
                                <div className="widget-body">
                                    <div className="title h4 text-center mb-3">
                                        تعليمات التحويل البنكي
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <span className="ml-1 text-primary font-weight-bold d-inline-block">
                                                البنك :
                                            </span>
                                            ليؤومي
                                        </div>
                                        <div className="col-md-6">
                                            <span className="ml-1 text-primary font-weight-bold d-inline-block">
                                                رقم الحساب  :
                                            </span>
                                            1261187
                                        </div>
                                        <div className="col-md-6">
                                            <span className="ml-1 text-primary font-weight-bold d-inline-block">
                                                باسم :
                                            </span>
                                            هيّا إلى العربيّة
                                        </div>
                                        <div className="col-md-6">
                                            <span className="ml-1 text-primary font-weight-bold d-inline-block">
                                                رقم الفرع :
                                            </span>
                                            906
                                        </div>
                                        <div className="col-md-6">
                                            <span className="ml-1 text-primary font-weight-bold d-inline-block">
                                                البلد    :
                                            </span>
                                            عرعرة المثلّث
                                        </div>
                                        <div className="col-md-6">
                                            <span className="ml-1 text-primary font-weight-bold d-inline-block">
                                                رقم البنك  :
                                            </span>
                                            10
                                        </div>
                                        <div className="col-12 mt-3">
                                            <p className="text-justify">
                                                لسرعة تفعيل الحساب يُفضّل أن ترسل لنا صورة قسيمة التحويل إلى البريد الإلكتروني:
                                                <a href={`mailto:${t('HeaderTop.email')}`} className="ml-1 text-primary font-weight-bold d-inline-block">
                                                    {t('HeaderTop.email')}
                                                </a>
                                                وشكرًا
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="widget widget-category ">
                                <div className="widget-body">
                                    <div className="title h4 text-center mb-3">
                                        تعليمات الشيك البنكيّ
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mb-3">
                                            <p className="text-justify">
                                                يُرسل المبلغ المطلوب بشيك داخل ظرف على العنوان التالي:
                                            </p>
                                        </div>
                                        <div className="col-md-6">
                                            <span className="ml-1 text-primary font-weight-bold d-inline-block">
                                                الاسم     :
                                            </span>
                                            هيّا إلى العربيّة
                                        </div>
                                        <div className="col-md-6">
                                            <span className="ml-1 text-primary font-weight-bold d-inline-block">
                                                الرمز البريدي  :
                                            </span>
                                            30091
                                        </div>
                                        <div className="col-md-6">
                                            <span className="ml-1 text-primary font-weight-bold d-inline-block">
                                                البلد    :
                                            </span>
                                            جت المثلّث
                                        </div>
                                        <div className="col-md-6">
                                            <span className="ml-1 text-primary font-weight-bold d-inline-block">
                                                صندوق بريد   :
                                            </span>
                                            1548
                                        </div>
                                        <div className="col-12 mt-3">
                                            <p className="text-justify text-danger">
                                                يُكتب على الشيك : (هيّا إلى العربيّة) أو تركه فارغا.
                                            </p>
                                            <div className="text-justify ">
                                                <span className="text-primary font-weight-bold">
                                                    ملاحظات:
                                                </span>
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item text-justify">
                                                        1-
                                                        متى يصل الشيك فإنّنا  سنعلمكم بذلك وسيُفعّل حسابكم.
                                                    </li>
                                                    <li className="list-group-item text-justify">
                                                        2-
                                                        الشيك يصل في غضون 7 - 8 أيّام من تاريخ الطلب .
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <div className="d-flex justify-content-center w-100 align-items-center  ">
                                <button className="custom-button mb-1" onClick={() => this.submitBill()} >
                                    <span>
                                        <i className="icon fal fa-envelope-open-dollar"></i>
                                        {t('Pages.Profile.services.Approved')}
                                    </span>
                                </button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    : ""
            }
            <section className="pos-rel padding-bottom call-in-action call-overlay oh " id="main-renwal-section" style={{
                backgroundColor: '#75cdff'
            }}>

                <div className="position-relative padding-bottom w-100 d-flex align-items-center justify-content-center" style={{
                    minHeight: '100vh'
                }}>
                    <Backgrounds CustomBackground="Registration" />
                    <div className="container h-100 mt-30 mb-30-none">
                        {this.renderServices()}
                    </div>
                </div>
            </section>
            <div className="position-sticky">
                <Footer />
            </div>
        </>;
    }

}

const mapStateToProps = state => {
    return {
        indices: state.indices,
        services: state.services,
        user: state.user
    };
};
export default connect(mapStateToProps, { servicesInitial, fetchIndices })(withTranslation('common')(Renwal));